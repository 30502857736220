import _ from 'lodash';
import moment from 'moment';
import {
  Assignment as AssignmentIcon,
  Beenhere as BeenhereIcon,
  Bookmark as BookmarkIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  CropSquare as CropSquareIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  DirectionsWalk as DirectionsWalkIcon,
  DoneAll as DoneAllIcon,
  Face as FaceIcon,
  FilterList as FilterListIcon,
  Feedback as FeedbackIcon,
  Flag as FlagIcon,
  FormatShapes as FormatShapesIcon,
  Gavel as GavelIcon,
  Gesture as GestureIcon,
  History as HistoryIcon,
  InsertChart as InsertChartIcon,
  Layers as LayersIcon,
  LibraryBooks as LibraryBooksIcon,
  NotInterested as NotInterestedIcon,
  PlayArrow as PlayArrowIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Place as PlaceIcon,
  Reorder as ReorderIcon,
  Router as RouterIcon,
  Search as SearchIcon,
  ShowChart as ShowChartIcon,
  Snooze as SnoozeIcon,
  SpeakerNotes as SpeakerNotesIcon,
  Star as StarIcon,
  SupervisorAccount as SupervisorAccountIcon,
  SwapHoriz as SwapHorizIcon,
  Terrain as TerrainIcon,
  ThreeSixty as ThreeSixtyIcon,
  VerifiedUser as VerifiedUserIcon,
  ViewList as ViewListIcon,
  Work as WorkIcon,
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  ExploreOff as ExploreOffIcon,
  DataUsage,
  LocalOffer as TagIcon,
  LocationSearching as FollowIcon,
  GroupWork as CallSignIcon,
  Description as FileIcon,
} from '@material-ui/icons';
import {
  AccountQuestion as AccountQuestionIcon,
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  Counter as CounterIcon,
  Speedometer as SpeedometerIcon,
  MapMarkerMultiple as ClusterIcon
} from 'mdi-material-ui';
import React from 'react';
import directionsCarGlyph from '../img/baseline_directions_car_white_24dp.png';
// import errorGlyph from '../img/baseline_error_white_24dp.png';
import feedbackGlyph from '../img/baseline_feedback_white_24dp.png';
import flagGlyph from '../img/baseline_flag_white_24dp.png';
import gavelGlyph from '../img/baseline_gavel_white_24dp.png';
import myLocationGlyph from '../img/baseline_my_location_black_24dp.png';
import personGlyph from '../img/baseline_person_white_24dp.png';
import placeGlyph from '../img/baseline_place_white_24dp.png';
import supervisorAccountGlyph from '../img/baseline_supervisor_account_white_24dp.png';
import verifiedUserGlyph from '../img/baseline_verified_user_white_24dp.png';
import warningGlyph from '../img/baseline_warning_white_24dp.png';
import locationGlyph from '../img/baseline_business_white_24dp.png';
import objectiveGlyph from '../img/baseline_data_usage_white_24dp.png';
import planGlyph from '../img/baseline_assignment_white_24dp.png';
import axisArrowGlyph from '../img/axis-arrow.png';
import gestureGlyph from '../img/baseline_gesture_white_24dp.png';
import playArrowGlyph from '../img/baseline_play_arrow_white_24dp.png';
import stopGlyph from '../img/baseline_stop_white_24dp.png';
import directionsWalkGlyph from '../img/baseline_directions_walk_white_24dp.png';
import snoozeGlyph from '../img/baseline_snooze_white_24dp.png';
import carSideGlyph from '../img/car-side.png';
import carTractionControlGlyph from '../img/car-traction-control.png';
import carBrakeParkingGlyph from '../img/car-brake-parking.png';
import grade1Glyph from '../img/1.png';
import grade2Glyph from '../img/2.png';
import grade3Glyph from '../img/3.png';
import grade4Glyph from '../img/4.png';
import grade5Glyph from '../img/5.png';
import threeSixtyGlyph from '../img/baseline_360_white_24dp.png';
import beenHereGlyph from '../img/baseline_beenhere_white_24dp.png';
import clusterGlyph from '../img/multiple_locations_white_24dp.png';
import { green, grey, orange, red } from '@material-ui/core/colors';

// import pointOfInterestGlyph from '../svg/baseline-flag-24px.svg';
// import rendezvousGlyph from '../svg/baseline-supervisor_account-24px.svg';
// import vulnerabilityGlyph from '../svg/baseline-warning-24px.svg';
// import safeLocationGlyph from '../svg/baseline-verified_user-24px.svg';
// import incidentGlyph from '../svg/baseline-feedback-24px.svg';
// import crimeGlyph from '../svg/baseline-gavel-24px.svg';
// import vehicleGlyph from '../svg/baseline-directions_car-24px.svg';
// import personGlyph from '../svg/baseline-person-24px.svg';
// import featureGlyph from '../svg/baseline-place-24px.svg';
// import eventGlyph from '../svg/baseline-error-24px.svg';
// import myLocationGlyph from '../svg/baseline-my_location-24px.svg';

const {
  baseType,
  useReducedResourceInformation,
  dioStates,
  liveOptions,
  // vehicleGroups,
  // personGroups,
  isFleet,
} = window.config;

export const positionGlyph = myLocationGlyph;

export const idleGlyph = snoozeGlyph;
export const vehicleStopGlyph = stopGlyph;
export const eventGlyph = warningGlyph;

export const accelerometerGlyph = axisArrowGlyph;

export const incidentFilters = {
  types: {
    'ABAND CALL': 'Abandoned Call',
    ABSENT: 'Absent',
    AUDIBLE: 'Alarm Audible Only',
    ALAPOLICE: 'Alarm Police Installed',
    ANIMAL: 'Animal Problems',
    ARSON: 'Arson',
    'ASB DIS AU': 'ASB Dispersal Authority',
    'ASB DIS NO': 'ASB Dispersal Notice',
    'ASB ENVIR': 'ASB Environmental',
    'ASB NUIS': 'ASB Nuisance',
    'ASB PER': 'ASB Personal',
    CTI: 'Aspire Default',
    AUTOCRIME: 'Autocrime Tumv',
    AUTOCRIME2: 'Autocrime Veh Interference',
    AWOL: 'AWOL/Absconders',
    'BURG DIST': 'Burg Dwell Bogus Distraction',
    BURGCOM: 'Burglary Commercial Premises',
    BURGDWELL: 'Burglary Dwelling',
    BURGOP2IN1: 'Burglary Dwelling Op 2 In 1',
    BURGOTH: 'Burglary Other',
    'BURG BOAT': 'Burglary Other Caravan/Boat',
    'BURG GARAG': 'Burglary Other Garage',
    'BURG SHED': 'Burglary Other Shed',
    CIVIL: 'Civil Dispute',
    COLLAPSE: 'Collapse/Injury/Illness/Trappe',
    COMPLAINT: 'Complaints Against Police',
    CONCERN: 'Concern For Safety',
    'CONC CHILD': 'Concern For Safety Of Child',
    ADVICE: 'Contact Record',
    'CRIM-RAP': 'Crime - Rape',
    DAMAGE: 'Criminal Damage',
    DOMESTIC: 'Domestic Incident',
    DOMVIO: 'Domestic Violence',
    DRUGS: 'Drugs',
    'EC CRIME': 'Economic Crime',
    FIREARMS: 'Firearms Inc',
    FRAUD: 'Fraud & Forgery',
    RTDISRUPT: 'Highway Disruption',
    'HOAX CALL': 'Hoax Calls Emergency Services',
    ASYLUM: 'Immigration',
    'HSE ACC': 'Industrial Incident/Accident',
    INSPREM: 'Insecure Premises/Vehicles',
    LICENSING: 'Licensing',
    LONEWKR: 'Lone Worker Alarm',
    LOSTFOUND: 'Lost/Found Property & Persons',
    MESSAGE: 'Messages',
    MISPER: 'Mfh - Pending Risk Asssessment',
    'MFH CARE': 'Missing Unauthorised Care',
    'MFH HIGH': 'Missing Person - High Risk',
    'MFH LOW': 'Missing Person - Low Risk',
    'MFH MED': 'Missing Person - Medium Risk',
    'MFH FELL': 'Missing Person Fell Rescue',
    DISASTER: 'Natural Disaster/Warning',
    OFFASSIST: 'Officer Assistance',
    OTHOFFENCE: 'Other Offences',
    PETS: 'Pets/Domesticated Animals',
    TASKING: 'Police Generated Activity',
    'PLAN EVENT': 'Pre Planned Events/Lamping',
    PROTEST: 'Protest/Demonstration',
    RTOFFENCE: 'Road Related Offence',
    ROBBERY: 'Robbery',
    'RTC DAMAGE': 'RTC Damage Only',
    'RTC DEATH': 'RTC Death',
    'RTC MINOR': 'RTC Minor',
    RTCSERIOUS: 'RTC Serious',
    SEXUAL: 'Sexual Offence',
    SMV: 'SMV',
    SMVRECOVD: 'SMV Recovered',
    SUDDEATH: 'Sudden Death',
    'SUS CIRCS': 'Suspect Circumstances',
    'SUS MUNITN': 'Suspect Object Munitions',
    'SUS OBJECT': 'Suspect Object/Package',
    'TERR BOMB': 'Terrorism Bomb Call',
    TERRORISM: 'Terrorism General',
    'TERR KIDNA': 'Terrorism Kidnap/Abduction',
    'TERR CYCLA': 'Terrorism Op Cyclamen',
    TEST: 'Test/Training',
    THEFT: 'Theft General',
    MOBTHEFT: 'Theft Mobile',
    'THEFT SHOP': 'Theft Shop',
    TRANSPORT: 'Transport Rail/Marine/Air',
    'URN ALARM': 'Urn Alarm',
    VIOLENCE: 'Violence',
    'WANT/BAIL': 'Wanted Person/Court Ord/Bail',
    WILDLIFE: 'Wildlife',
  },
  closingCodes: {
    ADMIN03: 'Cancel/Exit/Error',
    ADMIN04: 'Comp Pol./Dissatisfaction Serv',
    ADMIN05: 'Duplicate',
    ADMIN06: 'Lost/Found Property & Persons',
    ADMIN08: 'Messages',
    ADMIN10: 'Police Generated Activity',
    ADMIN11: 'Pre Planned Events',
    ADMIN12: 'Test/Training',
    ADMIN14: 'Contact Record',
    ASB17: 'Personal',
    ASB18: 'Nuisance',
    ASB19: 'Environmental',
    CRIME01: 'Arson',
    CRIME02: 'Burglary Dwelling',
    CRIME03: 'Burglary Other',
    CRIME04: 'Criminal Damage',
    CRIME05: 'Drugs',
    CRIME06: 'Fraud & Forgery',
    CRIME07: 'Robbery',
    CRIME08: 'Sexual Offence',
    CRIME09: 'Theft General',
    CRIME10: 'Autocrime',
    CRIME11: 'SMV',
    CRIME12: 'Terrorism',
    CRIME14: 'Violence',
    CRIME15: 'Other Offences',
    CRIME16: 'Crime Related Incident',
    CRIME17: 'Burglary Commercial Premises',
    CRIME18: 'Economic Crime',
    PSW05: 'Abandoned Call',
    PSW08: 'Civil Dispute',
    PSW11: 'Domestic Incident',
    PSW12: 'Firearms Inc',
    PSW13: 'Immigration',
    PSW14: 'Industrial Incident/Accident',
    PSW16: 'Licensing',
    PSW22: 'Natural Disaster/Warning',
    PSW24: 'Protest/Demonstration',
    PSW25: 'Sudden Death',
    PSW27: 'Suspect Object/Package',
    PSW30: 'Missing Person',
    PSW32: 'Alarm',
    PSW33: 'Hoax Calls',
    PSW34: 'Absc/AWOL/Wanted/Bail',
    PSW35: 'Conc Safety/Trapped/Inj/Ill',
    PSW36: 'Sus Circs/Insecure Prem/Veh',
    PSW37: 'Animals/Wildlife',
    PSW38: 'Absent',
    TRANSP01: 'RTC Damage Only',
    TRANSP05: 'Highway Disruption',
    TRANSP06: 'Road Related Offence',
    TRANSP07: 'Transport Rail/Marine/Air',
    TRANSP09: 'RTC Death/Injury',
  },
  categories: [
    'Immediate Urban',
    'Immediate Rural',
    'Priority',
    'Scheduled',
    'Unknown',
  ],
  status: ['Opened', 'Assigned', 'Deployed', 'Attended', 'Closed'],
};

export const incidentStatusColours = {
  opened: red[500],
  assigned: orange[500],
  attended: green[500],
  closed: grey[500],
};

export const locationTypes = {
  // forceboundaries: { name: 'Force Boundary', icon: 'W' },
  [`${baseType.value}s`]: {
    name: baseType.label,
    icon: <BusinessIcon />,
  },
  wards: { name: 'Ward', icon: <TerrainIcon /> },
  workshops: { name: 'Workshop', icon: <BuildIcon /> },
};

export const planSubtypes = {
  OP: 'Operational',
  EM: 'Emergency',
  PT: 'Patrol',
};

export const featureSubtypes = {
  markers: {
    PI: 'Point of Interest',
    RV: 'Rendezvous',
    SL: 'Safe Location',
    VL: 'Vulnerability',
  },
  paths: {
    FP: 'Foot Patrol',
    MP: 'Mobile Patrol',
    ER: 'Escort Route',
  },
  perimeters: {
    PA: 'Patrol',
    MB: 'Micro Beat',
    OL: 'Order/Legislative',
    EX: 'Exclusion',
    SE: 'Search',
    CO: 'Cordon',
    CL: 'Closed Road',
    ST: 'Static',
    WM: 'Waymarker',
  },
  Marker: {
    PI: 'Point of Interest',
    RV: 'Rendezvous',
    SL: 'Safe Location',
    VL: 'Vulnerability',
  },
  Path: {
    FP: 'Foot Patrol',
    MP: 'Mobile Patrol',
    ER: 'Escort Route',
  },
  Perimeter: {
    PA: 'Patrol',
    MB: 'Micro Beat',
    OL: 'Order/Legislative',
    EX: 'Exclusion',
    SE: 'Search',
    CO: 'Cordon',
    CL: 'Closed Road',
    ST: 'Static',
    WM: 'Waymarker',
  },
};

export const featureSubtypeColours = {
  perimeters: {
    PA: { stroke: 'rgb(63,81,181)', fill: 'rgba(63,81,181,0.5)' },
    MB: { stroke: 'rgb(76,175,80)', fill: 'rgba(76,175,80,0.5)' },
    OL: { stroke: 'rgb(255,193,7)', fill: 'rgba(255,193,7,0.5)' },
    EX: { stroke: 'rgb(244,67,54)', fill: 'rgba(244,67,54,0.5)' },
    SE: { stroke: 'rgb(255,152,0)', fill: 'rgba(255,152,0,0.5)' },
    CO: { stroke: 'rgb(156,39,176)', fill: 'rgba(156,39,176,0.5)' },
    CL: { stroke: 'rgb(255,87,34)', fill: 'rgba(255,87,34,0.5)' },
    ST: { stroke: 'rgb(121,85,72)', fill: 'rgba(121,85,72,0.5)' },
    WM: { stroke: 'rgb(0,150,136)', fill: 'rgba(0,150,136,0.5)' },
  },
};

export const retrospectiveTypeGlyphs = {
  vehicleTrips: carTractionControlGlyph,
  vehicleStops: carBrakeParkingGlyph,
  vehicleStopCount: carBrakeParkingGlyph,
  vehicleIdles: snoozeGlyph,
  vehicleIdleCount: snoozeGlyph,
  vehiclePolls: directionsCarGlyph,
  vehicleVisits: carSideGlyph,
  vehicleTime: carSideGlyph,
  vehicleVisitCount: carSideGlyph,
  incidents: feedbackGlyph,
  incidentCount: feedbackGlyph,
  personTrails: gestureGlyph,
  personVisits: directionsWalkGlyph,
  personTime: directionsWalkGlyph,
  personVisitCount: directionsWalkGlyph,
  personPolls: personGlyph,
  pathStart: playArrowGlyph,
  pathEnd: stopGlyph,
  clusters: clusterGlyph,
};

export function RetrospectiveTypeIcon({ type, className }) {
  switch (type) {
    case 'vehicleTrips':
      return <CarTractionControlIcon className={className} />;
    case 'vehicleStops':
      return <CarBrakeParkingIcon className={className} />;
    case 'vehicleStopCount':
      return <CarBrakeParkingIcon className={className} />;
    case 'vehicleIdles':
      return <SnoozeIcon className={className} />;
    case 'vehicleIdleCount':
      return <SnoozeIcon className={className} />;
    case 'vehiclePolls':
      return <DirectionsCarIcon className={className} />;
    case 'vehicleVisits':
      return <CarSideIcon className={className} />;
    case 'vehicleTime':
      return <CarSideIcon className={className} />;
    case 'vehicleVisitCount':
      return <CarSideIcon className={className} />;
    case 'incidents':
      return <FeedbackIcon className={className} />;
    case 'incidentCount':
      return <FeedbackIcon className={className} />;
    case 'personTrails':
      return <GestureIcon className={className} />;
    case 'personVisits':
      return <DirectionsWalkIcon className={className} />;
    case 'personTime':
      return <DirectionsWalkIcon className={className} />;
    case 'personVisitCount':
      return <DirectionsWalkIcon className={className} />;
    case 'personPolls':
      return <PersonIcon className={className} />;
    case 'areas':
      return <TerrainIcon className={className} />;
    case 'locations':
      return <BusinessIcon className={className} />;
    case 'clusters':
      return <ClusterIcon className={className} />;
    default:
      return <PlaceIcon className={className} />;
  }
}

export function LocationTypeIcon({ type, className }) {
  switch (type) {
    case 'Base':
    case 'Police Station':
    case 'Outstation':
      return <BusinessIcon className={className} />;
    case 'Workshop':
      return <BuildIcon className={className} />;
    case 'Ward':
    default:
      return <TerrainIcon className={className} />;
  }
}

export const replayTypeGlyphs = {
  incident: feedbackGlyph,
  vehicle: directionsCarGlyph,
  person: personGlyph,
  event: warningGlyph,
  location: locationGlyph,
  objective: objectiveGlyph,
  plan: planGlyph,
};

export const featureSubtypeGlyphs = {
  markers: {
    PI: flagGlyph,
    RV: supervisorAccountGlyph,
    SL: verifiedUserGlyph,
    VL: warningGlyph,
  },
  incidents: feedbackGlyph,
  crimes: gavelGlyph,
  features: placeGlyph,
  vehicles: directionsCarGlyph,
  people: personGlyph,
};

export const briefItemTypeIcons = {
  Perimeter: <CropSquareIcon style={{ fontSize: 16 }} />,
  Path: <ShowChartIcon style={{ fontSize: 16 }} />,
  Marker: <PlaceIcon style={{ fontSize: 16 }} />,
  incidents: <FeedbackIcon style={{ fontSize: 16 }} />,
  crimes: <GavelIcon style={{ fontSize: 16 }} />,
  intelligence: <SpeakerNotesIcon style={{ fontSize: 16 }} />,
  Plan: <AssignmentIcon />,
  Query: <SearchIcon />,
  Selection: <BookmarkIcon />,
};

export const objectiveTypes = {
  patrols: { name: 'Patrol', icon: <ThreeSixtyIcon /> },
  visits: { name: 'Visit', icon: <BeenhereIcon /> },
  exclusions: { name: 'Exclusion', icon: <NotInterestedIcon /> },
};

export const objectiveTypeIcons = {
  Patrol: <ThreeSixtyIcon />,
  Visit: <BeenhereIcon />,
};

export function AuditTypeIcon({ type }) {
  switch (type) {
    case 'Vehicle':
      return <DirectionsCarIcon style={{ fontSize: 16 }} />;
    case 'Location':
      return <BusinessIcon style={{ fontSize: 16 }} />;
    case 'Person':
      return <PersonIcon style={{ fontSize: 16 }} />;
    case 'Collection':
      return <LibraryBooksIcon style={{ fontSize: 16 }} />;
    case 'Feature':
      return <FormatShapesIcon style={{ fontSize: 16 }} />;
    case 'Objective':
      return <DataUsageIcon style={{ fontSize: 16 }} />;
    case 'Brief':
      return <WorkIcon style={{ fontSize: 16 }} />;
    case 'Chart':
      return <InsertChartIcon style={{ fontSize: 16 }} />;
    case 'Contents':
      return <ViewListIcon style={{ fontSize: 16 }} />;
    case 'Table':
      return <ReorderIcon style={{ fontSize: 16 }} />;
    case 'Speed Infractions':
      return <SpeedometerIcon style={{ fontSize: 16 }} />;
    case 'Unknown Driver Trips':
      return <AccountQuestionIcon style={{ fontSize: 16 }} />;
    case 'Double Crews':
      return <PeopleIcon style={{ fontSize: 16 }} />;
    case 'Accelerometer Events':
      return <AxisArrowIcon style={{ fontSize: 16 }} />;
    case 'Telematics Box Outages':
      return <ExploreOffIcon style={{ fontSize: 16 }} />;
    case 'Driving Scores':
      return <StarIcon style={{ fontSize: 16 }} />;
    case 'Audit':
      return <ChromeReaderModeIcon style={{ fontSize: 16 }} />;
    case 'Vehicle In Base Time':
      return <TimelineIcon style={{ fontSize: 16 }} />;
    case 'Vehicle In Locations':
      return <ShowChartIcon style={{ fontSize: 16 }} />;
    case 'Task':
      return <AssignmentTurnedInIcon style={{ fontSize: 16 }} />;
    case 'Telematics Box':
      return <RouterIcon style={{ fontSize: 16 }} />;
    case 'Retrospective':
      return <HistoryIcon style={{ fontSize: 16 }} />;
    case 'Layer':
      return <LayersIcon style={{ fontSize: 16 }} />;
    case 'Replay':
      return <PlayArrowIcon style={{ fontSize: 16 }} />;
    case 'Vehicle Mileage':
      return <CounterIcon style={{ fontSize: 16 }} />;
    case 'Incident':
      return <FeedbackIcon style={{ fontSize: 16 }} />;
    case 'Filter':
      return <FilterListIcon style={{ fontSize: 16 }} />;
    case 'Tag':
      return <TagIcon style={{ fontSize: 16 }} />;
    case 'Call Sign':
      return <CallSignIcon style={{ fontSize: 16 }} />;
    case 'Plan':
      return <AssignmentIcon style={{ fontSize: 16 }} />;
    case 'Follow':
      return <FollowIcon style={{ fontSize: 16 }} />;
    case 'CSV':
    case 'JSON':
    case 'GeoJSON':
      return <FileIcon style={{ fontSize: 16 }} />;
    default:
      return '?';
  }
}

export const replayItemTypeIcons = {
  vehicle: <DirectionsCarIcon />,
  location: <BusinessIcon />,
  event: <WarningIcon />,
  person: <PersonIcon />,
  incident: <FeedbackIcon />,
  task: <DoneAllIcon />,
  plan: <AssignmentIcon />,
  objective: <DataUsage />,
};

export const tripClassificationIcons = {
  Business: <BusinessIcon />,
  Personal: <FaceIcon />,
  Commute: <SwapHorizIcon />,
};

export const featureTypeIcons = {
  Perimeter: <CropSquareIcon />,
  Path: <ShowChartIcon />,
  Marker: <PlaceIcon />,
};

export const smallFeatureTypeIcons = {
  Perimeter: <CropSquareIcon style={{ fontSize: 16 }} />,
  Path: <ShowChartIcon style={{ fontSize: 16 }} />,
  Marker: <PlaceIcon style={{ fontSize: 16 }} />,
};

export const featureSubtypeIcons = {
  markers: {
    PI: <FlagIcon />,
    RV: <SupervisorAccountIcon />,
    SL: <VerifiedUserIcon />,
    VL: <WarningIcon />,
    default: <PlaceIcon />,
  },
};

export const smallFeatureSubtypeIcons = {
  markers: {
    PI: <FlagIcon style={{ fontSize: 16 }} />,
    RV: <SupervisorAccountIcon style={{ fontSize: 16 }} />,
    SL: <VerifiedUserIcon style={{ fontSize: 16 }} />,
    VL: <WarningIcon style={{ fontSize: 16 }} />,
    default: <PlaceIcon style={{ fontSize: 16 }} />,
  },
};

export function hexToRgba(hex, a) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r},${g},${b},${a})`;
}

export function randomHsl(step, totalSteps) {
  return `hsl(${(step / totalSteps) * 360}, 100%, 42%)`;
}

export function getKeyLabel(key) {
  const upperCaseNames = ['bcu', 'lpu'];

  return upperCaseNames.includes(key) ? _.upperCase(key) : _.startCase(key);
}

const { excludedFilters = {}, includedFilters = {} } = liveOptions;
function hideFiltersCallback(type) {
  return (filterName) => {
    const excluded =
      excludedFilters[type] && excludedFilters[type].includes(filterName);
    const included =
      !includedFilters[type] || includedFilters[type].includes(filterName);

    return included && !excluded;
  };
}
export const FOLLOW_TAG = 'Followed items';
export const liveFiltersSections = {
  vehicles: {
    vehicle: {
      label: 'Vehicle',
      filters: [
        'fleetNumber',
        'registrationNumber',
        'role',
        'type',
        'homeStation',
        // ...Object.keys(vehicleGroups),
        'areas',
        'colour',
        'make',
        'model',
        'equipmentArray',
        'tagsArray',
      ].filter(hideFiltersCallback('vehicles')),
    },
    inputs: {
      label: 'Telematics',
      filters: [
        ...Object.keys(dioStates),
        'malfunctionIndicatorLightOn',
        'emergencyEquipmentOn',
        'ignitionOn',
        'speedKilometresPerHour',
        'lastIgnitionOffTime',
        'lastPollTime',
        'imei',
        'stale',
      ].filter(hideFiltersCallback('vehicles')),
    },
    driver: {
      label: 'Driver',
      filters: [
        'driverName',
        'driverCollarNumber',
        'driverCode',
        'driverRole',
        'driverCategory',
        'driverAreas',
        'driverSkillsArray',
      ].filter(hideFiltersCallback('vehicles')),
    },
    assignment: {
      label: 'Assignments',
      filters: ['callSign', 'incident', 'status'].filter(
        hideFiltersCallback('vehicles')
      ),
    },
    locations: {
      label: 'Current Locations',
      filters: ['currentLocations'].filter(hideFiltersCallback('vehicles')),
    },
  },
  locations: {
    location: {
      label: 'Location',
      filters: ['name', 'type', 'subtype', 'areas', 'tagsArray'].filter(
        hideFiltersCallback('locations')
      ),
    },
  },
  people: {
    person: {
      label: 'Person',
      filters: [
        'name',
        'code',
        'collarNumber',
        'role',
        'rank',
        'homeStation',
        'areas',
        'category',
        'skills',
        'driverKeys',
        'tagsArray',
      ].filter(hideFiltersCallback('people')),
    },
    radio: {
      label: 'Radio',
      filters: ['radio', 'lastPollTime', 'emergencyButtonOn', 'stale'].filter(
        hideFiltersCallback('people')
      ),
    },
    assignment: {
      label: 'Assignments',
      filters: ['callSign', 'incident', 'status'].filter(
        hideFiltersCallback('people')
      ),
    },
    locations: {
      label: 'Current Locations',
      filters: ['currentLocations'].filter(hideFiltersCallback('people')),
    },
  },
  events: {
    event: {
      label: 'Events',
      filters: [
        'fleetNumber',
        'registrationNumber',
        'homeStation',
        'role',
        'type',
        'time',
        'location',
        'tagsArray',
      ].filter(hideFiltersCallback('events')),
    },
  },
  incidents: {
    incident: {
      label: 'Incident',
      filters: [
        // 'number',
        'group',
        'type',
        'category',
        'responseCategory',
        'status',
        'closingCodes',
        'openedTime',
        'assignedTime',
        'attendedTime',
        'tagsArray',
        'date',
        'reference'
      ].filter(hideFiltersCallback('incidents')),
    },
  },
  tasks: {
    task: {
      label: 'Task',
      filters: [
        'assignedName',
        'assignedCode',
        'assignedCollarNumber',
        'assignedRole',
        'number',
        'occurrences',
        'title',
        'type',
        'group',
        'priority',
        'requiresReview',
        'status',
        'tagsArray',
      ].filter(hideFiltersCallback('tasks')),
    },
  },
  objectives: {
    objective: {
      label: 'Objective',
      filters: [
        'type',
        'identifier',
        'title',
        'areas',
        'briefs',
        'startTime',
        'endTime',
        'status',
        'tagsArray',
      ].filter(hideFiltersCallback('objectives')),
    },
  },
  plans: {
    plan: {
      label: 'Plan',
      filters: [
        'identifier',
        'title',
        'areas',
        'briefs',
        'startTime',
        'endTime',
        'tagsArray',
      ].filter(hideFiltersCallback('plans')),
    },
  },
  tags: {
    tag: {
      label: 'Tag',
      filters: ['id'].filter(hideFiltersCallback('tags')),
    },
  },
  callSigns: {
    callSign: {
      label: 'Call Sign',
      filters: ['id', 'status', 'incident'].filter(
        hideFiltersCallback('callSigns')
      ),
    },
  },
};

// function expandConfigFilters(group, path) {
//   return Object.fromEntries(
//     Object.keys(group).map((key) => [
//       key,
//       {
//         name: group[key].label,
//         value: path + key,
//         mapLabel: (value) =>
//         group[key].values.find((v) => v.value === value)?.label ||
//           value,
//       },
//     ])
//   );
// }

const { filterOptionsOverrides } = liveOptions;
function arrayToOptions(optionsArray) {
  if (!optionsArray || optionsArray.length === 0) {
    return undefined;
  }

  return optionsArray.map((value) => ({ label: value, value }));
}

const onOffOptions = [
  { label: 'On', value: true },
  { label: 'Off', value: false },
];

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const activeOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

// some common filters
const tagsArray = {
  name: 'Tags',
  value: 'tags',
  isArray: true,
  excludeSort: true,
};

const areas = {
  name: 'Areas',
  value: 'reducedAreas',
  isDictionary: true,
};

const stale = {
  name: 'Stale',
  value: 'stale',
  excludeSort: true,
  options: yesNoOptions,
};

export const liveFilters = {
  vehicles: {
    fleetNumber: {
      name: 'Fleet Number',
      value: 'fleetNumber',
    },
    ...(useReducedResourceInformation
      ? {
          type: {
            name: 'Type',
            value: 'type',
          },
        }
      : {
          registrationNumber: {
            name: 'Registration Number',
            value: 'registrationNumber',
          },
          role: {
            name: 'Role',
            value: 'role',
          },
          driverCode: {
            name: 'Code',
            value: 'driver.code',
            prefix: 'Driver',
          },
          driverRole: {
            name: 'Role',
            value: 'driver.role',
            prefix: 'Driver',
          },
        }),
    homeStation: {
      name: useReducedResourceInformation ? 'Home Location' : 'Home Station',
      value: 'homeStation',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    colour: {
      name: 'Colour',
      value: 'colour',
    },
    make: {
      name: 'Make',
      value: 'make',
    },
    model: {
      name: 'Model',
      value: 'model',
    },
    ...Object.fromEntries(
      Object.keys(dioStates)
        .filter((key) => !key.startsWith('unused'))
        .map((key) => [
          key,
          {
            name: dioStates[key],
            value: key,
            options: onOffOptions,
            type: 'autocomplete',
            excludeSort: true,
          },
        ])
    ),
    //...expandConfigFilters(vehicleGroups, 'reducedAreas.'),
    areas: { ...areas, prefix: 'Vehicle' },
    driverAreas: {
      name: 'Driver Areas',
      value: 'reducedDriverAreas',
      isDictionary: true,
      prefix: 'Driver',
    },
    speedKilometresPerHour: {
      name: 'Speed',
      value: 'speedKilometresPerHour',
      type: 'miles',
      unit: 'mph',
    },
    stale,
    emergencyEquipmentOn: {
      name: 'Warning Equipment',
      value: 'emergencyEquipmentOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    status: {
      name: 'Status',
      value: 'assignments.callSign.status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      changeful: true,
    },
    driverCollarNumber: {
      name: 'Collar Number',
      value: 'driver.collarNumber',
      prefix: 'Driver',
    },
    driverName: {
      name: useReducedResourceInformation ? 'Staff ID' : 'Name',
      value: 'driverName', // derived so not in driver object
      prefix: 'Driver',
    },
    driverCategory: {
      name: 'Category',
      value: 'driver.category',
      prefix: 'Driver',
    },
    driverSkillsArray: {
      name: 'Skills',
      // derived from skills object array[{name: 'Skill 1', type: 'skill'}] to ['Skill 1']
      value: 'driverSkillsArray',
      isArray: true,
      excludeSort: true,
      prefix: 'Driver',
    },
    callSign: {
      name: 'Call Sign',
      value: 'assignments.callSign.code',
      changeful: true,
    },
    incident: {
      name: 'Incident',
      value: 'assignments.incident.number',
      changeful: true,
    },
    ignitionOn: {
      name: 'Ignition',
      value: 'ignitionOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    lastIgnitionOffTime: {
      name: 'Last Ignition Off Time',
      value: 'lastIgnitionOffTime',
      type: 'datetime',
    },
    lastPollTime: {
      name: 'Last Poll Time',
      value: 'lastPollTime',
      type: 'datetime',
    },
    imei: {
      name: 'IMEI',
      value: 'telematicsBoxImei',
    },
    currentLocations: {
      name: 'Current Locations',
      value: 'reducedCurrentLocations',
      isDictionary: true,
      prefix: 'Location:',
      // excludeSort: true
    },
    equipmentArray: {
      name: 'Equipment',
      // derived from skills object array[{name: 'Skill 1', type: 'skill'}] to ['Skill 1']
      value: 'equipment',
      isArray: true,
      excludeSort: true,
    },
    malfunctionIndicatorLightOn: {
      name: 'Malfunction Indicator Light',
      value: 'malfunctionIndicatorLightOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    tagsArray,
  },
  locations: {
    name: {
      name: 'Name',
      value: 'name',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    subtype: {
      name: 'Subtype',
      value: 'subtype',
    },
    //...expandConfigFilters(personGroups, 'reducedAreas.'),
    areas,
    tagsArray,
  },
  people: {
    ...(useReducedResourceInformation
      ? undefined
      : {
          name: {
            name: 'Name',
            value: 'name',
          },
          role: {
            name: 'Role',
            value: 'role',
          },
          rank: {
            name: 'Rank',
            value: 'rank.name',
          },
        }),
    code: {
      name: useReducedResourceInformation ? 'Staff ID' : 'Code',
      value: 'code',
    },
    collarNumber: {
      name: 'Collar Number',
      value: 'collarNumber',
    },
    radio: {
      name: 'Radio SSI',
      value: 'radioSsi',
    },
    homeStation: {
      name: useReducedResourceInformation ? 'Home Location' : 'Home Station',
      value: 'homeStation',
    },
    category: {
      name: 'Category',
      value: 'category',
    },
    stale,
    skills: {
      name: 'Skills',
      value: 'skillsArray',
      isArray: true,
      excludeSort: true,
    },
    areas,
    currentLocations: {
      name: 'Current Locations',
      value: 'reducedCurrentLocations',
      isDictionary: true,
      // excludeSort: true
    },
    driverKeys: {
      name: window.config.useDallasKeys ? 'Dallas Keys' : 'RFIDs',
      value: 'driverKeysArray',
      isArray: true,
    },
    status: {
      name: 'Status',
      value: 'assignments.callSign.status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      changeful: true,
    },
    callSign: {
      name: 'Call Sign',
      value: 'assignments.callSign.code',
      changeful: true,
    },
    incident: {
      name: 'Incident',
      value: 'assignments.incident.number',
      changeful: true,
    },
    emergencyButtonOn: {
      name: 'Emergency Button',
      value: 'emergencyButtonOn',
      type: 'autocomplete',
      options: onOffOptions,
    },
    lastPollTime: {
      name: 'Last Poll Time',
      value: 'lastPollTime',
      type: 'datetime',
    },
    tagsArray,
  },
  events: {
    fleetNumber: {
      name: 'Fleet Number',
      value: 'vehicle.fleetNumber',
    },
    registrationNumber: {
      name: 'Registration Number',
      value: 'vehicle.registrationNumber',
    },
    homeStation: {
      name: useReducedResourceInformation ? 'Home Location' : 'Home Station',
      value: 'vehicle.homeStation',
    },
    role: {
      name: 'Role',
      value: 'vehicle.role',
    },
    type: {
      name: 'Type',
      value: 'vehicle.type',
    },
    time: {
      name: 'Date',
      value: 'datetime',
      type: 'date',
    },
    location: {
      name: 'Location',
      value: 'reducedCurrentLocations',
      isDictionary: true,
    },
    tagsArray,
  },
  incidents: {
    number: {
      name: 'Number',
      value: 'number',
      excludeSort: true,
    },
    group: {
      name: 'Group',
      value: 'group',
    },
    type: {
      name: 'Type',
      value: 'type.name',
    },
    category: {
      name: 'Category',
      value: 'category.name',
    },
    responseCategory: {
      name: 'Response Category',
      value: 'responseCategory.name',
    },
    status: {
      name: 'Status',
      value: 'status',
    },
    closingCodes: {
      name: 'Closing Codes',
      value: 'closingCodesArray',
      isArray: true,
    },
    openedTime: {
      name: 'Opened Time',
      value: 'openedTime',
      type: 'datetime',
    },
    assignedTime: {
      name: 'Assigned Time',
      value: 'assignedTime',
      type: 'datetime',
    },
    attendedTime: {
      name: 'Attended Time',
      value: 'attendedTime',
      type: 'datetime',
    },
    reference: {
      name: 'Number',
      value: 'reference',
      type: 'number',
      onlyEqual: true,
      parse: (x => x?.toString() || ''),
      //format: (x => x?.toString() || '')
    },
    date: {
      name: 'Date',
      value: 'date',
      type: 'date',
      onlyEqual: true,
      parse: (x => {
        x = moment(x).local();
        if (x.isValid()) {
          return x.format('YYYY-MM-DD');
        }
        return '';
      })
    },
    tagsArray,
  },
  tasks: {
    assignedName: {
      name: 'Name',
      value: 'assignedName',
    },
    assignedCode: {
      name: 'Code',
      value: 'assignedPerson.code',
    },
    assignedCollarNumber: {
      name: 'CollarNumber',
      value: 'assignedPerson.CollarNumber',
    },
    assignedRole: {
      name: 'Role',
      value: 'assignedPerson.Role',
    },
    number: {
      name: 'Number',
      value: 'number',
    },
    occurrences: {
      name: 'Occurrences',
      value: 'occurrences',
    },
    title: {
      name: 'Title',
      value: 'title',
    },
    type: {
      name: 'Type',
      value: 'type.name',
    },
    group: {
      name: 'Group',
      value: 'group.name',
    },
    priority: {
      name: 'Priority',
      value: 'priority.name',
    },
    requiresReview: {
      name: 'Requires Review',
      value: 'requiresReview',
    },
    status: {
      name: 'Status',
      value: 'status.name',
    },
    // assignee: {
    //   name: 'Assignee',
    //   value: 'assignee',
    // },
    // description: {
    //   name: 'Description',
    //   value: 'description',
    // },
    tagsArray,
  },
  objectives: {
    type: {
      name: 'Type',
      value: 'type',
    },
    identifier: {
      name: 'Identifier',
      value: 'identifier',
    },
    title: {
      name: 'Title',
      value: 'title',
    },
    areas,
    briefs: {
      name: 'Briefs',
      value: 'briefs',
      isArray: true,
    },
    startTime: {
      name: 'Start Date',
      value: 'startTime',
      type: 'date',
    },
    endTime: {
      name: 'End Date',
      value: 'endTime',
      type: 'date',
    },
    status: {
      name: 'Status',
      value: 'status',
      options: activeOptions,
    },
    tagsArray,
  },
  plans: {
    identifier: {
      name: 'Identifier',
      value: 'identifier',
    },
    title: {
      name: 'Title',
      value: 'title',
    },
    areas,
    briefs: {
      name: 'Briefs',
      value: 'briefs',
      isArray: true,
    },
    startTime: {
      name: 'Start Date',
      value: 'startTime',
      type: 'date',
    },
    endTime: {
      name: 'End Date',
      value: 'endTime',
      type: 'date',
    },
    tagsArray,
  },
  tags: {
    id: {
      name: 'Name',
      value: 'id',
    },
  },
  callSigns: {
    id: {
      name: 'Name',
      value: 'id',
    },
    status: {
      name: 'Status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      value: 'status',
    },
    incident: {
      name: 'Incident',
      value: 'incident.number',
    },
  },
};

// quick check that items in sections exist in liveFilter definitions
// function checkSections() {
//   Object.keys(liveFiltersSections).forEach(type => {
//     Object.keys(liveFiltersSections[type]).forEach(sectionKey => {
//       const { filters } = liveFiltersSections[type][sectionKey];
//       filters.forEach(filterKey => {
//         if (!liveFilters[type][filterKey]) {
//           console.warn(`Unknown filter ${filterKey} for ${type} in ${sectionKey} section`)
//         }
//       });
//     })
//   })
// }
// checkSections();

export const liveTypes = {
  vehicle: { listName: 'vehicles', route: '/live/vehicles' },
  location: { listName: 'locations', route: '/live/locations' },
  person: { listName: 'people', route: '/live/people' },
  event: { listName: 'events', route: '/live/events' },
  incident: { listName: 'incidents', route: '/live/incidents' },
  task: { listName: 'tasks', route: '/live/tasks' },
  plan: { listName: 'plans', route: '/live/plans' },
};

export const liveGroups = {
  vehicles: 'vehicle',
  locations: 'location',
  people: 'person',
  events: 'event',
  incidents: 'incident',
  tasks: 'task',
  plans: 'plan',
};

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export const taskPriorities = [
  { code: '1', name: 'high' },
  { code: '0', name: 'normal' },
  { code: '-1', name: 'low' },
];

export const taskTypes = [
  { code: 'PATROL001', name: 'Statement' },
  { code: 'PATROL002', name: 'Report' },
  { code: 'PATROL003', name: 'Visit' },
];

export const livePaths = {
  vehicle:
    'M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z',
  person:
    'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z',
  ignition:
    'M7,14A2,2 0 0,1 5,12A2,2 0 0,1 7,10A2,2 0 0,1 9,12A2,2 0 0,1 7,14M12.65,10C11.83,7.67 9.61,6 7,6A6,6 0 0,0 1,12A6,6 0 0,0 7,18C9.61,18 11.83,16.33 12.65,14H17V18H21V14H23V10H12.65Z',
  malfunctionIndicatorLight:
    'M7,4V6H10V8H7L5,10V13H3V10H1V18H3V15H5V18H8L10,20H18V16H20V19H23V9H20V12H18V8H12V6H15V4H7Z',
  light:
    'M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63Z',
  trailer:
    'M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z',
  beacon:
    'M6,6.9L3.87,4.78L5.28,3.37L7.4,5.5L6,6.9M13,1V4H11V1H13M20.13,4.78L18,6.9L16.6,5.5L18.72,3.37L20.13,4.78M4.5,10.5V12.5H1.5V10.5H4.5M19.5,10.5H22.5V12.5H19.5V10.5M6,20H18A2,2 0 0,1 20,22H4A2,2 0 0,1 6,20M12,5A6,6 0 0,1 18,11V19H6V11A6,6 0 0,1 12,5Z',
  siren:
    'M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z',
  driver:
    'M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z',
  follow:
    'M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z',
};

export const sourceTypes = {
  incidents: 'Incidents',
  crimes: 'Crimes',
  intelligence: 'Intelligence',
  stopChecks: 'Stop Checks',
};

export const mapGlyphsByTypeAndSubtype = {
  incident: {
    default: feedbackGlyph,
    1: grade1Glyph,
    2: grade2Glyph,
    3: grade3Glyph,
    4: grade4Glyph,
    5: grade5Glyph,
  },
  vehicle: { default: directionsCarGlyph },
  person: { default: personGlyph },
  event: { default: warningGlyph },
  location: { default: locationGlyph },
  // caps for Patrol and Visit are important so it matches objective.type
  objective: {
    default: objectiveGlyph,
    Patrol: threeSixtyGlyph,
    Visit: beenHereGlyph,
  },
  plan: {
    default: planGlyph,
    Path: planGlyph,
    Perimeter: planGlyph,
    Marker: placeGlyph,
    ...featureSubtypeGlyphs.markers,
  },
};

const outline = 'rgba(255,255,255,1.0)';
const { statusColours } = liveOptions;
// from outside to in these arrays are colours for [outline, fill, glyph]
export const commonResourceColours = {
  ...(statusColours?.callSigns || {
    assigned: [outline, 'rgba(67,160,71,1.0)', 'rgba(255,255,255,1.0)'],
    unavailable: [outline, 'rgba(156,39,176,1.0)', 'rgba(255,255,255,1.0)'],
  }),
  // default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
};

export const statusIconColoursByType = {
  default: {
    default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
    hover: [
      'rgba(117,117,117,1.0)',
      'rgba(255,255,255,1.0)',
      'rgba(117,117,117,1.0)',
    ],
    select: ['rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)', 'rgba(0,0,0,1.0)'],
  },
  person: {
    emergency: ['rgba(0,0,0,1.0)', 'rgba(244,67,54,1.0)', 'rgba(0,0,0,1.0)'],
    ...commonResourceColours,
    ...(statusColours?.people || {}),
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  vehicle: {
    // emergency: ['rgba(0,0,0,1.0)', 'rgba(244,67,54,1.0)', 'rgba(0,0,0,1.0)'],
    ...(isFleet ? {} : commonResourceColours),
    ...(statusColours?.vehicles || {}),
    'warning equipment': [outline, 'rgb(0,188,212)', 'rgba(255,255,255,1.0)'],
    malfunctioning: [
      'rgba(0,0,0,1.0)',
      'rgba(255,193,7,1.0)',
      'rgba(0,0,0,1.0)',
    ],
    parked: [outline, 'rgba(117,117,117,1.0)', 'rgba(255,255,255,1.0)'],
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  callSign: {
    ...commonResourceColours,
  },
  incident: {
    ...Object.fromEntries(
      Object.keys(incidentStatusColours).map((status) => [
        status,
        [outline, incidentStatusColours[status], 'rgba(255,255,255,1.0)'],
      ])
    ),
    ...(statusColours?.incidents || {}),
  },
  objective: {
    active: [outline, 'rgba(33,150,243,1.0)', 'rgba(255,255,255,1.0)'],
    inactive: [outline, 'rgba(189,189,189,1.0)', 'rgba(255,255,255,1.0)'],
    ...(statusColours?.objectives || {}),
  },
  plan: {
    default: [outline, 'rgb(0,0,0)', 'rgb(255,255,255)'],
    hover: [
      'rgba(117,117,117,1.0)',
      'rgba(255,255,255,1.0)',
      'rgba(117,117,117,1.0)',
    ],
    select: ['rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)', 'rgba(0,0,0,1.0)'],
    Path: ['rgba(0,0,0,1.0)', 'rgba(0,0,0,1.0)', 'rgba(0,0,0,1.0)'],
    ...Object.fromEntries(
      Object.keys(featureSubtypeColours.perimeters).map((status) => [
        status,
        [
          featureSubtypeColours.perimeters[status].stroke,
          featureSubtypeColours.perimeters[status].fill,
          'rgba(255,255,255,1.0)',
        ],
      ])
    ),
    ...(statusColours?.plans || {}),
  },
  // bit of a hack so they are all in one place
};

// without having to retype everything above use the order
// to assume the zIndex
export const statusZIndexByType = Object.fromEntries(
  Object.entries(statusIconColoursByType).map(([type, statusColours]) => [
    type,
    Object.fromEntries(
      Object.keys(statusColours)
        .reverse()
        .map((status, index) => [status, index])
    ),
  ])
);

export const liveIconsByTypeAndSubtype = {
  incident: { default: <FeedbackIcon /> },
  vehicle: { default: <DirectionsCarIcon /> },
  person: { default: <PersonIcon /> },
  event: { default: <WarningIcon /> },
  location: { default: <BusinessIcon /> },
  objective: {
    default: <DataUsage />,
    ...objectiveTypeIcons,
  },
  plan: { default: <AssignmentIcon /> },
  task: { default: <DoneAllIcon /> },
  tag: {
    default: <TagIcon />,
    follow: <FollowIcon />,
  },
  callSign: { default: <CallSignIcon /> },
  default: { default: '?' },
};

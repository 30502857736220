import { Divider, makeStyles, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import {
  ColorsField,
  DateTimeField,
  DebouncedTextField,
  Field,
  requiredDateAfter,
  requiredDateBefore,
  requiredNotNegative,
  SelectField,
} from '../fields';
import { compareLabels } from './constants';

const { areaTypes } = window.config;

const useStyles = makeStyles((theme) => ({
  dateTimeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 197,
  },
  endTimeField: {
    marginTop: theme.spacing(1),
    width: 198,
  },
  colorsField: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  typeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 80,
  },
  visualisationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

}));

export default function TypeParameters({ type, name, value }) {
  const classes = useStyles();

  switch (type) {
    case 'area':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Time Period
          </Typography>
          <Field
            className={classes.dateTimeField}
            label="Start"
            name={`${name}.startTime`}
            component={DateTimeField}
            maxDate={value.endTime}
            validate={requiredDateBefore(value.endTime)}
          />
          <Field
            className={classes.endTimeField}
            label="End"
            name={`${name}.endTime`}
            component={DateTimeField}
            minDate={value.startTime}
            validate={requiredDateAfter(value.startTime)}
          />
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            className={classes.colorsField}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            Area
          </Typography>
          <Field
            className={classes.dateTimeField}
            label="Type"
            name={`${name}.areaType`}
            component={SelectField}
            values={areaTypes.sort(compareLabels)}
          />
        </Fragment>
      );
    case 'bubble':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Time Period
          </Typography>
          <Field
            className={classes.dateTimeField}
            label="Start"
            name={`${name}.startTime`}
            component={DateTimeField}
            maxDate={value.endTime}
            validate={requiredDateBefore(value.endTime)}
          />
          <Field
            className={classes.endTimeField}
            label="End"
            name={`${name}.endTime`}
            component={DateTimeField}
            minDate={value.startTime}
            validate={requiredDateAfter(value.startTime)}
          />
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            className={classes.colorsField}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
          <Field
            className={classes.typeField}
            initialValue={10}
            placeholder="10"
            name={`${name}.distance`}
            component={DebouncedTextField}
            label="Distance"
            type="number"
            inputProps={{ min: 1, max: 20, step: 1 }}
            validate={requiredNotNegative}
          />
          <Field
            className={classes.typeField}
            initialValue={0.5}
            placeholder="0.5"
            name={`${name}.growth`}
            component={DebouncedTextField}
            label="Growth"
            type="number"
            inputProps={{ min: 0.1, max: 1, step: 0.1 }}
            validate={requiredNotNegative}
          />
        </Fragment>
      );
    case 'heat':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Time Period
          </Typography>
          <Field
            className={classes.dateTimeField}
            label="Start"
            name={`${name}.startTime`}
            component={DateTimeField}
            maxDate={value.endTime}
            validate={requiredDateBefore(value.endTime)}
          />
          <Field
            className={classes.endTimeField}
            label="End"
            name={`${name}.endTime`}
            component={DateTimeField}
            minDate={value.startTime}
            validate={requiredDateAfter(value.startTime)}
          />
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <div className={classes.visualisationContainer}>
            <div>
              <Field
                className={classes.colorsField}
                label="Colors"
                name={`${name}.colors`}
                component={ColorsField}
                min={2}
              />
              <Field
                className={classes.typeField}
                name={`${name}.blur`}
                component={DebouncedTextField}
                label="Blur"
                type="number"
                initialValue={10}
                inputProps={{ min: 1, max: 20, step: 1 }}
                validate={requiredNotNegative}
              />
              <Field
                className={classes.typeField}
                name={`${name}.radius`}
                component={DebouncedTextField}
                label="Radius"
                type="number"
                initialValue={10}
                inputProps={{ min: 1, max: 20, step: 1 }}
                validate={requiredNotNegative}
              />
            </div>
          </div>
        </Fragment>
      );
    case 'shape':
      return (
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Time Period
          </Typography>
          <Field
            className={classes.dateTimeField}
            label="Start"
            name={`${name}.startTime`}
            component={DateTimeField}
            maxDate={value.endTime}
            validate={requiredDateBefore(value.endTime)}
          />
          <Field
            className={classes.endTimeField}
            label="End"
            name={`${name}.endTime`}
            component={DateTimeField}
            minDate={value.startTime}
            validate={requiredDateAfter(value.startTime)}
          />
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" color="textSecondary">
            Visualisation
          </Typography>
          <Field
            className={classes.colorsField}
            label="Colour"
            name={`${name}.colors`}
            component={ColorsField}
            max={1}
          />
        </Fragment>
      );
    default:
      return '';
  }
}

import _ from 'lodash';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import TelematicsBoxList from './TelematicsBoxList';
import TelematicsBoxDetail from './TelematicsBoxDetail';
import {
  FETCH_TELEMATICS_BOXES,
  END_TELEMATICS_BOX_POLLS_STREAM,
} from '../../../actions';
import Container from '../../Container';
import { useSnackbar } from '../../Snackbar';
import { log } from '../../../apis/utilities';
import { usePrevious } from '../../../hooks';
import {
  CommissioningDialogProvider,
} from './CommissioningDialog';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  listSection: {
    width: 310,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  itemSection: {
    width: 'calc(100% - 280px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
}));

export default function TelematicsBoxPage() {
  const { id } = useParams();
  const prevId = usePrevious(id);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  // log when a new id is navigated to
  useEffect(() => {
    if (id && id !== prevId) {
      log('Read', 'Telematics Box', { id });
    }
  }, [id, prevId]);

  // get the tbs when starting up
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: FETCH_TELEMATICS_BOXES });

    return () => {
      dispatch({ type: END_TELEMATICS_BOX_POLLS_STREAM });
    };
  }, [dispatch]);

  const snackbar = useSnackbar();
  const error = useSelector((state) => state.telematicsBoxes.error, _.isEqual);
  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  return (
    <CommissioningDialogProvider>
      <Container title="Telematics Boxes">
        <Helmet>
          <title>IR3 | Telematics Boxes</title>
        </Helmet>
        {(!isXs || !id) && (
          <div className={classes.listSection}>
            <TelematicsBoxList props={{ match: { params: id } }} />
          </div>
        )}
        {(!isXs || id) && (
          <div className={classes.itemSection}>
            {id ? ( // if there's an id show the individual box
              <Route path="/resources/telematicsboxes/:id">
                <TelematicsBoxDetail key={id} />
              </Route>
            ) : (
              <Fragment />
              // move to reports instead
              // otherwise there's no id, show the diagnostics
              // <TelematicsBoxDiagnostics />
            )}
          </div>
        )}
      </Container>
    </CommissioningDialogProvider>
  );
}

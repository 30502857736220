import { Slider } from '@material-ui/core';

export default function SliderField({ input: {value, ...input}, meta, fallbackValue, ...props }) {
  function handleChange(e, v) {
    input.onChange(v);
  }

  // make sure value is an int
  const parsed = parseInt(value);
  value = isNaN(parsed) ? (fallbackValue || 0) : parsed;

  return (
    <Slider
      //helperText={meta.touched && (meta.error || meta.submitError)}
      //error={meta.touched && meta.invalid}
      {...input}
      {...props}
      value={value}
      onChange={handleChange}
    />
  );
}

import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Search as SearchIcon, Person as PersonIcon } from '@material-ui/icons';

const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    margin: theme.spacing(1),
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    transition: theme.transitions.create('width'),
    width: 240,
  },
  content: {
    padding: 0,
  },
}));

export default function PersonDialog({ onClose, open, people }) {
  const [id, setId] = useState('');
  const [filterText, setFilterText] = useState('');
  const classes = useStyles();

  function handleCancel() {
    onClose(null);
  }

  async function handleSelect() {
    onClose(id);
  }

  function handleListItemClick(event) {
    setId(event.currentTarget.dataset.id);
  }

  function handleListItemDoubleClick(event) {
    onClose(event.currentTarget.dataset.id);
  }

  function handleFilterTextChange(event) {
    setFilterText(event.target.value);
  }

  const searchFilter = useReducedResourceInformation
    ? (person) =>
        filterText &&
        person.code.toLowerCase().includes(filterText.toLowerCase())
    : (person) =>
        filterText &&
        (`${person.forenames || ''} ${person.surname || ''}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
          (person.collarNumber || '')
            .toLowerCase()
            .includes(filterText.toLowerCase()));

  // const suggestions = useReducedResourceInformation
  //   ? people
  //       .sort((a, b) => a.code.localeCompare(b.code))
  //       .map(person => ({
  //         label: person.code,
  //         value: person.code
  //       }))
  //   : people
  //       .sort((a, b) =>
  //         `${a.surname}, ${a.forenames}`.localeCompare(
  //           `${b.surname}, ${b.forenames}`
  //         )
  //       )
  //       .map(person => ({
  //         label: `${person.forenames} ${person.surname}`,
  //         value: person.code
  //       }));

  return (
    <Dialog onClose={handleCancel} open={open}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={filterText}
          onChange={handleFilterTextChange}
        />
      </div>
      <DialogContent className={classes.content}>
        <List disablePadding>
          {people
            .filter(searchFilter)
            .slice(0, 5)
            .map((person) => (
              <ListItem
                button
                data-id={person.code}
                onClick={handleListItemClick}
                onDoubleClick={handleListItemDoubleClick}
                selected={id === person.code}
                key={person.code}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={person.picture}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    useReducedResourceInformation
                      ? person.code
                      : `${person.forenames || ''}  ${person.surname || ''}`
                  }
                  secondary={person.collarNumber}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button disabled={id === ''} onClick={handleSelect} color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  Avatar,
  Paper,
  Typography,
  IconButton,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { PlayArrow as PlayArrowIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Container from '../Container';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_ATTENDANCES,
  FETCH_ATTENDANCES_CANCELLED,
  UPDATE_ATTENDANCES_FILTER,
  UPDATE_ATTENDANCES_QUERY,
} from '../../actions';
import { FilterPicker, Parameters, Table, TablePagination } from '../controls';
import React, { useState, useMemo, useEffect } from 'react';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import { downloadCSV } from '../../apis/utilities';

const { rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  timeWrapperDays: {
    flex: '0 0 8.3%',
  },
  timeWrapperHours: {
    flex: '0 0 8.3%',
    margin: theme.spacing(0.5, 0, 2, 0),
  },
  selectedTimeDays: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
    fontSize: 10,
    marginRight: 2,
  },
  unselectedTimeDays: {
    color: theme.palette.primary.contrastText,
    width: 20,
    height: 20,
    fontSize: 10,
    marginRight: 2,
  },
  selectedTimeHours: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
    fontSize: 10,
    marginRight: -13,
    marginBottom: -16,
  },
  unselectedTimeHours: {
    color: theme.palette.primary.contrastText,
    width: 20,
    height: 20,
    fontSize: 10,
    marginRight: -13,
    marginBottom: -16,
  },
}));

const activeTimeType = (type) => (props) => ActiveTime(props, type);

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/personObjectiveAttendances/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function Attendance({ entry }) {
  const [orderBy, setOrderBy] = useState('startTime');
  const [order, setOrder] = useState('asc');

  const entryAttendances = entry.attendances;

  const areas = Array.from(
    new Set(entryAttendances.flatMap((record) => Object.keys(record.areas)))
  );

  const attendanceHeaders = [
    {
      label: 'Collar Number',
      key: 'person.collarNumber',
      type: 'text',
      filter: true,
    },
    {
      label: 'Forenames',
      key: 'person.forenames',
      type: 'text',
      filter: true,
    },
    {
      label: 'Surname',
      key: 'person.surname',
      type: 'text',
      filter: true,
    },
    {
      label: 'Role',
      key: 'person.role',
      type: 'text',
      filter: true,
    },
    ...areas.map((area) => ({
      label: _.startCase(area),
      key: ['areas', area],
      type: 'text',
      filter: true,
    })),
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: '',
      key: 'replay',
      type: 'component',
      component: ReplayLink,
      filter: false,
    },
  ];

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  return (
    <Table
      data={entry.attendances}
      headers={attendanceHeaders}
      rowsPerPage={entry.attendances.length}
      page={0}
      keyName="identifier"
      orderBy={orderBy}
      order={order}
      onOrderChange={handleOrderChange}
      onOrderByChange={handleOrderByChange}
    />
  );
}

const objectiveHeaders = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: Attendance,
    filter: false,
  },
  {
    label: 'Identifier',
    key: 'identifier',
    type: 'text',
    filter: true,
  },
  {
    label: 'Title',
    key: 'title',
    type: 'text',
    filter: true,
  },
  {
    label: 'Type',
    key: 'type',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Days Active',
    key: 'days',
    type: 'component',
    component: activeTimeType('days'),
    filter: false,
  },
  {
    label: 'Hours Active',
    key: 'hours',
    type: 'component',
    component: activeTimeType('hours'),
    filter: false,
  },
];

const objectiveFilterHeaders = [
  {
    label: 'Objective Identifier',
    key: 'identifier',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Title',
    key: 'title',
    type: 'text',
    filter: true,
  },
  {
    label: 'Objective Type',
    key: 'type',
    type: 'text',
    filter: true,
  },
];

function objectifyKey(headers) {
  return headers.map((header) => ({
    ...header,
    key: 'objective.' + header.key,
  }));
}

function ActiveTime({ entry }, type) {
  const days = entry.days !== undefined ? entry.days : [];
  const hours = entry.hours !== undefined ? entry.hours : [];

  const classes = useStyles();

  switch (type) {
    case 'days':
      return (
        <div className={classes.timeContainer}>
          {[...'MTWTFSS'].map((letter, index) => (
            <div className={classes.timeWrapperDays} key={letter + index}>
              <Avatar
                className={
                  days.includes(index)
                    ? classes.selectedTimeDays
                    : classes.unselectedTimeDays
                }
              >
                {letter}
              </Avatar>
            </div>
          ))}
        </div>
      );
    case 'hours':
      return (
        <div className={classes.timeContainer}>
          {[...Array(24).keys()].map((hour) => (
            <div className={classes.timeWrapperHours} key={hour}>
              <Avatar
                className={
                  hours.includes(hour)
                    ? classes.selectedTimeHours
                    : classes.unselectedTimeHours
                }
              >
                {hour}
              </Avatar>
            </div>
          ))}
        </div>
      );
    default:
      return <></>;
  }
}

const attendanceFilterHeaders = (areas) => [
  {
    label: 'Collar Number',
    key: 'person.collarNumber',
    type: 'text',
    filter: true,
  },
  {
    label: 'Forenames',
    key: 'person.forenames',
    type: 'text',
    filter: true,
  },
  {
    label: 'Surname',
    key: 'person.surname',
    type: 'text',
    filter: true,
  },
  {
    label: 'Role',
    key: 'person.role',
    type: 'text',
    filter: true,
  },
  ...areas.map((area, index) => ({
    label: _.startCase(area),
    key: `person.areas.${index}.name`,
    type: 'text',
    filter: true,
  })),
];

export default function Attendances() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.events.attendances.isLoading);
  const error = useSelector((state) => state.events.attendances.error);

  const attendances = useSelector(
    (state) => state.events.attendances.list,
    _.isEqual
  );

  const areas = Array.from(
    new Set(
      attendances.flatMap((record) =>
        record.person.areas.map((area) => area.type)
      )
    )
  );

  const filter = useSelector(
    (state) => state.events.attendances.filter,
    _.isEqual
  );

  const query = useSelector(
    (state) => state.events.attendances.query,
    _.isEqual
  );

  const classes = useStyles();

  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  const data = useMemo(() => {
    const filteredAttendances = filterLocally(filter, attendances);

    const objectiveToAttendances = {};
    filteredAttendances.forEach((attendance) => {
      const objectiveId = attendance.objective.identifier;
      const objective = objectiveToAttendances[objectiveId] || {
        ...attendance.objective,
        attendances: [],
      };

      objective.attendances.push(attendance);
      objectiveToAttendances[objectiveId] = objective;
    });

    return Object.values(objectiveToAttendances);
  }, [attendances, filter]);

  function handleDownloadClick() {
    const filename = 'Person Attendances.csv';
    const attendanceHeaders = [
      {
        label: 'Collar Number',
        key: 'person.collarNumber',
        type: 'text',
      },
      {
        label: 'Forenames',
        key: 'person.forenames',
        type: 'text',
      },
      {
        label: 'Surname',
        key: 'person.surname',
        type: 'text',
      },
      {
        label: 'Role',
        key: 'person.role',
        type: 'text',
      },
      ...areas.map((area) => ({
        label: _.startCase(area),
        key: area,
        type: 'text',
      })),
      {
        label: 'Start Time',
        key: 'startTime',
        type: 'date',
      },
      {
        label: 'End Time',
        key: 'endTime',
        type: 'date',
      },
    ];

    const dataToDownload = data
      .flatMap((objective) => objective.attendances)
      .map((attendance) => ({
        ...attendance,
        'person.collarNumber': attendance.person.collarNumber,
        'person.forenames': attendance.person.forenames,
        'person.surname': attendance.person.surname,
        'person.role': attendance.person.role,
        ...attendance.areas,
        startTime: new Date(attendance.startTime),
        endTime: new Date(attendance.endTime),
      }));

    downloadCSV(dataToDownload, filename, attendanceHeaders);
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_ATTENDANCES_QUERY,
      payload: query,
    });
  }

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ATTENDANCES,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ATTENDANCES_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ATTENDANCES_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  return (
    <Container title="Attendances">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        person
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Attendances</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Attendances
          </Typography>
          <FilterPicker
            headers={objectifyKey(objectiveFilterHeaders).concat(
              attendanceFilterHeaders(areas)
            )}
            data={attendances}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={data.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={data}
            headers={objectiveHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

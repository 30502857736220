import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {
  signalStrengthIcon,
  convertSoftwareId,
  mapCanBusStatus,
  mapCanBusStatusAbbreviated,
  OnOffIcon,
} from './utilities';

const { hideLonLat } = window.config.dioOptions;

export default function PollTable({ polls, pollsToShow }) {
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down(1000));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const breakValAt = (val, n) => {
    return val.toString().substring(0, n) + '\n' + val.toString().substring(n);
  };

  const pollLabels = () => {
    return isMedium ? (
      <TableRow>
        <TableCell>F/W</TableCell>
        <TableCell>Time</TableCell>
        {!hideLonLat && (
          <>
            <TableCell>Lat</TableCell>
            <TableCell>Lon</TableCell>
          </>
        )}
        <TableCell>GPS</TableCell>
        <TableCell>Ign</TableCell>
        <TableCell>Batt</TableCell>
        <TableCell>Signal</TableCell>
        <TableCell>CAN</TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell>Firmware Version</TableCell>
        <TableCell>Time</TableCell>
        {!hideLonLat && (
          <>
            <TableCell>Latitude</TableCell>
            <TableCell>Longitude</TableCell>
          </>
        )}
        <TableCell>Location Valid</TableCell>
        <TableCell>Ignition</TableCell>
        <TableCell>Battery</TableCell>
        <TableCell>Signal Strength</TableCell>
        <TableCell>CAN Bus Status</TableCell>
      </TableRow>
    );
  };

  const pollRows = (polls, numberToShow = 12) => {
    const yearFormat = isMedium ? 'DD/MM/YY ' : 'DD/MM/YYYY, ';

    return polls.slice(0, numberToShow).map((poll) => {
      if (!poll || !poll.position) {
        return null;
      }
      const time = moment(poll.time);
      let [lon, lat] = poll.position.coordinates;
      lon = parseFloat(lon).toFixed(6);
      lat = parseFloat(lat).toFixed(6);
      return (
        <TableRow key={poll.identifier}>
          <TableCell>
            {convertSoftwareId(poll.deviceProperties.softwareId)}
          </TableCell>
          <TableCell>{time.format(`${yearFormat}HH:mm:ss`)}</TableCell>
          {!hideLonLat && (
            <>
              <TableCell>{isXSmall ? breakValAt(lat, 5) : lat}</TableCell>
              <TableCell>{isXSmall ? breakValAt(lon, 5) : lon}</TableCell>
            </>
          )}
          <TableCell>
            <OnOffIcon
              style={{ margin: '0 auto' }}
              on={poll.deviceProperties.isValidated}
              onTooltip="valid"
              offTooltip="invalid"
            />
          </TableCell>
          <TableCell>
            <OnOffIcon style={{ margin: '0 auto' }} on={poll.ignitionOn} />
          </TableCell>
          <TableCell>{poll.deviceProperties.batteryVoltage}V</TableCell>
          <TableCell>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: 20,
              }}
            >
              {signalStrengthIcon(poll.deviceProperties.deviceSignalStrength)}
            </div>
          </TableCell>
          <TableCell
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 64,
            }}
          >
            {(isXSmall ? mapCanBusStatusAbbreviated : mapCanBusStatus)(
              poll.deviceProperties.canBusStatus
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Table>
      <TableHead>{pollLabels()}</TableHead>
      <TableBody>{pollRows(polls, pollsToShow)}</TableBody>
    </Table>
  );
}

import {
  IconButton,
  makeStyles,
  Paper,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  FETCH_ON_BOARD_DIAGNOSTICS,
  FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED,
  UPDATE_ON_BOARD_DIAGNOSTICS_QUERY,
  UPDATE_ON_BOARD_DIAGNOSTICS_FILTER,
} from '../../actions';
import { downloadCSV } from '../../apis/utilities';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  textField: {
    margin: theme.spacing(1),
    width: 200,
  },
  popoverSheet: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  parameters: {
    width: 256,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Type', key: 'vehicle.type', type: 'text', filter: true },
      ]
    : [
        {
          label: 'Registration',
          key: 'vehicle.registrationNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Role', key: 'vehicle.role', type: 'text', filter: true },
        { label: 'Type', key: 'vehicle.type', type: 'text', filter: true },
      ]),
  { label: 'Time', key: 'time', type: 'date', filter: false },
  { label: 'Class', key: 'class', type: 'text', filter: true },
  { label: 'Code', key: 'code', type: 'text', filter: true },
  { label: 'Confirmed', key: 'isConfirmed', type: 'text', filter: true },
  { label: 'Area', key: 'area', type: 'text', filter: true },
  { label: 'Description', key: 'description', type: 'text', filter: true },
];

export default function OnBoardDiagnostics() {
  const dispatch = useDispatch();
  const diagnostics = useSelector(
    (state) => state.events.onBoardDiagnostics.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.onBoardDiagnostics.isLoading
  );
  const error = useSelector((state) => state.events.onBoardDiagnostics.error);
  const filter = useSelector(
    (state) => state.events.onBoardDiagnostics.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.onBoardDiagnostics.query,
    _.isEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);
  function handleFetch(event, query) {
    dispatch({
      type: FETCH_ON_BOARD_DIAGNOSTICS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_ON_BOARD_DIAGNOSTICS_CANCELLED,
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_ON_BOARD_DIAGNOSTICS_QUERY,
      payload: query,
    });
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_ON_BOARD_DIAGNOSTICS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'On Board Diagnostics.csv';
    const data = filteredDiagnostics.map((diagnostics) => ({
      ...diagnostics,
      'vehicle.fleetNumber': diagnostics.vehicle.fleetNumber,
      'vehicle.type': diagnostics.vehicle.type,
      'vehicle.registrationNumber': diagnostics.vehicle.registrationNumber,
      'vehicle.role': diagnostics.vehicle.role,
      time: moment(diagnostics.time).format('YYYY-MM-DD HH:mm:ss'),
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredDiagnostics = filterLocally(filter, diagnostics);

  return (
    <Container title="On-Board Diagnostics">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        vehicle
        pointEvent
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | On-Board Diagnostics</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            On-Board Diagnostics
          </Typography>
          <FilterPicker
            headers={headers}
            data={diagnostics}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredDiagnostics.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
          <Popover
            id="filter-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          ></Popover>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredDiagnostics}
            headers={headers}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredDiagnostics.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

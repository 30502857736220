import React from 'react';
import moment from 'moment';
import LiveListItem from './LiveListItem';

const { useReducedResourceInformation } = window.config;

function EventLiveListItem(props) {
  return (
    <LiveListItem
      type="events"
      primaryPath={
        useReducedResourceInformation
          ? 'vehicle.fleetNumber'
          : 'vehicle.registrationNumber'
      }
      secondaryComponent={
        <div>{moment(props.item.time).format('DD/MM/YYYY hh:mm')}</div>
      }
      {...props}
    />
  );
}

export default EventLiveListItem;

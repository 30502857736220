import {
  Paper,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSnackbar } from '../Snackbar';
import {
  FETCH_SPEED_INFRACTIONS,
  FETCH_SPEED_INFRACTIONS_CANCELLED,
  UPDATE_SPEED_INFRACTIONS_FILTER,
} from '../../actions';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import { downloadCSV } from '../../apis/utilities';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Staff ID', key: 'driverCode', filter: true },
        { label: 'Fleet Number', key: 'fleetNumber', filter: true },
        { label: 'Vehicle Type', key: 'vehicleType', filter: true },
        { label: 'Home Location', key: 'homeStation', filter: true },
      ]
    : [
        { label: 'Driver Name', key: 'driverName', filter: true },
        { label: 'Collar Number', key: 'collarNumber', filter: true },
        { label: 'Driver Role', key: 'personRole', filter: true },
        { label: 'Registration', key: 'registrationNumber', filter: true },
        { label: 'Fleet Number', key: 'fleetNumber', filter: true },
        { label: 'Vehicle Role', key: 'role', filter: true },
        { label: 'Home Station', key: 'homeStation', filter: true },
      ]),
  { label: 'Start Time', key: 'startTime', filter: false },
  { label: 'End Time', key: 'endTime', filter: false },
  { label: 'Duration (minutes)', key: 'durationMinutes', filter: false },
  { label: 'Distance (miles)', key: 'distanceMiles', filter: false },
  { label: 'Maximum Speed (mph)', key: 'maxSpeedMilesPerHour', filter: false },
  {
    label: 'Emergency Equipment Used',
    key: 'emergencyEquipmentUsed',
    filter: true,
  },
  { label: 'Infraction Count', key: 'speedInfractionCount', filter: false },
  {
    label: 'Infraction Duration (minutes)',
    key: 'speedInfractionDurationMinutes',
    filter: false,
  },
  { label: 'Speed Infractions', key: 'speedInfractions', filter: false },
];

function TripReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/trips/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function SpeedInfractionReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/speedInfractions/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function SpeedLimits({ entry }) {
  const headers = [
    {
      label: 'Speed Limit (mph)',
      key: 'limitMilesPerHour',
      type: 'text',
    },
    ...entry.speedLimitBreakdowns.rules.map((name) => ({
      label: `${name} (minutes)`,
      key: ['ruleDurationMinutes', name],
      type: 'number',
    })),
    {
      label: 'Maximum Speed (mph)',
      key: 'maxSpeedMilesPerHour',
      type: 'number',
    },
    {
      label: 'Maximum Excess (mph)',
      key: 'excessMilesPerHour',
      type: 'number',
    },
  ];

  return (
    <Table
      data={entry.speedLimitBreakdowns.rows}
      headers={headers}
      rowsPerPage={entry.speedLimitBreakdowns.rows.length}
      page={0}
      footer={[
        { key: 'limit', type: 'text', value: 'Totals' },
        ...entry.speedLimitBreakdowns.rules.map((name) => ({
          key: ['ruleDurationMinutes', name],
          type: 'number',
          value: _.round(
            entry.speedLimitBreakdowns.rows
              .map((breakdown) => breakdown.ruleDurationMinutes[name] || 0)
              .reduce((a, b) => a + b, 0) || 0,
            2
          ),
        })),
        {
          key: 'maxSpeedMilesPerHour',
          type: 'number',
          value: _.round(
            Math.max(
              ...entry.speedLimitBreakdowns.rows.map(
                (breakdown) => breakdown.maxSpeedMilesPerHour
              )
            ),
            2
          ),
        },
        {
          label: 'Maximum Excess (mph)',
          key: 'excessMilesPerHour',
          type: 'number',
          value: _.round(
            Math.max(
              ...entry.speedLimitBreakdowns.rows.map(
                (breakdown) => breakdown.excessMilesPerHour
              )
            ),
            2
          ),
        },
      ]}
      keyName="limitMilesPerHour"
    />
  );
}

const infractionHeaders = [
  {
    label: '',
    key: 'expand',
    type: 'expand',
    component: SpeedLimits,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
  },
  {
    label: 'Maximum Speed (mph)',
    key: 'maxSpeedMilesPerHour',
    type: 'number',
  },
  {
    label: 'Maximum Excess (mph)',
    key: 'maxExcessMilesPerHour',
    type: 'number',
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    component: SpeedInfractionReplayLink,
  },
];

function Infractions({ entry }) {
  return (
    <Table
      data={entry.speedInfractions}
      headers={infractionHeaders}
      rowsPerPage={entry.speedInfractions.length}
      page={0}
      footer={[
        { key: 'expand', type: 'text', value: '' },
        { key: 'title', type: 'text', value: 'Totals' },
        {
          key: 'count',
          type: 'text',
          value: `${entry.speedInfractions.length} ${
            entry.speedInfractions.length === 1
              ? 'speed infraction'
              : 'speed infractions'
          }`,
        },
        {
          key: 'duration',
          type: 'number',
          value: _.round(
            entry.speedInfractions
              .map((speedInfraction) => speedInfraction.durationMinutes)
              .reduce((a, b) => a + b, 0) || 0,
            2
          ),
        },
        {
          key: 'distance',
          type: 'number',
          value: _.round(
            entry.speedInfractions
              .map((speedInfraction) => speedInfraction.distanceMiles)
              .reduce((a, b) => a + b, 0) || 0.0,
            2
          ),
        },
        {
          key: 'maxSpeed',
          type: 'number',
          value: _.round(
            Math.max(
              ...entry.speedInfractions
                .map((speedInfraction) => speedInfraction.maxSpeedMilesPerHour)
                .concat(0)
            ),
            2
          ),
        },
        {
          key: 'maxExcess',
          type: 'number',
          value: _.round(
            Math.max(
              ...entry.speedInfractions
                .map((speedInfraction) => speedInfraction.maxExcessMilesPerHour)
                .concat(0)
            ),
            2
          ),
        },
      ]}
      keyName="identifier"
    />
  );
}

export default function SpeedInfractions() {
  const dispatch = useDispatch();
  const trips = useSelector(
    (state) => state.events.tripsWithSpeedInfractions.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.tripsWithSpeedInfractions.isLoading
  );
  const error = useSelector(
    (state) => state.events.tripsWithSpeedInfractions.error
  );
  const filter = useSelector(
    (state) => state.events.tripsWithSpeedInfractions.filter,
    _.isEqual
  );
  const classes = useStyles();
  const snackbar = useSnackbar();

  const areas = Array.from(
    new Set(trips.flatMap((record) => Object.keys(record.areas)))
  );

  const tableHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: Infractions,
      filter: false,
    },
    ...(useReducedResourceInformation
      ? [
          {
            label: 'Staff ID',
            key: 'driverCode',
            type: 'text',
            filter: true,
          },
          {
            label: 'Fleet Number',
            key: 'fleetNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Vehicle Type',
            key: 'type',
            type: 'text',
            filter: true,
          },
          {
            label: 'Home Location',
            key: 'homeStation',
            type: 'text',
            filter: true,
          },
        ]
      : [
          {
            label: 'Driver Name',
            key: 'driverName',
            type: 'text',
            filter: true,
          },
          {
            label: 'Collar Number',
            key: 'collarNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Driver Role',
            key: 'personRole',
            type: 'text',
            filter: true,
          },
          {
            label: 'Registration',
            key: 'registrationNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Fleet Number',
            key: 'fleetNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Vehicle Role',
            key: 'role',
            type: 'text',
            filter: true,
          },
          {
            label: 'Home Station',
            key: 'homeStation',
            type: 'text',
            filter: true,
          },
        ]),
    ...areas.map((area) => ({
      label: _.startCase(area),
      key: ['areas', area],
      type: 'text',
      filter: true,
    })),
    {
      label: 'Classification',
      key: 'classification',
      type: 'text',
      filter: true,
    },
    {
      label: 'Start Time',
      key: 'startTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'End Time',
      key: 'endTime',
      type: 'date',
      filter: false,
    },
    {
      label: 'Duration (minutes)',
      key: 'durationMinutes',
      type: 'number',
      filter: false,
    },
    {
      label: 'Distance (miles)',
      key: 'distanceMiles',
      type: 'number',
      filter: false,
    },
    {
      label: 'Maximum Speed (mph)',
      key: 'maxSpeedMilesPerHour',
      type: 'number',
      filter: false,
    },
    {
      label: 'Maximum Excess (mph)',
      key: 'maxExcessMilesPerHour',
      type: 'number',
      filter: false,
    },
    {
      label: 'Warning Equipment Used',
      key: 'emergencyEquipmentUsed',
      type: 'boolean',
      filter: true,
    },
    {
      label: 'Infraction Count',
      key: 'speedInfractionCount',
      type: 'number',
      filter: false,
    },
    {
      label: 'Infraction Duration (minutes)',
      key: 'speedInfractionDurationMinutes',
      type: 'number',
      filter: false,
    },
    {
      label: '',
      key: 'replay',
      type: 'component',
      component: TripReplayLink,
      filter: false,
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_SPEED_INFRACTIONS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_SPEED_INFRACTIONS_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_SPEED_INFRACTIONS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const allHeaders = headers
      .slice(0, 7)
      .concat(
        areas.map((key) => ({
          label: _.startCase(key),
          key,
        }))
      )
      .concat(headers.slice(7));
    const filename = 'Speed Infractions.csv';
    const data = filteredTrips.map((trip) => ({
      driverName: trip.driverName,
      driverCode: trip.driverCode,
      collarNumber: trip.collarNumber,
      personRole: trip.personRole,
      registrationNumber: trip.registrationNumber,
      fleetNumber: trip.fleetNumber,
      role: trip.role,
      vehicleType: trip.type,
      homeStation: trip.homeStation,
      ...trip.areas,
      classification: trip.classification,
      startTime: moment(trip.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(trip.endTime).format('YYYY-MM-DD HH:mm:ss'),
      emergencyEquipmentUsed: trip.emergencyEquipmentUsed,
      durationMinutes: _.round(trip.durationMinutes || 0.0, 2),
      distanceMiles: _.round(trip.distanceMiles || 0.0, 2),
      maxSpeedMilesPerHour: _.round(trip.maxSpeedMilesPerHour || 0.0, 2),
      maxExcessMilesPerHour: _.round(trip.maxExcessMilesPerHour || 0.0, 2),
      speedInfractionCount: trip.speedInfractions.length,
      speedInfractionDurationMinutes: _.round(
        trip.speedInfractionDurationMinutes || 0.0,
        2
      ),
      speedInfractions: trip.speedInfractions
        .map(
          (speedInfraction) =>
            `Start Time:${moment(speedInfraction.startTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )},` +
            `End Time:${moment(speedInfraction.endTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )},` +
            `Duration (minutes):${_.round(
              speedInfraction.durationMinutes,
              2
            )},` +
            `Distance (miles):${_.round(speedInfraction.distanceMiles, 2)},` +
            `Maximum Speed (mph):${_.round(
              speedInfraction.maxSpeedMilesPerHour,
              2
            )},` +
            `Breakdown: [${speedInfraction.speedLimitBreakdowns.rows.map(
              (breakdown) =>
                `Speed Limit (mph):${breakdown.limitMilesPerHour},` +
                `Max Speed (mph):${breakdown.maxSpeedMilesPerHour.toFixed(
                  2
                )},` +
                Object.entries(breakdown.ruleDurationMinutes)
                  .map((item) => `${item[0]} (minutes):${item[1]}`)
                  .join()
            )}]`
        )
        .join('\n'),
    }));

    downloadCSV(data, filename, allHeaders);
  }

  const filteredTrips = filterLocally(filter, trips);

  return (
    <Container title="Speed Infractions">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
        vehicle
        driver
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Speed Infractions</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Speed Infractions
          </Typography>
          <FilterPicker
            headers={tableHeaders}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            title="Download data"
            disabled={filteredTrips.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredTrips}
            headers={tableHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

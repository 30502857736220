import {
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowUpward as ArrowUpwardIcon,
  FilterList as FilterListIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_LIVE_ADVANCED_FILTERS,
  UPDATE_LIVE_LAYER_VISIBILITIES,
  UPDATE_LIVE_SEARCHTEXTS,
  UPDATE_LIVE_SORTS,
} from '../../../actions';
import { liveFilters, liveFiltersSections } from '../../../data/constants';
import { filterList } from '../../../data/utilities';
import { usePrevious } from '../../../hooks';
import { /*SelectMultiple,*/ SearchBox } from '../../controls';
import { FilterField } from '../../fields';
import { pluralToSingularTypeMap } from '../constants';
import FilterSummary from '../FilterSummary';
import { filterFullyDefined } from '../utilities';

const { useReducedResourceInformation } = window.config;
const defaultSortOptionByType = {
  vehicles: useReducedResourceInformation
    ? { label: 'Fleet Number', value: 'fleetNumber' }
    : { label: 'Registration Number', value: 'registrationNumber' },
  locations: { label: 'Name', value: 'name' },
  people: useReducedResourceInformation
    ? { label: 'Code', value: 'code' }
    : { label: 'Name', value: 'name' },
  incidents: { label: 'Type', value: 'type.name' },
  plans: { label: 'Title', value: 'title' },
  objectives: { label: 'Title', value: 'title' },
  tags: { label: 'Name', value: 'id' },
  callSigns: { label: 'Name', value: 'id' },
};

const DEFAULT_SORT_OPTION = { label: '<default>', value: 'id' };
const NO_SUGGESTION_TYPES = ['miles', 'date', 'datetime', 'number', 'duration'];

const {
  liveOptions: {
    excludedSorts = {},
    includedSorts = {},
    excludedFilters = {},
    includedFilters = {},
    overriddenFilterLabels = {},
    vehiclesHaveCallsigns = true,
  },
} = window.config;

// dictionaries will be faster for constant lookups
function toLookup(dictOfArrays) {
  let result = {};
  Object.keys(dictOfArrays).forEach((key) => {
    result[key] = {};
    dictOfArrays[key].forEach((element) => (result[key][element] = true));
  });

  return result;
}

const excludedFiltersLookup = toLookup(excludedFilters);
const includedFiltersLookup = toLookup(includedFilters);
const excludedSortsLookup = toLookup(excludedSorts);
const includedSortsLookup = toLookup(includedSorts);

const allowed = (excluded, included) => (type, key) => {
  // it's allowed if not excluded and (there's no included list or it's included)
  return !excluded[type]?.[key] && (!included[type] || included[type]?.[key]);
};

const allowedSort = allowed(excludedSortsLookup, includedSortsLookup);
const allowedFilter = allowed(excludedFiltersLookup, includedFiltersLookup);

const useStyles = makeStyles((theme) => ({
  listSearch: {
    width: '100%',
  },
  selectField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  filtersContainer: {
    // marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1, 0.5, 1),
    overflowY: 'auto',
  },
  searchAndIcons: {
    display: 'flex',
    height: 52,
    alignItems: 'center',
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  icons: {
    height: 48,
    display: 'flex',
    paddingRight: theme.spacing(0.5),
  },
  ieAdornment: {
    display: 'block',
    height: 22,
    marginRight: theme.spacing(1),
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  searchAndFiltersContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  collapseContainer: {
    overflow: 'auto',
  },
  sectionTitle: {
    marginTop: theme.spacing(1),
  },
}));

const advancedFiltersToFormData = _.memoize(filtersToFormData);
function filtersToFormData(advancedFilters) {
  // need to separate out filters in general vehicle to the sectioned
  // ones in the form e.g. vehicles [{field: 'driver.collarNumber', value: '123'}]
  // may need to go to vehicles,driver

  let initialValues = {};
  Object.keys(liveFilters).forEach((key) => {
    const filterConfig = liveFilters[key];
    if (liveFiltersSections[key]) {
      const sections = liveFiltersSections[key];
      initialValues[key] = [];

      Object.keys(sections).forEach((sectionKey) => {
        // find the existing advancedFilters that would come under
        // this section by matching the field (e.g. if the section
        // filters had ['regNo', 'driverSkillsArray'] there might
        // be {field: 'regNo', condition...}
        // or {field: 'driverSkillsArray', condition...} in the
        // advancedFilters) so use these as the initial values
        const filtersInSection =
          advancedFilters[key]?.filter((chosenFilter) =>
            sections[sectionKey].filters.some(
              (sectionFilterName) =>
                !!chosenFilter.field &&
                (sectionFilterName === chosenFilter.field ||
                  filterConfig[sectionFilterName]?.value === chosenFilter.field)
            )
          ) || [];

        // are any dynamic ones in there too?
        // we'll know if areas is dynamic as it'll have isDictionary set
        // and we know which of the advancedFilters will be dynamic as they
        // will begin with the areas field/path i.e. reducedAreas.whatever
        // this'll work even when the data haven't been fully loaded and the
        // dynamic filters aren't available yet
        sections[sectionKey].filters.forEach((filterKey) => {
          if (
            filterConfig[filterKey] &&
            filterConfig[filterKey].isDictionary &&
            advancedFilters[key]
          ) {
            filtersInSection.push(
              ...advancedFilters[key]?.filter((f) =>
                f.field?.startsWith(filterConfig[filterKey].value)
              )
            );
          }
        });

        initialValues[[key, sectionKey].join()] = filtersInSection;
      });
    } else {
      initialValues[key] = advancedFilters[key];
    }
  });

  return initialValues;
}

function valueToOption(value, filterDefinition) {
  return {
    // use a mapped option or a predefined option matching value or Start Case
    label:
      filterDefinition?.mapOption?.(value) ||
      filterDefinition?.options?.find((o) => o.value === value)?.label ||
      value,
    value,
  };
}

// addUnavailableProps will convert
// {label: 'Main BCU', value: 'main bcu'}
// to
// {label: 'Main BCU', value: 'main bcu',
//  unavailable: true, className: 'unavailable'}
// if it's not possible
function addUnavailableProps(options, possibleDict) {
  return options.map((o) => {
    const unavailable = !!possibleDict && !possibleDict[o.value];

    return {
      ...o,
      unavailable,
      className: unavailable ? 'unavailable' : undefined,
    };
  });
}

// arrayToOptions will convert ['main bcu', 'other bcu'] to
// [
//   {label: 'Main BCU', value: 'main bcu'},
//   {label: 'Other BCU', value: 'other bcu'}
// ]
function dataValuesToOptions(
  dataValues = [],
  possibleDict = {},
  filterSelections,
  filterDefinition
) {
  // there may be previously selected values that are no longer in the data
  // e.g. select "STALEREG" then hide stale items. It won't be in the the array
  // in order to work out if possible or not so it will appear possible by default
  // we need to add these so we can correctly mark them as impossible
  const selectedValues =
    filterDefinition &&
    filterSelections?.find((s) => s.field === filterDefinition.value)?.value;

  if (selectedValues) {
    // add the selected values & make sure the end array is unique
    dataValues = [...new Set([...dataValues, ...selectedValues])];
  }

  return addUnavailableProps(
    dataValues.map(valueToOption, filterDefinition),
    possibleDict
  );
}

function FilterControl({
  className,
  type,
  showFilter,
  filters,
  fullList,
  onFilterToggle,
  filteredListLength,
}) {
  const classes = useStyles();

  const sorts = useSelector((state) => state.live.sorts);
  const sort =
    sorts[type] || defaultSortOptionByType[type] || DEFAULT_SORT_OPTION;

  const searchTexts = useSelector((state) => state.live.searchTexts);
  const searchText = searchTexts[type] || '';

  // this is now searchTextLOCAL because props is now destructured in function
  // and this component needs a local search text to change in response to user
  // input and a debounced external one when it is supposed to trigger filtering
  const [searchTextLocal, setSearchTextLocal] = useState(searchText || '');

  // 5 setXXX will result in 5 renders, condense to one to improve perf
  const [settings, setSettings] = useState({
    suggestions: {},
    sortBy: sorts[type] || defaultSortOptionByType[type] || DEFAULT_SORT_OPTION,
    sortDesc: sorts[type]?.desc,
    sortOptions: [defaultSortOptionByType[type] || DEFAULT_SORT_OPTION],
  });

  const currListLength = (fullList || []).length;
  const prevListLength = usePrevious(currListLength);
  const prevType = usePrevious(type);
  const filteredInIds = useSelector(
    (state) => state.live.filteredInIdsByType[type]
  );
  const prevFilteredInIds = usePrevious(filteredInIds);
  const advancedFilters = useSelector((state) => state.live.advancedFilters);
  const prevAdvancedFilters = usePrevious(advancedFilters);

  const selectedLiveViewKey = useSelector(
    (state) => state.live.selectedLiveViewKey
  );

  const layerVisibilities = useSelector(
    (state) => state.live.layerVisibilities
  );
  const dispatch = useDispatch();

  // ...(useReducedResourceInformation
  //   ? [{ label: 'Staff ID', value: 'code' }]
  //   : [
  //       { label: 'Name', value: 'name' },
  //       { label: 'Collar Number', value: 'collarNumber' },
  //     ]),
  // ];

  function needsSuggestions(type) {
    return !NO_SUGGESTION_TYPES.some((t) => t === type);
  }

  function updateSorts(change) {
    dispatch({
      type: UPDATE_LIVE_SORTS,
      payload: {
        ...sorts,
        [type]: {
          ...(sorts[type] || {}),
          ...change,
        },
      },
    });
  }

  function handleSortByChange(event) {
    setSettings({ ...settings, sortBy: event.target.value });
    updateSorts({ path: event.target.value });
  }

  function handleSortToggle() {
    setSettings({ ...settings, sortDesc: !settings.sortDesc });
    updateSorts({ desc: !settings.sortDesc });
  }

  // it seems I have to do this every time the list changes, surely
  // there's some way to improve this frequent expensive comp: TODO JL
  useEffect(() => {
    // it is possible that all the filters would need to be redone each
    // time the resource list changes at all, however for performance we
    // will try to limit this to a few triggers, an update is needed if:
    const updateNeeded =
      // - there are more or less items
      currListLength !== prevListLength ||
      // - the type changes (all options need updating)
      prevType !== type ||
      // - a resource changes being filtered in/out (changes possible options)
      prevFilteredInIds !== filteredInIds ||
      // - a selection changes (changes possible options)
      prevAdvancedFilters !== advancedFilters;

    // console.log('Full  list changed');
    let allOptionsByFilter = updateNeeded ? {} : settings.suggestions;
    let sortOptions = updateNeeded
      ? [defaultSortOptionByType[type] || DEFAULT_SORT_OPTION]
      : settings.sortOptions;
    let filterDefinitions = liveFilters[type];

    if (!filterDefinitions) {
      return;
    }

    // only use the categorical multiselect filters for checking possible options
    // i.e. don't get all the options for mileage for example
    let filterSelections = (advancedFilters[type] || []).filter(({ field }) => {
      if (!field) {
        return false;
      }

      const rootPath = field.split('.')[0];
      const definitionKey = Object.keys(filterDefinitions).find(
        (definitionKey) =>
          filterDefinitions[definitionKey].value === rootPath ||
          filterDefinitions[definitionKey].value === field
      );

      return !!definitionKey;
    });

    // to only allow possible options we need the currently filtered list
    const completeFilteredList = filterList(
      fullList,
      null,
      null,
      filterSelections
    );

    // if this filter has some selections it shouldn't limit its own options
    // (e.g. selecting Ford shouldn't limit Make to just Ford) so if needed,
    // get the possible options as if that particular filter wasn't set at all
    // and any other filter selections are unchanged
    function refilterExcluding(fieldName) {
      // if there's no selections for this filter anyways return the full list
      if (!filterSelections.some((f) => f.field === fieldName)) {
        return completeFilteredList;
      }

      const filterSelectionsExcludingThisOne = filterSelections.filter(
        (f) => f.field !== fieldName
      );
      return filterList(fullList, null, null, filterSelectionsExcludingThisOne);
    }

    function addSortOption(filterDefinition, filterKey, label, value) {
      const shouldInclude =
        !filterDefinition.excludeSort && allowedSort(type, filterKey);

      if (shouldInclude) {
        // default to the filter name and value
        label = (!!label && label) || filterDefinition.name;
        value = value || filterDefinition.value;

        // all sort options are in the one dropdown and there may be a prefix so
        // vehicle role and driver role don't have the same label e.g. 'Driver Role'
        label = [
          filterDefinition.prefix,
          overriddenFilterLabels[label] || _.startCase(label),
        ]
          .filter(Boolean)
          .join(' ');

        // if it's not added already, add it...
        if (!sortOptions.find((s) => s.value === value)) {
          sortOptions.push({
            label,
            value,
          });
        }
      }
    }

    // go through each filter definition and get all the options and the
    // possible options for it. Some may be dynamic like areas or arrays
    // like skills
    let settingsChanged = updateNeeded;
    Object.keys(filterDefinitions).forEach((filterKey) => {
      const filterDefinition = filterDefinitions[filterKey];

      // if an update is needed or this filter changes often, get options
      if (updateNeeded || filterDefinition.changeful) {
        function keysToOptions(obj = {}, possibleDict) {
          return dataValuesToOptions(
            Object.keys(obj),
            possibleDict,
            filterSelections,
            filterDefinition
          );
        }

        // filters like speed don't need suggestions so just add a sort option
        if (!needsSuggestions(filterDefinition.type)) {
          addSortOption(filterDefinition, filterKey);
          return; // skip the rest
        }

        // some filters are dynamic & not set by the config e.g. areas. each item
        // in the list might have an array of areas like
        //   areas: [{type: 'bcu', name: 'Main BCU'}, {type: 'lpu', name: 'LPU A'}]
        // !!! which I expect to be transformed into something like
        //   reducedAreas: {bcu: 'Main BCU', lpu: 'LPU A'}
        // for the above bcu and lpu are new separate dynamic filters with options
        // assembled from all items. We should end with suggestions like
        // 'reducedAreas.bcu': [{label: 'Main BCU', value: 'Main BCU', {...}]
        if (filterDefinition.isDictionary) {
          // go through the full list of data and get all the types and their options
          const dynamicFiltersAllOptions = fullList.reduce(
            (dynamicFilters, item) => {
              // get the dictionary e.g.: _.get(someVehicle, 'reducedAreas')
              const dict = _.get(item, filterDefinition.value);
              if (dict) {
                // for each of ['bcu', 'lpu', ...]
                Object.keys(dict)
                  .filter((key) =>
                    allowedFilter(type, filterDefinition.value + '.' + key)
                  )
                  .forEach((dynamicFilterKey) => {
                    // if the type isn't there already, add a blank one
                    // dynamicFilterKey here will be bcu or lpu etc.
                    dynamicFilters[dynamicFilterKey] = dynamicFilters[
                      dynamicFilterKey
                    ] || {
                      path: filterDefinition.value + '.' + dynamicFilterKey,
                      options: {},
                    };

                    // add a dictionary item for the name i.e. 'Main BCU' or 'LPU A'
                    const option = dict[dynamicFilterKey].trim();
                    dynamicFilters[dynamicFilterKey].options[option] = true;
                  });
              }

              return dynamicFilters;
            },
            {}
          );

          // dynamicOptions now looks something like this:
          // these are the isDictionary dynamic type
          // dynamicOptions = {
          //    bcu: {
          //      path: 'reducedAreas.bcu',
          //      options: {'Main BCU': true, 'Other BCU': true}
          //    },
          //    lpu: {path: 'reducedAreas.lpu', options: {'LPU A': true}}
          // };

          // to get the possible options we need to go through each and refilter in
          // case that dynamic filter has some options selected e.g. if 'Main BCU'
          // is selected, it shouldn't prohibit 'Other BCU' from being selected
          let dynamicFiltersPossibleOptions = {};
          Object.keys(dynamicFiltersAllOptions).forEach((dynamicFilterKey) => {
            const dynamicFilter = dynamicFiltersAllOptions[dynamicFilterKey];
            const field = dynamicFilter.path;
            let filteredList = refilterExcluding(field);

            // TODO JL refactor all this stuff, lots of similarities with the alloptions
            // one, and the names of keys and dicts are awful!
            const possibleOptionsDict = filteredList.reduce((options, item) => {
              const dict = _.get(item, filterDefinition.value);
              if (dict) {
                const option = dict[dynamicFilterKey]?.trim();
                if (option) {
                  options[option] = true;
                }
              }

              return options;
            }, {});

            dynamicFiltersPossibleOptions[dynamicFilterKey] = {
              path: dynamicFilter.path,
              options: possibleOptionsDict,
            };
          });

          // for each dynamic filter (bcu, lpu), convert the keys to an option list,
          // and add it as a sort option
          Object.keys(dynamicFiltersAllOptions).forEach((dynamicFilterKey) => {
            const dynamicFilter = dynamicFiltersAllOptions[dynamicFilterKey];

            // change the dynamicFilter options dictionary to a list of options
            // before adding to the suggestions e.g.
            //    bcu: {
            //      path: 'reducedAreas.bcu',
            //      options: {'Main BCU': true, 'Other BCU': true}
            //    },
            // becomes
            //  {'reducedAreas.bcu':
            //    [{label: 'Main BCU', value: 'Main BCU', {label: 'Other BCU', ...}]
            //  }
            const options = keysToOptions(
              dynamicFiltersAllOptions[dynamicFilterKey].options,
              dynamicFiltersPossibleOptions[dynamicFilterKey].options
            );

            settingsChanged =
              settingsChanged ||
              !_.isEqual(options, allOptionsByFilter[dynamicFilter.path]);

            allOptionsByFilter[dynamicFilter.path] = options;

            addSortOption(
              filterDefinition,
              filterKey,
              dynamicFilterKey,
              dynamicFilter.path
            );
          });
        } else if (filterDefinition.isArray) {
          // go through the full list of data and get all the types and options
          function filterOptionsFromArrays(list) {
            return list.reduce((options, item) => {
              const array = _.get(item, filterDefinition.value);
              if (array && array.length > 0) {
                array.forEach((option) => {
                  options = options || {};
                  options[option] = true;
                });
              }

              return options;
            }, {});
          }

          const allOptionsDict = filterOptionsFromArrays(fullList);

          let filteredList = refilterExcluding(filterDefinition.value);
          const possibleOptionsDict = filterOptionsFromArrays(filteredList);

          // dynamicOptions now looks something like this:
          // dynamicOptions = {
          //   '4X4 driving on road': 'driverSkillsArray',
          //   '4X4 driving off road': 'driverSkillsArray'
          // };

          // convert the keys to suggestions
          const options = keysToOptions(allOptionsDict, possibleOptionsDict);

          settingsChanged =
            settingsChanged ||
            !_.isEqual(options, allOptionsByFilter[filterKey]);

          allOptionsByFilter[filterKey] = options;

          // none of the array types are sortable
        } else {
          // for each item in the list, get the filterDefinition.value e.g. registration
          // filter out any blank ones and only get the unique ones
          function optionsFromList(list, possible) {
            return dataValuesToOptions(
              _.chain(
                list.map((item) => _.get(item, filterDefinition.value)).sort()
              )
                .filter((value) => typeof value !== 'undefined' && value !== '')
                .uniq()
                .value(),
              possible,
              filterSelections,
              filterDefinition
            );
          }

          // which values are possible with the current filters excluding this one?
          const possible = refilterExcluding(filterDefinition.value).reduce(
            (dict, item) => {
              let option = _.get(item, filterDefinition.value);
              dict[option] = true;
              return dict;
            },
            {}
          );

          // for standard (not dictionary or array) filters they could have
          // predefined options so use these instead of trawling the data
          // make sure to mark any unavailable ones as such
          const predefinedOptions =
            filterDefinition.options &&
            addUnavailableProps(filterDefinition.options, possible);

          const options =
            predefinedOptions || optionsFromList(fullList, possible);

          settingsChanged =
            settingsChanged ||
            !_.isEqual(options, allOptionsByFilter[filterKey]);

          allOptionsByFilter[filterKey] = options;

          addSortOption(filterDefinition, filterKey);
        }
      } // end if updateNeeded || filterDefinition.changeful
    }); // end forEach filterDefinition

    if (settingsChanged) {
      // console.log("filters etc. changed!!");

      setSettings({
        suggestions: allOptionsByFilter,
        sortOptions: _.sortBy(sortOptions, 'label'),
        sortBy: sort.path,
        sortDesc: sort.desc,
      });
    }
  }, [
    fullList,
    type,
    prevType,
    currListLength,
    prevListLength,
    sort,
    advancedFilters,
    prevAdvancedFilters,
    filteredInIds,
    prevFilteredInIds,
    settings,
  ]);

  // debounce search change, don't do a search on every char, only when finished typing
  // const debouncedSearch = _.debounce(() => {
  //   const newSearchTexts = { ...searchTexts, [type]: searchTextLocal };
  //   dispatch({
  //     type: UPDATE_LIVE_SEARCHTEXTS,
  //     payload: newSearchTexts,
  //   });
  // }, 300);
  function search(newSearchTexts) {
    dispatch({
      type: UPDATE_LIVE_SEARCHTEXTS,
      payload: newSearchTexts,
    });
  }

  const debouncedSearch = _.debounce(search, 300);

  function handleSearchChange(event) {
    const term = event.target.value;

    setSearchTextLocal(term);
    debouncedSearch({ ...searchTexts, [type]: term });
  }

  useEffect(() => {
    if (type !== prevType) {
      setSearchTextLocal(searchText);
    }
  }, [searchText, type, prevType]);

  function handleVisibilityToggle() {
    dispatch({
      type: UPDATE_LIVE_LAYER_VISIBILITIES,
      payload: {
        ...layerVisibilities,
        [type]: !(type in layerVisibilities ? layerVisibilities[type] : true),
      },
    });
  }

  const styles = {
    unavailable: {
      textDecoration: 'line-through',
    },
  };

  function filterToPossible(options, state) {
    let possible = [];
    let impossible = [];

    options.forEach((o) => {
      if (!o.unavailable) {
        possible.push(o);
      } else {
        impossible.push(o);
      }
    });

    options = _.orderBy(possible, 'label').concat(
      _.orderBy(impossible, 'label')
    );

    if (!!state.inputValue) {
      const match = state.inputValue.toLowerCase();
      options = options.filter(
        (o) => o.value.toLowerCase().indexOf(match) !== -1
      );
    }

    return options;
  }

  function getFilterFields(name, filtersDict, form) {
    let allFilters = {};
    Object.keys(filtersDict).forEach((key) => {
      const filter = filtersDict[key];
      const filterConfig = liveFilters[type][key];

      // if any of the filters are a dictionary type (e.g. areas) replace it
      // with the known filters
      if (filterConfig?.isDictionary) {
        // find all the keys of options that start with this filter's path
        // e.g. for areas, find all suggestions that start with 'reducedAreas'
        const dynamicFilterPaths = Object.keys(
          settings.suggestions
        ).filter((suggestionsKey) =>
          suggestionsKey.startsWith(filterConfig.value)
        );
        dynamicFilterPaths.forEach((dynamicFilterPath) => {
          // the key is used as the path to the property, i.e. field
          const suggestions = settings.suggestions[dynamicFilterPath];
          const dynamicFilterName = dynamicFilterPath.split('.').pop();

          allFilters[dynamicFilterPath] = {
            label:
              overriddenFilterLabels[dynamicFilterName] ||
              _.startCase(dynamicFilterName),
            type: 'multiselect',
            values: suggestions,
            filterOptions: filterToPossible,
            styles,
            onlyEqual: filterConfig?.onlyEqual,
            parse: filterConfig?.parse,
            format: filterConfig?.format
          };
        });
      } else {
        const suggestions = settings.suggestions[key];

        allFilters[filter.value] = {
          label: overriddenFilterLabels[filter.name] || filter.name,
          type: filter.type || 'multiselect',
          values: suggestions,
          filterOptions: filterToPossible,
          styles,
          unit: filter.unit,
          onlyEqual: filterConfig?.onlyEqual,
          parse: filterConfig?.parse,
          format: filterConfig?.format
      };
      }
    });

    // there may be previously selected values for dynamic data that no longer exist
    // e.g. { field: 'reducedAreas.bcu', value: ['STALEBCU'] } when hiding stale
    // add dummy filters so we can keep the value but mark it as impossible
    const selectedValues = form.getState()?.values?.[name] || [];
    selectedValues.forEach(({ field, value }) => {
      if (!!field && !!value && !allFilters[field]) {
        const subtype = field.split('.').pop(); // get bcu for reducedAreas.bcu

        allFilters[field] = {
          label: overriddenFilterLabels[subtype] || subtype,
          type: 'multiselect',
          values: dataValuesToOptions(value),
          filterOptions: filterToPossible,
          styles,
        };
      }
    });

    return (
      <FieldArray
        // label={key}
        name={name}
        filters={allFilters}
        component={FilterField}
        clearValue={form.mutators.clearValue}
      />
    );
  }

  function getFilterSections(type, form) {
    // does this type have named sections or not?
    if (liveFiltersSections[type]) {
      return Object.keys(liveFiltersSections[type]).map((sectionKey, index) => {
        const first = index === 0;
        const section = liveFiltersSections[type][sectionKey];
        const filtersForSection = Object.fromEntries(
          section.filters
            .map((key) =>
              liveFilters[type][key] ? [key, liveFilters[type][key]] : undefined
            )
            .filter(Boolean)
        );

        return (
          <React.Fragment key={sectionKey}>
            {Object.keys(filtersForSection).length > 0 && (
              <React.Fragment>
                {!first && <Divider className={classes.divider} />}
                <Typography
                  className={classes.sectionTitle}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {section.label}
                </Typography>
                {getFilterFields(
                  [type, sectionKey].join(),
                  filtersForSection,
                  form
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      });
    } else {
      // if no named section just generate all the filter fields in one
      return (
        <React.Fragment>
          <Typography
            className={classes.sectionTitle}
            variant="subtitle2"
            color="textSecondary"
          >
            {_.startCase(pluralToSingularTypeMap[type])}
          </Typography>
          {getFilterFields(type, liveFilters[type], form)}
        </React.Fragment>
      );
    }
  }

  function formDataToAdvancedFilters(data) {
    // filter sections have a key that looks like vehicle,driver
    // need to get all the filter values out of these and into
    // the general type e.g. vehicle,driver.name --> vehicle.name
    return Object.keys(data).reduce((filters, key) => {
      let typeKey = key.split(',')[0];
      filters[typeKey] = [...(filters[typeKey] || []), ...(data[key] || [])];

      return filters;
    }, {});
  }

  function removeFiltersWithNoValue(filters) {
    Object.keys(filters).forEach((type) => {
      filters[type] = filters[type].filter(filterFullyDefined);

      // more correct but if a view has an empty filter [] it won't match
      // and will always look modified when it is just applied *Some View
      // if (filters[type].length  === 0) {
      //   delete filters[type];
      // }
    });

    return filters;
  }

  return (
    <div className={className}>
      <div className={classes.searchAndFiltersContainer}>
        <FormControl className={classes.listSearch}>
          <div className={classes.searchAndIcons}>
            <SearchBox
              value={searchTextLocal}
              onChange={handleSearchChange}
              className={classes.searchBox}
              endAdornment={
                <InputAdornment position="end" className={classes.ieAdornment}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.count}
                  >
                    {filteredListLength === currListLength
                      ? `${currListLength}`
                      : `${filteredListLength}/${currListLength}`}
                  </Typography>
                </InputAdornment>
              }
            />
            <div className={classes.icons}>
              {filters && (
                <IconButton
                  aria-label="Toggle filter"
                  onClick={onFilterToggle}
                  title={showFilter ? 'Hide filter' : 'Show filter'}
                >
                  <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
                </IconButton>
              )}
              {window.config.liveOptions &&
                window.config.liveOptions.mapLayerToggleOnList && (
                  <IconButton
                    aria-label="Toggle map visibility"
                    onClick={handleVisibilityToggle}
                    title={
                      layerVisibilities[type] === false
                        ? 'Show layer'
                        : 'Hide layer'
                    }
                  >
                    {layerVisibilities[type] === false ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                )}
            </div>
          </div>
        </FormControl>
        <div className={classes.collapseContainer}>
          <Collapse in={showFilter} timeout="auto" unmountOnExit>
            <div className={classes.filtersContainer}>
              <div className={classes.sortTextField}>
                <TextField
                  select
                  fullWidth
                  label="Sort by"
                  value={settings.sortBy || ''}
                  onChange={handleSortByChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          title={settings.sortDesc ? 'Descending' : 'Ascending'}
                          className={clsx(classes.sortAsc, {
                            [classes.sortDesc]: settings.sortDesc,
                          })}
                          onClick={handleSortToggle}
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  {settings.sortOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* <Divider></Divider> */}
              <Form
                key={selectedLiveViewKey}
                initialValues={advancedFiltersToFormData(advancedFilters)}
                onSubmit={() => {}}
                keepDirtyOnReinitialize={true}
                mutators={{
                  resetFilter: ({ 1: name }, state, { changeValue }) => {
                    function wipeSelections(filter) {
                      delete filter.value;
                      return filter;
                    }

                    changeValue(state, name, wipeSelections);
                  },
                  // setValue: ([name, value], state, { changeValue }) => {
                  //   console.log('setValue');
                  //   changeValue(state, name, () => value);
                  // },
                  ...arrayMutators,
                }}
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      {Object.keys(liveFilters)
                        .filter((k) => k === type)
                        .map((filterType) => (
                          <div key={filterType}>
                            {getFilterSections(filterType, form)}
                          </div>
                        ))}
                      <FormSpy
                        subscription={{ values: true }}
                        onChange={(state) => {
                          const newAdvancedFilters = removeFiltersWithNoValue(
                            formDataToAdvancedFilters(state.values)
                          );

                          if (!_.isEqual(newAdvancedFilters, advancedFilters)) {
                            dispatch({
                              type: UPDATE_LIVE_ADVANCED_FILTERS,
                              payload: newAdvancedFilters,
                            });
                          }
                        }}
                      />
                    </form>
                  );
                }}
              />
              {type === 'callSigns' && (
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography
                    className={classes.sectionTitle}
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    {`Related Filters`}
                  </Typography>
                  <FilterSummary
                    filters={{
                      ...(vehiclesHaveCallsigns
                        ? { vehicles: advancedFilters.vehicles }
                        : {}),
                      people: advancedFilters.people,
                    }}
                    hideEmpty={false}
                  />
                </React.Fragment>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default FilterControl;

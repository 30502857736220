import {
  Avatar,
  Badge,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  ArrowUpward as ArrowUpwardIcon,
  Router as RouterIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { SearchBox } from '../../controls';
import { usePrevious } from '../../../hooks';
import { downloadCSV, downloadJSON } from '../../../apis/utilities';

const dateFormat = 'DD/MM/YYYY, HH:mm:ss';
const { useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
  },
  active: {
    backgroundColor: theme.palette.action.focus,
  },
  searchBox: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  settingsPanel: {
    padding: theme.spacing(0, 1, 1, 1),
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    // backgroundColor: theme.palette.primary.main,
    // color: 'white',
    width: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
  sortTextField: {
    paddingBottom: theme.spacing(1),
  },
  exportButton: {
    margin: theme.spacing(1, 1, 1, 0),
  },
}));

// so useMemo doesn't think these are new strings on compare
const badgeError = 'error';
const badgePrimary = 'primary';

// when this was in the FastList component, useMemo didn't work, I think
// because it was reset every time FastList was rendered
const Row = ({ data, index, style }) => {
  const { list, sortBy } = data;
  const item = list[index];
  const badgeNumber = item.isMultiAssigned
    ? item.multiAssignments.length
    : item.identificationNumber
    ? 1
    : 0;
  const badgeColor = item.isMultiAssigned ? badgeError : badgePrimary;

  return useMemo(
    () =>
      item && (
        <ListItem
          dense
          button
          key={item.imei}
          style={style}
          component={NavLink}
          to={`/resources/telematicsboxes/${encodeURIComponent(item.imei)}`}
          activeStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0.14)',
          }}
        >
          <ListItemAvatar>
            {badgeNumber > 0 ? (
              <Badge badgeContent={badgeNumber} color={badgeColor}>
                <Avatar>
                  <RouterIcon />
                </Avatar>
              </Badge>
            ) : (
              <Avatar>
                <RouterIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={item.imei}
            secondary={
              sortBy === 'mostRecentTime'
                ? !!item[sortBy] && moment(item[sortBy]).format(dateFormat)
                : sortBy === 'imei'
                ? ''
                : item[sortBy]
            }
          />
        </ListItem>
      ),
    [badgeColor, badgeNumber, item, sortBy, style]
  );
};

// rerendering the list every time a key was pressed was slow,
// results in nothing appearing in text box until .5s later
// this will only render it if the list changes
const FastList = React.memo(({ filteredList, classes, sortBy }) => {
  return (
    filteredList.length > 0 && (
      <List className={classes.list}>
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              height={height}
              overscanCount={10}
              itemData={{ list: filteredList, sortBy }}
              itemCount={filteredList.length}
              itemSize={60}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    )
  );
});

function filterList(list, searchTerm, filter, propsToSearch, sortBy, sortDesc) {
  return _.orderBy(
    list
      .filter(
        (item) =>
          !searchTerm ||
          propsToSearch.some((prop) =>
            _.get(item, prop)?.toLowerCase()?.includes(searchTerm.toLowerCase())
          )
      )
      .filter((item) => {
        switch (filter) {
          case 'noVehicle':
            return !item.identificationNumber;
          case 'neverPolled':
            return !item.mostRecentTime;
          case 'multiAssign':
            return item.isMultiAssigned;
          default:
            return true;
        }
      }),
    [sortBy],
    [sortDesc ? 'desc' : 'asc']
  );
}

export default function TelematicsBoxList() {
  const { id } = useParams();
  const boxes = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const [filterText, setFilterText] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('imei');
  const [sortDesc, setSortDesc] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const sortOptions = [
    ...(useReducedResourceInformation
      ? []
      : [{ label: 'Registration', value: 'registrationNumber' }]),
    { label: 'Fleet Number', value: 'fleetNumber' },
    { label: 'Last Poll Time', value: 'mostRecentTime' },
    { label: 'VIN', value: 'identificationNumber' },
    { label: 'IMEI', value: 'imei' },
  ];

  const propsToSearch = Object.values(sortOptions)
    .map((o) => o.value)
    .filter((s) => s !== 'mostRecentTime');

  const filters = [
    { label: 'All', value: 'all' },
    { label: 'No vehicle', value: 'noVehicle' },
    { label: 'Never polled', value: 'neverPolled' },
    { label: 'Multiple assignments', value: 'multiAssign' },
  ];

  const prevBoxes = usePrevious(boxes);
  useEffect(() => {
    if (boxes !== prevBoxes) {
      setFilteredList(
        filterList(
          Object.values(boxes),
          searchText,
          filterText,
          propsToSearch,
          sortBy,
          sortDesc
        )
      );
    }
  }, [
    boxes,
    searchText,
    filterText,
    propsToSearch,
    sortBy,
    sortDesc,
    prevBoxes,
  ]);

  function updateList({
    search = searchText,
    filter = filterText,
    sortProp = sortBy,
    sortDescending = sortDesc,
  }) {
    setFilteredList(
      filterList(
        Object.values(boxes),
        search,
        filter,
        propsToSearch,
        sortProp,
        sortDescending
      )
    );
  }

  const debouncedFilterList = _.debounce(updateList, 300, { trailing: true });

  function handleSearchChange(event) {
    const term = event.target.value;

    setSearchText(term);
    debouncedFilterList({ search: term });
  }

  function handleFilterChange(event) {
    const term = event.target.value;

    setFilterText(term);
    updateList({ filter: term });
  }

  function handleSortByChange(event) {
    const prop = event.target.value;

    setSortBy(prop);
    updateList({ sortProp: prop });
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
    updateList({ sortDescending: !sortDesc });
  }

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  async function handleCsvClick() {
    downloadCSV(
      Object.values(boxes).map((b) => ({
        ...b,
        //imei: `\t${b.imei}`,
        lastPollTime: b.mostRecentTime
          ? format(new Date(b.mostRecentTime), 'dd/MM/yyyy HH:mm:ss')
          : '',
      })),
      `Telematics Boxes ${moment().format('YYYY-MM-DD')}.csv`,
      [
        { label: 'IMEI', key: 'imei' },
        { label: 'Last poll time', key: 'lastPollTime' },
        { label: 'Fleet Number', key: 'fleetNumber' },
        ...(useReducedResourceInformation
          ? []
          : [{ label: 'Registration', key: 'registrationNumber' }]),
        { label: 'VIN', key: 'identificationNumber' },
      ]
    );
  }

  async function handleJsonClick() {
    downloadJSON(Object.values(boxes), 'telematicsBoxes.json');
  }

  return (
    (!isXs || !id) && (
      <Fragment>
        <div className={classes.searchBar}>
          <div className={classes.toolbar}>
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              className={classes.searchBox}
              endAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.count}
                  >
                    {`${filteredList.length}/${Object.keys(boxes).length}`}
                  </Typography>
                </InputAdornment>
              }
            />
            <IconButton
              title={showSettings ? 'Hide settings' : 'Show settings'}
              onClick={handleSettingsToggle}
            >
              <SettingsIcon color={showSettings ? 'primary' : 'inherit'} />
            </IconButton>
          </div>
          <Collapse in={showSettings} unmountOnExit>
            <div className={classes.settingsPanel}>
              <TextField
                className={classes.sortTextField}
                select
                fullWidth
                label="Filter"
                value={filterText}
                onChange={handleFilterChange}
              >
                {filters.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.sortTextField}
                select
                fullWidth
                label="Sort by"
                value={sortBy}
                onChange={handleSortByChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        title={sortDesc ? 'Descending' : 'Ascending'}
                        className={clsx(classes.sortAsc, {
                          [classes.sortDesc]: sortDesc,
                        })}
                        onClick={handleSortToggle}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {sortOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Export
              </Typography>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  className={classes.exportButton}
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  disableElevation
                  className={classes.exportButton}
                  onClick={handleJsonClick}
                >
                  JSON
                </Button>
              </div>
            </div>
          </Collapse>
        </div>
        {/* <SearchBox
          value={filterText}
          onChange={handleFilterChange}
          className={classes.searchBox}
        /> */}
        <FastList
          filteredList={filteredList}
          classes={classes}
          sortBy={sortBy}
        />
      </Fragment>
    )
  );
}

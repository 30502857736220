import React from 'react';
import {
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  AddCircle as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import Field from './Field';
import SelectField from './SelectField';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  fieldValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    maxWidth: 'calc(100% - 48px)',
  },
  subjectField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 160,
  },
  fieldField: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  sortAsc: {
    transform: 'rotate(0deg)',
    // marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
}));

function getSubjects(type) {
  switch (type) {
    case 'vehicleTrips':
    case 'vehicleIdles':
    case 'vehicleIdleCount':
      return [
        { label: 'Event', value: 'event' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
      ];
    case 'vehicleTime':
      return [
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
      ];
    case 'vehicleStops':
    case 'vehicleStopCount':
      return [
        { label: 'Event', value: 'event' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Last Driver', value: 'lastDriver' },
      ];
    case 'vehiclePolls':
      return [{ label: 'Vehicle', value: 'vehicle' }];
    case 'vehicleVisits':
    case 'vehicleVisitCount':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Vehicle', value: 'vehicle' },
        { label: 'Driver', value: 'driver' },
      ];
    case 'incidents':
    case 'incidentCount':
      return [{ label: 'Event', value: 'event' }];
    case 'personTrails':
    case 'personTime':
    case 'personPolls':
      return [{ label: 'Person', value: 'person' }];
    case 'personVisits':
    case 'personVisitCount':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Person', value: 'person' },
      ];
    case 'locations':
      return [
        { label: 'Location', value: 'location' },
        { label: 'Person', value: 'person' },
      ];
    case 'aggregates':
      return [
        { label: 'Event', value: 'event' }
      ];
    default:
      return [];
  }
}

function getFilterName(type, subject) {
  switch (subject) {
    case 'event':
      switch (type) {
        case 'vehicleTrips':
          return 'trip';
        case 'vehicleIdles':
        case 'vehicleIdleCount':
        case 'vehicleStops':
        case 'vehicleStopCount':
          return 'stop';
        case 'incidents':
        case 'incidentCount':
          return 'incident';
        case 'aggregates':
          return 'aggregate';
        default:
          return 'event';
      }
    case 'lastDriver':
    case 'driver':
      return 'person';
    default:
      return subject;
  }
}

function DirectionField({ input, position }) {
  const classes = useStyles();

  function handleSortToggle() {
    const value = input.value === 'asc' ? 'desc' : 'asc';
    input.onChange(value);
  }

  return (
    <InputAdornment position={position}>
      <IconButton
        title={input.value !== 'asc' ? 'Descending' : 'Ascending'}
        className={clsx(classes.sortAsc, {
          [classes.sortDesc]: input.value !== 'asc',
        })}
        onClick={handleSortToggle}
      >
        <ArrowUpwardIcon />
      </IconButton>
    </InputAdornment>
  );
}

export default function SortField({
  fields,
  type,
  filters,
  label,
  clearValue,
}) {
  const classes = useStyles();
  const subjects = getSubjects(type);

  function handleAddClick() {
    fields.push({ direction: 'asc' });
  }

  const handleRemoveClick = (index) => () => {
    fields.remove(index);
  };

  const handleSubjectChange = (name) => () => {
    clearValue(name);
  };

  return (
    <div>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        className={classes.title}
      >
        {label}
      </Typography>
      <div>
        {fields.map((name, index) => {
          const subject = fields.value[index]?.subject;
          const fieldValues = subject
            ? Object.entries(filters[getFilterName(type, subject)] || {}).map(
                (filter) => ({
                  label: filter[1].label,
                  value: filter[0],
                })
              )
            : [];
          fieldValues.sort((a, b) => a.label.localeCompare(b.label));

          return (
            <div className={classes.row} key={index}>
              <div className={classes.fieldValueContainer}>
                <Field
                  name={`${name}.subject`}
                  component={SelectField}
                  label="Subject"
                  values={subjects}
                  className={classes.subjectField}
                  onChange={handleSubjectChange(`${name}.field`)}
                />
                <Field
                  name={`${name}.field`}
                  component={SelectField}
                  label="Field"
                  values={fieldValues}
                  className={classes.fieldField}
                  InputProps={{
                    endAdornment: (
                      <Field
                        name={`${name}.direction`}
                        component={DirectionField}
                        position="start"
                      />
                    ),
                  }}
                />
              </div>
              <IconButton
                className={classes.button}
                title="Remove"
                aria-label="Remove"
                onClick={handleRemoveClick(index)}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
      <div className={classes.row}>
        <div style={{ flex: 1 }} />
        <IconButton
          className={classes.addButton}
          title="Add"
          aria-label="Add"
          onClick={handleAddClick}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}

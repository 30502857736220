import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Autorenew as AutorenewIcon,
  ExpandMore as ExpandMoreIcon,
  // Warning as WarningIcon,
} from '@material-ui/icons';
import {
  Grid as HighPrecisionIcon,
  GridLarge as LowPrecisionIcon,
  SortVariantLock as VirtualiseMapOnIcon,
  SortVariantLockOpen as VirtualiseMapOffIcon,
  // MapLegend as VirtualiseMapIcon,
} from 'mdi-material-ui';
import React, { Fragment, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { SliderField, requiredNotNegative } from '../fields';
import SourceFilters from './SourceFilters';
import TypeParameters from './TypeParameters';
import { ConfirmationDialog } from '../dialogs';
import { Field, SelectField, DebouncedTextField, required } from '../fields';
import { types, sources, compareLabels, tooManyMapItems } from './constants';

const { isFleet } = window.config;


const useStyles = makeStyles((theme) => ({
  list: {
    overflow: 'auto',
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1, 0.5, 1, 1),
    overflow: 'visible',
  },
  cardActions: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start'
  },
  cardButtons: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  hoveredCard: {
    margin: theme.spacing(1, 0.5, 1, 1),
    overflow: 'visible',
    backgroundColor: theme.palette.action.hover,
  },
  labelField: {
    marginRight: theme.spacing(1),
  },
  colorsField: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  typeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 80,
  },
  sourceField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 236,
  },
  boundaryTypeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 96,
  },
  boundarySubtypeField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },
  drawButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  delete: {
    color: theme.palette.error.main,
  },
  warn: {
    color: theme.palette.warning.main,
  },
  estimationNormal: {
    color: theme.palette.text.secondary,
  },
  estimationWarn: {
    color: theme.palette.warning.main,
  },
  estimationAlert: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 10,
  },
  progress: {
    margin: theme.spacing(1),
  },
  precisionContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  sliderField: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: '100%',
  },
  helperTextLabel: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  estimationLabel: {
    height: 16,
    paddingLeft: theme.spacing(1.5),
  },
}));

const boundaryTypes = isFleet
  ? [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
    ]
  : [
      { label: 'None', value: 'None' },
      { label: 'Custom', value: 'Custom' },
      { label: 'Location', value: 'Location' },
      { label: 'Objective', value: 'Objective' },
      { label: 'Perimeter', value: 'Perimeter' },
    ];

export default function LayerList({
  fields,
  onSelect,
  hiddenLayerIndexes,
  hoveredItemIndex,
  onVisibilityToggle,
  onRefresh,
  loadingLayers,
  estimatingLayers,
  boundaries,
  onBoundaryChange,
  clearValue,
  expandedLayerIndex,
  onExpanded,
  onDraw,
  onVirtualizationChange,
  filters,
}) {
  const [deleteLayer, setDeleteLayer] = useState(null);

  const classes = useStyles();
  const theme = useTheme();

  function handleAddLayer() {
    fields.push({
      label: '',
      type: 'shape',
      source: '',
      startTime: new Date(),
      endTime: new Date(),
      colors: [theme.palette.grey[500]],
      parameters: {},
    });
  }

  function handleDeleteLayer() {
    fields.remove(deleteLayer.index);

    setDeleteLayer(null);
  }

  function handleDeleteCancel() {
    setDeleteLayer(null);
  }

  function handleExpandClick(event) {
    const index = parseInt(event.currentTarget.value);

    if (expandedLayerIndex === index) {
      onExpanded(null);
    } else {
      onExpanded(index);
    }
  }

  function handleVirtualizationToggle(layer, index) {
    onVirtualizationChange({
      index,
      virtualize: layer.virtualize === undefined ? false : !layer.virtualize,
    });
  }

  function handleDeleteClick(event) {
    const index = parseInt(event.currentTarget.value);
    setDeleteLayer({ label: fields.value[index].label, index });
  }

  function handleItemsClick(event) {
    const index = parseInt(event.currentTarget.value);
    onSelect({ layerIndex: index });
  }

  function handleVisibilityClick(event) {
    const index = parseInt(event.currentTarget.value);
    onVisibilityToggle(index);
  }

  function handleRefreshClick(event) {
    const index = parseInt(event.currentTarget.value);
    onRefresh(index, fields.value[index]);
  }

  function handleDrawClick(event) {
    const index = parseInt(event.currentTarget.value);
    onDraw(index);
  }

  const handleTypeChange = (name) => () => {
    clearValue(`${name}.source`);
    clearValue(`${name}.parameters`);
    // clearValue(`${name}.filters`);
    clearValue(`${name}.clientFilters`);
    clearValue(`${name}.searchText`);
    clearValue(`${name}.sort`);
    clearValue(`${name}.featureCollection`);

  };

  const handleSourceChange = (name) => () => {
    clearValue(`${name}.parameters`);
    // clearValue(`${name}.filters`);
    clearValue(`${name}.clientFilters`);
    clearValue(`${name}.searchText`);
    clearValue(`${name}.sort`);
    clearValue(`${name}.featureCollection`);
  };

  const handleBoundaryTypeChange = (name) => () => {
    clearValue(`${name}.boundarySubtype`);
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  const handleBoundarySubtypeChange = (name) => () => {
    clearValue(`${name}.boundaryIdentifier`);
    clearValue(`${name}.boundaryGeometry`);
  };

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    if (expandedLayerIndex === result.source.index) {
      onExpanded(result.destination.index);
    } else {
      if (
        expandedLayerIndex &&
        expandedLayerIndex > result.source.index &&
        expandedLayerIndex <= result.destination.index
      ) {
        onExpanded(expandedLayerIndex - 1);
      }

      if (
        expandedLayerIndex &&
        expandedLayerIndex >= result.destination.index &&
        expandedLayerIndex < result.source.index
      ) {
        onExpanded(expandedLayerIndex + 1);
      }
    }

    fields.move(result.source.index, result.destination.index);
  }

  const handleBoundaryChange = (index) => (event) => {
    const layer = fields.value[index];

    onBoundaryChange(index, {
      ...layer,
      boundaryIdentifier: event.target.value,
    });
  };

  // https://stackoverflow.com/a/60980688/1350146
  function abbreviateNumber(num) {
    return (
      '~' +
      new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(num)
    );
  }

  function estimationLabel(layer) {
    let labelClass = classes.estimationNormal;

    if (layer.estimatedRecords > 100000) {
      labelClass = classes.estimationAlert;
    } else if (layer.estimatedRecords > 10000) {
      labelClass = classes.estimationWarn;
    }

    return (
      <div className={classes.estimationLabel}>
        <Typography variant="caption" className={labelClass}>
          {estimatingLayers.includes(layer.index)
            ? 'Estimating...'
            : layer.estimatedRecords &&
              `${abbreviateNumber(layer.estimatedRecords)} items estimated`}
        </Typography>
      </div>
    );
  }

  function numberOfItems(layer) {
    let total = layer.featureCollection.features.length;

    // if "count" is a property, it's aggregated - add each count together
    if (total > 0 && layer.featureCollection.features[0].properties.count) {
      const clusters = total;
      total = layer.featureCollection.features.reduce(
        (total, feature) => total + feature.properties.count,
        0
      );
      return `${total} items (${clusters} clusters)`;
    } else {
      return `${total} items`;
    }
  }

  return (
    <Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={classes.list}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((name, index) => {
                const layer = fields.value[index];

                const background =
                  layer.colors.length > 1
                    ? `linear-gradient(${layer.colors.join()})`
                    : layer.colors[0] || theme.palette.grey[500];

                const color = theme.palette.getContrastText(
                  layer.colors[Math.floor(layer.colors.length / 2)] ||
                    theme.palette.grey[500]
                );

                return (
                  <Draggable
                    key={name}
                    draggableId={name}
                    index={index}
                    isDragDisabled={expandedLayerIndex === index}
                  >
                    {(provided, snapshot) => (
                      <Card
                        className={
                          hoveredItemIndex.layerIndex === index
                            ? classes.hoveredCard
                            : classes.card
                        }
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CardHeader
                          avatar={
                            <Avatar
                              style={{
                                background,
                                color,
                              }}
                              title={types[layer.type].label}
                            >
                              {types[layer.type].icon}
                            </Avatar>
                          }
                          action={
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]:
                                  expandedLayerIndex === index,
                              })}
                              onClick={handleExpandClick}
                              aria-expanded={expandedLayerIndex === index}
                              aria-label="Settings"
                              value={index}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          }
                          title={layer.label}
                          subheader={
                            layer.source &&
                            (
                              sources[layer.type].find(
                                (source) => source.value === layer.source
                              ) || { label: 'Unknown' }
                            ).label
                          }
                        />
                        <Collapse
                          in={expandedLayerIndex === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <CardContent className={classes.cardContent}>
                            <Field
                              fullWidth
                              className={classes.labelField}
                              label="Label"
                              name={`${name}.label`}
                              component={DebouncedTextField}
                              // component={TextField}
                            />
                            <Field
                              label="Type"
                              className={classes.typeField}
                              name={`${name}.type`}
                              values={Object.values(types)
                                .map(({ label, value }) => ({
                                  label,
                                  value,
                                }))
                                .sort(compareLabels)}
                              component={SelectField}
                              onChange={handleTypeChange(name)}
                              validate={required}
                            />
                            <Field
                              label="Source"
                              className={classes.sourceField}
                              name={`${name}.source`}
                              values={sources[layer.type].sort(compareLabels)}
                              component={SelectField}
                              onChange={handleSourceChange(name)}
                              validate={required}
                            />
                            <Divider className={classes.divider} />
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              Boundary
                            </Typography>
                            <Field
                              label="Type"
                              className={classes.boundaryTypeField}
                              name={`${name}.boundaryType`}
                              values={boundaryTypes}
                              component={SelectField}
                              onChange={handleBoundaryTypeChange(name)}
                            />
                            {Object.keys(boundaries).includes(
                              layer.boundaryType
                            ) && (
                              <Fragment>
                                <Field
                                  label="Subtype"
                                  className={classes.boundarySubtypeField}
                                  name={`${name}.boundarySubtype`}
                                  values={Object.keys(
                                    boundaries[layer.boundaryType]
                                  ).sort()}
                                  component={SelectField}
                                  onChange={handleBoundarySubtypeChange(name)}
                                />
                                {layer.boundarySubtype && (
                                  <Field
                                    fullWidth
                                    label="Name"
                                    className={classes.colorsField}
                                    name={`${name}.boundaryIdentifier`}
                                    values={(
                                      boundaries[layer.boundaryType]?.[
                                        layer.boundarySubtype
                                      ] || []
                                    ).sort(compareLabels)}
                                    component={SelectField}
                                    onChange={handleBoundaryChange(index)}
                                  />
                                )}
                              </Fragment>
                            )}
                            {layer.boundaryType === 'Custom' && (
                              <IconButton
                                className={classes.drawButton}
                                title="Draw"
                                value={index}
                                onClick={handleDrawClick}
                              >
                                <CreateIcon
                                  color={
                                    Boolean(layer.boundaryGeometry)
                                      ? 'primary'
                                      : 'inherit'
                                  }
                                />
                              </IconButton>
                            )}
                            <Divider className={classes.divider} />
                            <TypeParameters
                              type={layer.type}
                              name={name}
                              value={layer}
                            />
                            {layer.source &&
                              sources[layer.type].find(
                                (source) => source.value === layer.source
                              ) && (
                                <Fragment>
                                  <Divider className={classes.divider} />
                                  <SourceFilters
                                    type={layer.source}
                                    name={`${name}.filters`}
                                    filters={filters}
                                  />
                                </Fragment>
                              )}
                            {layer?.type === 'heat' && (
                              <Fragment>
                                <Divider className={classes.divider} />
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  Precision
                                </Typography>

                                <div
                                  id="precision"
                                  className={classes.precisionContainer}
                                >
                                  <LowPrecisionIcon />
                                  <Field
                                    className={classes.sliderField}
                                    name={`${name}.precision`}
                                    component={SliderField}
                                    label="Precision"
                                    type="number"
                                    // InputProps={{ marks: true, min: 1, max: 7, step: 1 }}
                                    marks
                                    min={1}
                                    max={7}
                                    step={1}
                                    fallbackValue={4} // default or initial are taken
                                    validate={requiredNotNegative}
                                  />
                                  <HighPrecisionIcon />
                                </div>
                                <div className={classes.helperTextLabel}>
                                  <Typography variant="caption">
                                    Lower precision can speed up the query by clustering
                                  </Typography>
                                </div>
                              </Fragment>
                            )}
                          </CardContent>
                        </Collapse>
                        <CardActions disableSpacing className={classes.cardActions}>
                          <div className={classes.cardButtons}>
                          <IconButton
                            aria-label="Fetch"
                            title={
                              loadingLayers.includes(index) ? 'Cancel' : 'Fetch'
                            }
                            onClick={handleRefreshClick}
                            value={index}
                            disabled={!(layer.source && layer.type)}
                          >
                            {loadingLayers.includes(index) ? (
                              <CloseIcon color="error" />
                            ) : (
                              <AutorenewIcon />
                            )}
                          </IconButton>
                          <IconButton
                            aria-label="Delete"
                            title="Delete"
                            className={classes.delete}
                            onClick={handleDeleteClick}
                            value={index}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {layer.featureCollection && (
                            <Button onClick={handleItemsClick} value={index}>
                              {numberOfItems(layer)}
                            </Button>
                          )}
                          {loadingLayers.includes(index) && (
                            <CircularProgress
                              className={classes.progress}
                              size={16}
                              thickness={6}
                            />
                          )}
                          <div style={{ flex: 1 }} />
                          {(tooManyMapItems(layer) || layer.virtualize !== undefined) && (
                            <IconButton
                              onClick={() => handleVirtualizationToggle(layer, index)}
                              className={
                                layer.virtualize === false ? classes.warn : ''
                              }
                              title={
                                layer.virtualize === false 
                                ? 'Showing all items on map (may decrease performance)'
                                : 'Locked items shown on map to those visible in results list'
                              }
                              value={index}
                            >
                              {layer.virtualize === false
                                ? <VirtualiseMapOffIcon />
                                : <VirtualiseMapOnIcon />
                              }
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="Visibility"
                            title={
                              hiddenLayerIndexes.includes(index)
                                ? 'Show Layer'
                                : 'Hide Layer'
                            }
                            onClick={handleVisibilityClick}
                            value={index}
                          >
                            {hiddenLayerIndexes.includes(index) ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                          </div>
                          <div>
                            {estimationLabel({...layer, index})}
                          </div>
                        </CardActions>
                      </Card>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={handleAddLayer}
      >
        <AddIcon />
      </Fab>
      <ConfirmationDialog
        action="Delete"
        open={deleteLayer !== null}
        itemId={(deleteLayer && deleteLayer.label) || 'Untitled'}
        onOk={handleDeleteLayer}
        onCancel={handleDeleteCancel}
      />
    </Fragment>
  );
}

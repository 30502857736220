import {
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  FormHelperText,
  FormControl,
  Input,
} from '@material-ui/core';
import { DirectionsCar as CarIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Form } from 'react-final-form';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import { useAuth } from '../Auth';
import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  FETCH_HOME_STATIONS,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESS,
  UPDATE_VEHICLE,
} from '../../actions';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  AvatarField,
  TextField,
  SelectField,
  TypesField,
  DateTimeField,
  isDateValidOrEmpty,
} from '../fields';
import { doesIdExist, imeiValid } from '../../apis/utilities';
import TelematicsBoxVehicles from './TelematicsBoxList/TelematicsBoxVehicles';
import { useCommissioningDialog } from './TelematicsBoxList/CommissioningDialog';
import VehicleKeyFields from './VehicleKeyFields';
import { RouteLeavingGuard } from '../controls';

const {
  vehicleTypes,
  vehicleFuelTypes,
  vehicleGroups,
  vehicleEditableFields,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  dateTimeField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  equipment: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  telematicsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'end',
  },
}));

export default function ReducedVehicle() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicles.vehicle, _.isEqual);
  const error = useSelector((state) => state.vehicles.error);
  const homeStationNames = useSelector(
    (state) => state.locations.homeStationNames,
    _.isEqual
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const auth = useAuth();
  const snackbar = useSnackbar();
  const editable = auth.isAuthorised('vehicles', true);
  const canCommission = auth.getAuthorisation().commissioning.write && editable;
  const commissioningDialog = useCommissioningDialog();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_VEHICLE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE,
        payload: id,
      });
    }
    dispatch({
      type: FETCH_HOME_STATIONS,
    });
  }, [id, dispatch]);

  async function onSubmit(values) {
    const validImei = await imeiValid(
      values.telematicsBoxImei,
      values.identificationNumber
    );
    if (!validImei) {
      return { telematicsBoxImei: 'In use' };
    }

    if (vehicle) {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: values,
      });
    } else {
      const exists = await doesIdExist('vehicles', values.identificationNumber);
      if (exists) {
        return { identificationNumber: 'Exists' };
      }

      dispatch({
        type: CREATE_VEHICLE,
        payload: {
          ...values,
          redirect: true
        }
      });
    }
  }

  function handleDelete() {
    if (vehicle) {
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicle.identificationNumber,
      });
    }
  }

  function isDisabled(fieldName) {
    return !(
      editable &&
      (vehicleEditableFields[fieldName] || vehicle === null)
    );
  }

  const noVehicle = vehicle === null;
  const needToRemoveAssociation = !!vehicle?.telematicsBoxImei;

  return (
    <Form
      initialValues={vehicle || {}}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Paper className={classes.card}>
            <Helmet>
              <title>
                IR3 | Vehicle
                {values.fleetNumber ? ` | ${values.fleetNumber}` : ''}
              </title>
            </Helmet>
            <CardHeader
              avatar={
                <Field
                  name="picture"
                  component={AvatarField}
                  icon={<CarIcon />}
                  disabled={isDisabled('picture')}
                />
              }
              title={values.fleetNumber}
              subheader={values.identificationNumber}
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <VehicleKeyFields vehicle={vehicle} classes={classes} />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Details</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="type"
                      component={SelectField}
                      label="Type"
                      className={classes.textField}
                      values={vehicleTypes}
                      disabled={isDisabled('type')}
                    />
                    <Field
                      name="fuelType"
                      component={SelectField}
                      label="Fuel Type"
                      className={classes.textField}
                      values={vehicleFuelTypes}
                      disabled={isDisabled('fuelType')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Home</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="homeStation"
                      component={SelectField}
                      label="Location"
                      values={homeStationNames.map((station) => ({
                        label: station.name,
                        value: station.code,
                      }))}
                      className={classes.textField}
                      disabled={isDisabled('homeStation')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={vehicleGroups}
                      component={TypesField}
                      disabled={isDisabled('areas')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Telematics Box</ListSubheader>
                <ListItem>
                  <div className={classes.telematicsSection}>
                    <div className={classes.section}>
                      <TelematicsBoxVehicles
                        identificationNumber={vehicle?.identificationNumber}
                      />
                      {/* <Field
                      name="telematicsBoxImei"
                      component={TextField}
                      label="IMEI"
                      className={classes.textField}
                      disabled={isDisabled('telematicsBoxImei')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                history.push(
                                  `/resources/telematicsboxes/${values.telematicsBoxImei}`
                                )
                              }
                            >
                              <RouterIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                      <Field
                        label="Last Poll Time"
                        name="lastPollTime"
                        component={TextField}
                        className={classes.textField}
                        format={(value) =>
                          value ? moment(value).fromNow() : 'never'
                        }
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                      />
                    </div>
                    {canCommission && (
                      <FormControl>
                        <Input
                          disableUnderline
                          inputComponent={() => (
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={
                                noVehicle || needToRemoveAssociation || dirty
                              } // save vehicle info first so no conflict
                              onClick={() =>
                                commissioningDialog.show({ vehicle, redirect: false })
                              }
                            >
                              commission new
                            </Button>
                          )}
                        />
                        <FormHelperText>
                          {(() => {
                            if (noVehicle || dirty) {
                              return 'Must save vehicle first';
                            } else if (needToRemoveAssociation) {
                              return 'Remove existing association first';
                            }
                          })()}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Odometer</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="lastOdometerReading.time"
                      component={DateTimeField}
                      label="Reading Date & Time"
                      className={classes.dateTimeField}
                      disabled={isDisabled('lastOdometerReading')}
                      validate={isDateValidOrEmpty}
                    />
                    <Field
                      name="lastOdometerReading.distanceMiles"
                      type="number"
                      component={TextField}
                      label="Reading Miles"
                      className={classes.textField}
                      disabled={isDisabled('lastOdometerReading')}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Disposed</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="disposalDate"
                      component={DateTimeField}
                      label="Date & Time"
                      className={classes.dateTimeField}
                      disabled={isDisabled('disposalDate')}
                      validate={isDateValidOrEmpty}
                    />
                  </div>
                </ListItem>
              </List>
            </CardContent>
            <CardActions>
              {editable && (
                <Fragment>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    disabled={pristine || submitting}
                    onClick={reset}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.delete}
                    onClick={() => setDeleteOpen(true)}
                    disabled={vehicle === null}
                  >
                    Delete
                  </Button>
                </Fragment>
              )}
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.registrationNumber || values.fleetNumber}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}

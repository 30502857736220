import { mergeMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE,
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_FAILURE,
  CREATE_PERSON,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_FAILURE,
  UPDATE_PERSON,
  UPDATE_PERSON_SUCCESS,
  UPDATE_PERSON_FAILURE,
  DELETE_PERSON,
  DELETE_PERSON_SUCCESS,
  DELETE_PERSON_FAILURE,
  FETCH_RFID_CARDS,
  FETCH_RFID_CARDS_SUCCESS,
  FETCH_RFID_CARDS_FAILURE,
  FETCH_RFID_CARD_PEOPLE,
  FETCH_RFID_CARD_PEOPLE_SUCCESS,
  FETCH_RFID_CARD_PEOPLE_FAILURE,
  REMOVE_RFID_CARD,
  REMOVE_RFID_CARD_SUCCESS,
  REMOVE_RFID_CARD_FAILURE,
  FETCH_POST_HIERARCHY,
  FETCH_POST_HIERARCHY_SUCCESS,
  FETCH_POST_HIERARCHY_FAILURE,
} from '../actions';
import api from '../apis';
import { getHeaders, log, fetchPersonRequest } from '../apis/utilities';
import history from '../history';

const { personRanks, useRestricted } = window.config;

async function fetchPeopleRequest() {
  const response = await api.get('/people', {
    params: {
      projection: {
        code: true,
        picture: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        radioSsi: true,
        lastPollTime: true,
        rfidCards: true,
        role: true,
        homeStation: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'People');

  return response.data.map((person) => {
    return {
      ...person,
      searchString: (
        `${person.forenames} ${person.surname}+` +
        `${_.join(
          (person.rfidCards || []).map((card) => card.reference),
          '+'
        )}+` +
        `${_.join(
          _.values(
            _.omit(person, ['forenames', 'surname', 'rfidCards', 'picture'])
          ),
          '+'
        )}`
      ).toLowerCase(),
    };
  });
}

export function fetchPeopleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PEOPLE),
    mergeMap(() =>
      from(fetchPeopleRequest()).pipe(
        map((payload) => ({
          type: FETCH_PEOPLE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PEOPLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchPersonEpic(action$) {
  return action$.pipe(
    ofType(FETCH_PERSON),
    mergeMap(({ payload: id }) =>
      from(fetchPersonRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_PERSON_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createPersonRequest({ rank, ...person }) {
  rank = personRanks.find((item) => item.code === (rank || {}).code);
  if (useRestricted && person.restricted === undefined) {
    person.restricted = false;
  }
  person = rank ? { rank, ...person } : person;

  await api.post(`/people`, person, {
    headers: getHeaders(),
  });

  history.replace(person.code);

  return rank ? { rank, ...person } : person;
}

export function createPersonEpic(action$) {
  return action$.pipe(
    ofType(CREATE_PERSON),
    mergeMap(({ payload: values }) =>
      from(createPersonRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_PERSON_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updatePersonRequest({ rank, ...person }) {
  rank = personRanks.find((item) => item.code === (rank || {}).code);
  person = rank ? { rank, ...person } : person;

  await api.patch(`/people/${person.code}`, person, {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/merge-patch+json',
    },
  });

  return rank ? { rank, ...person } : person;
}

export function updatePersonEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_PERSON),
    mergeMap(({ payload: values }) =>
      from(updatePersonRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_PERSON_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deletePersonRequest(id) {
  await api.delete(`/people/${id}`, { headers: getHeaders() });

  history.push('.');

  return id;
}

export function deletePersonEpic(action$) {
  return action$.pipe(
    ofType(DELETE_PERSON),
    mergeMap(({ payload: id }) =>
      from(deletePersonRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_PERSON_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_PERSON_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchRfidCardsRequest() {
  const response = await api.get('/people', {
    params: {
      projection: { code: true, rfidCards: true },
    },
    headers: getHeaders(),
  });

  return response.data
    .map((person) => person.rfidCards)
    .reduce((acc, cur) => acc.concat(cur), [])
    .filter((id) => id && id.reference)
    .map((id) => id.reference.toLowerCase())
    .reduce((p, c) => {
      if (c in p) {
        p[c]++;
      } else {
        p[c] = 1;
      }
      return p;
    }, {});
}

export function fetchRfidCardsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RFID_CARDS),
    mergeMap(() =>
      from(fetchRfidCardsRequest()).pipe(
        map((payload) => ({
          type: FETCH_RFID_CARDS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RFID_CARDS_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchRfidCardPeopleRequest(id) {
  const response = await api.get('/people', {
    params: {
      query: {
        // identification: id,
        'rfidCards.reference': id,
      },
      projection: {
        code: true,
        forenames: true,
        surname: true,
        collarNumber: true,
        rfidCards: true,
      },
    },
    headers: getHeaders(),
  });

  log('Read', 'RFID Card Owners', { id });

  return response.data;
}

export function fetchRfidCardPeopleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RFID_CARD_PEOPLE),
    mergeMap(({ payload: id }) =>
      from(fetchRfidCardPeopleRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_RFID_CARD_PEOPLE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RFID_CARD_PEOPLE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function removeRfidCardEpic(action$) {
  return action$.pipe(
    ofType(REMOVE_RFID_CARD),
    mergeMap(({ payload: values }) =>
      from(updatePersonRequest(values)).pipe(
        map(({ code: payload }) => ({
          type: REMOVE_RFID_CARD_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: REMOVE_RFID_CARD_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchPostHierarchyRequest() {
  const response = await api.get('/groups/POSTS', {
    params: {
      query: {
        fields: ['data'],
      },
    },
    headers: getHeaders(),
  });

  return response.data.data;
}

export function fetchPostHierarchyEpic(action$) {
  return action$.pipe(
    ofType(FETCH_POST_HIERARCHY),
    mergeMap(() =>
      from(fetchPostHierarchyRequest()).pipe(
        map((payload) => ({
          type: FETCH_POST_HIERARCHY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_POST_HIERARCHY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

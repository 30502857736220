import {
  mergeMap,
  map,
  catchError,
  takeUntil,
  endWith,
  switchMap,
} from 'rxjs/operators';
import { from, of, timer, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import history from '../history';
import { getHeaders } from '../apis/utilities';

import {
  FETCH_VEHICLE,
  FETCH_TELEMATICS_BOX_POLLS,
  FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
  FETCH_TELEMATICS_BOX_POLLS_FAILURE,
  FETCH_TELEMATICS_BOXES,
  FETCH_TELEMATICS_BOXES_SUCCESS,
  FETCH_TELEMATICS_BOXES_FAILURE,
  START_TELEMATICS_BOX_POLLS_STREAM,
  START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
  END_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  RECEIVE_TELEMATICS_BOX_POLL,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS,
  // FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE,
  UPDATE_VEHICLE_IMEI,
  UPDATE_VEHICLE_IMEI_SUCCESS,
  UPDATE_VEHICLE_IMEI_FAILURE,
  CREATE_TELEMATICS_BOX,
  UPDATE_TELEMATICS_BOX,
  DELETE_TELEMATICS_BOX,
  //  CREATE_TELEMATICS_BOX_FAILURE,
  //  CREATE_TELEMATICS_BOX_SUCCESS,
  FETCH_DRIVER_ID,
  UPDATE_DRIVER_ID,
  CANCEL_DRIVER_ID,
  FETCH_TRACKING,
  UPDATE_TRACKING,
  CANCEL_TRACKING,
  CREATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_SUCCESS,
} from '../actions';
import api from '../apis';
import { reduceByType, log, doesIdExist } from '../apis/utilities';

async function apiGet(url, params) {
  const response = await api.get(url, {
    params,
    headers: getHeaders(),
  });

  return response.data;
}

const { dioOptions: { hideMap } = { hideMap: false } } = window.config;

async function fetchTelematicsBoxesRequest(boxQuery, vehicleQuery) {
  const boxParams = {
    projection: {
      imei: true,
      // mostRecentPoll: true,
      'mostRecentPoll.identifier': true,
      'mostRecentPoll.time': true,
      'mostRecentPoll.diagnosticCode': true,
      'mostRecentPoll.deviceProperties': true,
      'mostRecentPoll.position': true,
      'mostRecentPoll.driver': true,
      'mostRecentPoll.ignitionOn': true,
      ...(hideMap ? {} : { 'mostRecentPoll.locations': true }),
      lastPosition: true,
      gpsValidPollCount: true,
      lastIgnitionOffTime: true,
      make: true,
      model: true,
      hardwareVersion: true,
      active: true,
    },
    query: boxQuery,
  };

  const vehicleParams = {
    projection: {
      identificationNumber: true,
      registrationNumber: true,
      fleetNumber: true,
      telematicsBoxImei: true,
      areas: true,
      role: true,
      disposalDate: true,
      type: true,
    },
    query: vehicleQuery,
  };

  const [boxes, vehicles] = await Promise.all([
    apiGet('/telematicsBoxes', boxParams),
    apiGet('/vehicles', vehicleParams),
  ]);

  const vehiclesByImei = _.mapKeys(vehicles, 'telematicsBoxImei');
  const multiAssignedImeis = _(vehicles)
    .groupBy((v) => v.telematicsBoxImei)
    .pickBy((x) => x.length > 1)
    .keys()
    .value();

  const boxesWithVehicles = boxes.map((box) => {
    const { mostRecentPoll: poll, active = true } = box;
    const {
      time: mostRecentTime,
      position: lastPosition,
      ignitionOn,
      locations = [],
      deviceProperties,
    } = poll || {}; // sometimes there's no poll
    const { batteryVoltage = '', deviceSignalStrength: signalStrength } =
      deviceProperties || {};
    const {
      identificationNumber,
      registrationNumber,
      fleetNumber,
      areas = [],
      role,
      disposalDate,
      type,
    } = box.imei in vehiclesByImei ? vehiclesByImei[box.imei] : {};
    const isMultiAssigned = multiAssignedImeis.includes(box.imei);
    const multiAssignments = vehicles.filter(
      (v) => v.telematicsBoxImei === box.imei
    );

    return {
      ...box,
      active,
      batteryVoltage,
      signalStrength,
      mostRecentTime,
      lastPosition,
      registrationNumber,
      fleetNumber,
      identificationNumber,
      ignitionOn,
      locations,
      areas: reduceByType(areas),
      isMultiAssigned,
      multiAssignments,
      role,
      disposalDate,
      type,
    };
  });

  return {
    boxesByImei: _.mapKeys(boxesWithVehicles, 'imei'),
    vehiclesById: _.mapKeys(vehicles, 'identificationNumber'),
  };
}

async function fetchTelematicsBoxPollsRequest(imei, start, end) {
  const params = {
    query: {
      imei,
      time: {
        $gte: start.toISOString(),
        $lte: end.toISOString(),
      },
      // TODO date query not working
      // start: moment().subtract(1, 'm'),
      // end: moment().add(24, 'h')
    },
    projection: {
      imei: true,
      time: true,
      diagnosticCode: true,
      deviceProperties: true,
      position: true,
      driver: true,
      ignitionOn: true,
      ...Object.fromEntries(
        Object.keys(window.config.dioStates).map((key) => [key, true])
      ),
    },
  };

  const data = await apiGet('/telematicsBoxPolls', params);

  return data.map((telematicsBoxPoll) => {
    return {
      ...telematicsBoxPoll,
      searchString: `${telematicsBoxPoll.imei}`.toLowerCase(),
    };
  });
}

export function fetchTelematicsBoxPollsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOX_POLLS),
    mergeMap(({ payload: { imei, start, end } }) =>
      from(fetchTelematicsBoxPollsRequest(imei, start, end)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
          payload: {
            imei,
            polls: payload,
          },
        })),
        catchError(({ message }) =>
          of({
            type: FETCH_TELEMATICS_BOX_POLLS_FAILURE,
            payload: {
              imei,
              message,
            },
          })
        )
      )
    )
  );
}

export function fetchTelematicsBoxesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TELEMATICS_BOXES),
    mergeMap(({ boxQuery, vehicleQuery }) =>
      from(fetchTelematicsBoxesRequest(boxQuery, vehicleQuery)).pipe(
        map((payload) => ({
          type: FETCH_TELEMATICS_BOXES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TELEMATICS_BOXES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

// internal actions just used by socket & epic
const SOCKET_SUBSCRIBED = 'SOCKET_SUBSCRIBED';
const SOCKET_POLL_RECEIVED = 'SOCKET_POLL_RECEIVED';
function getTelematicsBoxPollsObservable(imei) {
  return new Observable((observer) => {
    const socket = new WebSocket(window.config.wsRootUrl);

    const inputParams = {
      action: 'SUBSCRIBE',
      authorization: 'Bearer ' + localStorage.getItem('access_token'),
      payload: {
        telematicsBoxes: {
          query: {
            imei: { $eq: imei },
          },
          projection: {
            imei: true,
            'mostRecentPoll.identifier': true,
            'mostRecentPoll.bufferCount': true,
            'mostRecentPoll.time': true,
            'mostRecentPoll.diagnosticCode': true,
            'mostRecentPoll.deviceProperties': true,
            'mostRecentPoll.position': true,
            'mostRecentPoll.driver': true,
            'mostRecentPoll.ignitionOn': true,
            ...Object.fromEntries(
              Object.keys(window.config.dioStates).map((key) => [
                'mostRecentPoll.' + key,
                true,
              ])
            ),
            cachedPolls: true,
          },
        },
      },
    };

    socket.onerror = (e) => observer.error(e);

    socket.onmessage = (poll) => {
      try {
        const data = JSON.parse(poll.data);

        if (data.action === 'ERROR') {
          observer.error({ message: data.payload });
        } else {
          if (data.payload.telematicsBoxes[imei]?.mostRecentPoll) {
            const mostRecent =
              data.payload.telematicsBoxes[imei].mostRecentPoll;
            const cached = data.payload.telematicsBoxes[imei].cachedPolls || [];
            const allPolls = _.orderBy(
              _.uniqBy([mostRecent, ...cached].filter(Boolean), 'identifier'),
              'identifier'
            );

            // if the imei was just created, it won't have a mostRecentPoll
            allPolls.forEach((poll) =>
              observer.next({
                type: SOCKET_POLL_RECEIVED,
                payload: poll,
              })
            );
          }
        }
      } catch (e) {
        observer.error(e);
      }
    };

    socket.onopen = () => {
      socket.send(JSON.stringify(inputParams));
    };

    return () => {
      socket.close();
    };
  });
}

export function socketTelematicsBoxPollsEpic(action$, state$) {
  return action$.pipe(
    ofType(START_TELEMATICS_BOX_POLLS_STREAM),
    switchMap(({ payload: { imei } }) =>
      getTelematicsBoxPollsObservable(imei).pipe(
        map((message) => {
          switch (message.type) {
            case SOCKET_SUBSCRIBED:
              return { type: START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS };
            case SOCKET_POLL_RECEIVED:
              return {
                type: RECEIVE_TELEMATICS_BOX_POLL,
                payload: {
                  imei,
                  isTemp: !state$.value.telematicsBoxes.boxesByImei[imei],
                  poll: message.payload,
                },
              };
            default:
              // shouldn't happen but warning if no default
              return {
                type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
                payload: 'Unknown message from socket',
              };
          }
        }),
        takeUntil(
          action$.pipe(
            ofType(
              START_TELEMATICS_BOX_POLLS_STREAM,
              END_TELEMATICS_BOX_POLLS_STREAM
            )
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
            payload,
          })
        ),
        endWith({ type: END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS })
      )
    )
  );
}

// async function fetchTelematicsBoxAuditLogRequest() {
// const logs = await apiGet('/audits', {
// index: {
//   name: 'imeiTime',
//   values: [imei, { start, end }],
// },
// fields: [
//   'imei',
//   'time',
//   'diagnosticCode',
//   'deviceProperties',
//   'position',
//   'driver',
//   'ignitionOn',
//   ...Object.keys(window.config.dioStates),
// ],
// orderBy: { field: 'time', sort: 'desc' },
// maxResults: 200,
// }
// );

// return _.mapKeys(boxesWithVehicles, 'imei');
// }

// export function fetchTelematicsBoxAuditLog(action$) {
//   return action$.pipe(
//     ofType(FETCH_TELEMATICS_BOX_AUDIT_LOG),
//     mergeMap(({ payload: { imei, start, end } }) =>
//       from(fetchTelematicsBoxAuditLogRequest(imei)).pipe(
//         map((payload) => ({
//           type: FETCH_TELEMATICS_BOX_AUDIT_LOG_SUCCESS,
//           payload: {
//             imei,
//             polls: payload,
//           },
//         })),
//         catchError(({ message }) =>
//           of({
//             type: FETCH_TELEMATICS_BOX_AUDIT_LOG_FAILURE,
//             payload: {
//               imei,
//               message,
//             },
//           })
//         )
//       )
//     )
//   );
// }

async function updateVehicleRequest(
  { identificationNumber },
  telematicsBoxImei
) {
  const vehicle = {
    identificationNumber,
    telematicsBoxImei,
  };

  await api.patch(`/vehicles/${identificationNumber}`, vehicle, {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/merge-patch+json',
    },
  });

  log('Update', 'Vehicle', vehicle);

  return vehicle;
}

export function updateVehicleEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_VEHICLE_IMEI),
    mergeMap(({ payload: { vehicle, telematicsBoxImei } }) =>
      from(updateVehicleRequest(vehicle, telematicsBoxImei)).pipe(
        mergeMap((payload) =>
          of(
            {
              type: UPDATE_VEHICLE_IMEI_SUCCESS,
              payload,
            },
            {
              type: FETCH_TELEMATICS_BOXES,
            },
            {
              type: FETCH_VEHICLE,
              payload: vehicle.identificationNumber,
            }
          )
        ),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_VEHICLE_IMEI_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function createTelematicsBoxRequest({ redirect, ...box }) {
  await api.post('/telematicsBoxes', box, { headers: getHeaders() });
  log('Create', 'Telematics Box', box);
  if (redirect) {
    history.push(`/resources/telematicsboxes/${box.imei}`);
  }
}

async function updateTelematicsBoxRequest({ imei, ...box }) {
  await api.put(`/telematicsBoxes/${imei}`, box, { headers: getHeaders() });
  log('Update', 'Telematics Box', box);
}

async function deleteTelematicsBoxRequest(imei) {
  // TEMP only remove it if it exists on the server, we may have a
  // temporary local placeholder while we are commissioning a new one
  const exists = await doesIdExist('telematicsBoxes', imei);

  if (exists) {
    await api.delete(`/telematicsBoxes/${imei}`, { headers: getHeaders() });
    log('Delete', 'Telematics Box', imei);
  }

  history.push(`/resources/telematicsboxes`);
}

function createEpic(TYPE, requestFunction, ...args) {
  function epic(action$) {
    return action$.pipe(
      ofType(TYPE),
      mergeMap(({ payload }) =>
        from(requestFunction(payload)).pipe(
          mergeMap((payload) =>
            of(
              {
                type: TYPE + '_SUCCESS',
                payload,
              },
              ...args.map((type) => ({
                type,
              }))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: TYPE + '_FAILURE',
              payload,
            })
          )
        )
      )
    );
  }

  return epic;
}

export const createTelematicsBoxEpic = createEpic(
  CREATE_TELEMATICS_BOX,
  createTelematicsBoxRequest,
  FETCH_TELEMATICS_BOXES
);
export const updateTelematicsBoxEpic = createEpic(
  UPDATE_TELEMATICS_BOX,
  updateTelematicsBoxRequest,
  FETCH_TELEMATICS_BOXES
);
export const deleteTelematicsBoxEpic = createEpic(
  DELETE_TELEMATICS_BOX,
  deleteTelematicsBoxRequest,
  FETCH_TELEMATICS_BOXES
);

// export function createTelematicsBoxEpic(action$) {
//   return action$.pipe(
//     ofType(CREATE_TELEMATICS_BOX),
//     mergeMap(({ payload: box }) =>
//       from(createTelematicsBoxRequest(box)).pipe(
//         map((payload) => ({
//           type: CREATE_TELEMATICS_BOX_SUCCESS,
//           payload,
//         })),
//         catchError(({ message: payload }) =>
//           of({
//             type: CREATE_TELEMATICS_BOX_FAILURE,
//             payload,
//           })
//         )
//       )
//     )
//   );
// }

// When a telematics box is being subscribed to,
// get readings from the driver id grpc periodically
function createSubscribeTimerEpic(type) {
  function epic(action$) {
    return action$.pipe(
      ofType(START_TELEMATICS_BOX_POLLS_STREAM),
      mergeMap(({payload}) =>
        timer(0, 3000).pipe(
          map(() => ({
            type,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(
                START_TELEMATICS_BOX_POLLS_STREAM,
                END_TELEMATICS_BOX_POLLS_STREAM
              )
            )
          )
        )
      )
    );
  }

  return epic;
}

// certain box parameters (currently driver id and tracking) will need to be polled,
// updated and potentially have the update cancelled. The form is the same for both
// so the following functions try to abstract this instead of copy n paste
function createFetchBoxParameter(type, enabledState) {
  return async function({imei}) {
      const response = await api.get(`/telematicsBox/${imei}/${type}`, {
      params: {},
      headers: getHeaders(),
    });

    const result = response.data;

    return {
      imei,
      currentlyEnabled: result ? result.CurrentState === enabledState : undefined,
      willBeEnabled: result?.IsPending
        ? result?.TargetState === enabledState
        : undefined,
    };
  }
}


function createUpdateBoxParameter(type, enabledState) {
  return async function f({ imei, enabled }) {
    const State = enabled ? enabledState : `${_.startCase(type).replace(/\s/g, '')}Disabled`;

    const response = await api.put(
      `/telematicsBox/${imei}/${type}`,
      { State },
      {
        headers: getHeaders(),
      }
    );

    return response.data;
  }
}

function createCancelBoxParameter(type) {
  return async function f({imei}) {
      const response = await api.delete(`/telematicsBox/${imei}/${type}`, {
      headers: getHeaders(),
    });

    return response.data;
  }
}


// Update, cancel and poll regularly for driver id
const enabledDriverIdState = window.config.useDallasKeys ? 'DallasKey' : 'Rfid';
const fetchDriverId = createFetchBoxParameter('driverId', enabledDriverIdState);
const updateDriverId = createUpdateBoxParameter('driverId', enabledDriverIdState);
const cancelDriverId = createCancelBoxParameter('driverId', enabledDriverIdState);
export const fetchDriverIdEpic = createEpic(FETCH_DRIVER_ID, fetchDriverId);
export const updateDriverIdEpic = createEpic(UPDATE_DRIVER_ID, updateDriverId);
export const cancelDriverIdEpic = createEpic(CANCEL_DRIVER_ID, cancelDriverId);
export const subscribeToDriverIdEpic = createSubscribeTimerEpic(FETCH_DRIVER_ID);

// same for tracking state
const enabledTrackingState = 'TrackingEnabled';
const fetchTracking = createFetchBoxParameter('tracking', enabledTrackingState);
const updateTracking = createUpdateBoxParameter('tracking', enabledTrackingState);
const cancelTracking = createCancelBoxParameter('tracking', enabledTrackingState);
export const fetchTrackingEpic = createEpic(FETCH_TRACKING, fetchTracking);
export const updateTrackingEpic = createEpic(UPDATE_TRACKING, updateTracking);
export const cancelTrackingEpic = createEpic(CANCEL_TRACKING, cancelTracking);
export const subscribeToTrackingEpic = createSubscribeTimerEpic(FETCH_TRACKING);

// async function updateDriverId({ imei, enabled }) {
//   const State = enabled ? enabledKey : 'DriverIdDisabled';

//   const response = await api.put(
//     `/telematicsBox/${imei}/driverId`,
//     { State },
//     {
//       headers: getHeaders(),
//     }
//   );

//   return response.data;
// }
// export const updateDriverIdEpic = createEpic(UPDATE_DRIVER_ID, updateDriverId);

// async function fetchDriverId({ imei }) {
//   const response = await api.get(`/telematicsBox/${imei}/driverId`, {
//     params: {},
//     headers: getHeaders(),
//   });

//   const result = response.data;

//   return {
//     imei,
//     currentlyEnabled: result ? result.CurrentState === enabledKey : undefined,
//     willBeEnabled: result?.IsPending
//       ? result?.TargetState === enabledKey
//       : undefined,
//   };
// }
// export const fetchDriverIdEpic = createEpic(FETCH_DRIVER_ID, fetchDriverId);

// async function cancelDriverId({ imei }) {
//   const response = await api.delete(`/telematicsBox/${imei}/driverId`, {
//     headers: getHeaders(),
//   });

//   return response.data;
// }
// export const cancelDriverIdEpic = createEpic(CANCEL_DRIVER_ID, cancelDriverId);

// // When a telematics box is being subscribed to,
// // get readings from the tracking grpc periodically
// export const telematicsBoxTrackingEpic = createSubscribeTimerEpic(
//   FETCH_TRACKING
// );

// async function updateTracking({ imei, enabled }) {
//   const State = enabled ? enabledKey : 'TrackingDisabled';

//   const response = await api.put(
//     `/telematicsBox/${imei}/tracking`,
//     { State },
//     {
//       headers: getHeaders(),
//     }
//   );

//   return response.data;
// }
// export const updateTrackingEpic = createEpic(UPDATE_TRACKING, updateTracking);

// async function fetchTracking({ imei }) {
//   const response = await api.get(`/telematicsBox/${imei}/tracking`, {
//     params: {},
//     headers: getHeaders(),
//   });

//   const result = response.data;

//   return {
//     imei,
//     currentlyEnabled: result ? result.CurrentState === enabledKey : undefined,
//     willBeEnabled: result?.IsPending
//       ? result?.TargetState === enabledKey
//       : undefined,
//   };
// }
// export const fetchTrackingEpic = createEpic(FETCH_TRACKING, fetchTracking);

// async function cancelTracking({ imei }) {
//   const response = await api.delete(`/telematicsBox/${imei}/tracking`, {
//     headers: getHeaders(),
//   });

//   return response.data;
// }
// export const cancelTrackingEpic = createEpic(CANCEL_TRACKING, cancelTracking);

export function reactToVehicleUpdatesEpic(action$) {
  return action$.pipe(
    ofType(CREATE_VEHICLE_SUCCESS, UPDATE_VEHICLE_SUCCESS),
    map(() => ({ type: FETCH_TELEMATICS_BOXES }))
  );
}

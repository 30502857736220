import React, {
  useState,
  useContext,
  useRef,
  createContext,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  TextField as MuiTextField,
  makeStyles,
} from '@material-ui/core';
import { PhotoCamera as CameraIcon } from '@material-ui/icons';
import { red, green } from '@material-ui/core/colors';
import { Form } from 'react-final-form';
import Webcam from 'react-webcam';
import _ from 'lodash';
import InputsSection from './InputsSection';
import { DriverIdTest, DriverIdActions } from './DriverIDSection';
import { Field, TextField, SelectField, required } from '../../fields';
import {
  START_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM,
  CREATE_TELEMATICS_BOX,
  DELETE_TELEMATICS_BOX,
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
} from '../../../actions';
import { checkLuhn, log, doesIdExist } from '../../../apis/utilities';
import { usePrevious } from '../../../hooks';
import VehicleKeyFields from '../VehicleKeyFields';

const {
  dioOptions: { manualPolls, showDriverId = true },
} = window.config;

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noteAndKeyFields: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  locationFields: {
    display: 'flex',
    alignItems: 'center',
  },
  vinText: {
    textTransform: 'uppercase',
  },
  stepper: {
    padding: 16,
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  positiveButton: {
    color: theme.palette.primary.main,
    width: '150px',
  },
  negativeButton: {
    color: red[500],
    width: '150px',
  },
  passButton: {
    color: green[500],
    width: '150px',
  },
  camera: {
    width: '100%',
    height: '100%',
  },
  notes: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
}));

function imeiErrors(value) {
  if (value?.length !== 15) {
    return 'Incorrect length';
  }

  if (!checkLuhn(value)) {
    return 'Invalid IMEI';
  }

  return false;
}

const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const NO_DRIVER_ID = 'Not installed';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
};

function Camera({ input: { onChange, value } }) {
  const classes = useStyles();

  const [image, setImage] = useState(value);
  const cameraRef = useRef();

  const captureBoxCam = React.useCallback(() => {
    let image = cameraRef.current.getScreenshot();
    setImage(image);
    if (onChange) {
      onChange(image);
    }
  }, [cameraRef, onChange]);

  function retake() {
    setImage(null);
  }

  return (
    <div>
      {image ? (
        <img className={classes.camera} src={image} alt="device location" />
      ) : (
        <Webcam
          className={classes.camera}
          audio={false}
          // height={720}
          ref={cameraRef}
          screenshotFormat="image/jpeg"
          // width={1280}
          videoConstraints={videoConstraints}
        />
      )}
      <div className={classes.buttonContainer}>
        <Button
          onClick={captureBoxCam}
          disabled={!!image}
          className={classes.positiveButton}
        >
          Capture
        </Button>
        <Button
          onClick={retake}
          disabled={!image}
          className={classes.negativeButton}
        >
          retake
        </Button>
      </div>
    </div>
  );
}

export default function CommissioningDialog({
  open,
  onClose,
  box: initialBox,
  vehicle: initialVehicle,
  redirect
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [box, setBox] = useState(initialBox);
  const [vehicle, setVehicle] = useState(initialVehicle);
  const [driverIdInstalled, setDriverIdInstalled] = useState(true);

  const [inputTestResults, setInputTestResults] = useState({});
  const [inputTestComplete, setInputTestComplete] = useState(false);

  const [driverIdActionsResults, setDriverIdActionsResults] = useState({});
  const [driverIdActionsComplete, setDriverIdActionsComplete] = useState(true);

  const [driverIdTestResults, setDriverIdTestResults] = useState({});
  const [driverIdTestComplete, setDriverIdTestComplete] = useState(false);

  const [installationNotes, setInstallationNotes] = useState('');

  const [showTelematicsCamera, setShowTelematicsCamera] = useState(false);
  const [showDriverIdCamera, setShowDriverIdCamera] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const prevInitialBox = usePrevious(initialBox);
  const prevInitialVehicle = usePrevious(initialVehicle);

  const vehiclesById = useSelector(
    (state) => state.telematicsBoxes.vehiclesById
  );
  const prevImeiRef = useRef();
  async function imeiExists(imei) {
    const exists = await doesIdExist('telematicsBoxes', imei); // boxesByImei[imei];

    // only log it once
    if (exists && prevImeiRef.current !== imei) {
      log('Create', 'Telematics Box Commission', {
        imei,
        warning: 'Attempt to commission a box that already exists',
      });
    }

    prevImeiRef.current = imei;

    return exists ? 'IMEI already exists' : undefined;
  }

  useEffect(() => {
    if (
      initialBox !== prevInitialBox ||
      initialVehicle !== prevInitialVehicle
    ) {
      setBox(initialBox);
      setVehicle(initialVehicle);
    }
  }, [initialBox, initialVehicle, prevInitialBox, prevInitialVehicle]);

  const prevBox = usePrevious(box);
  useEffect(() => {
    if (box?.imei && prevBox?.imei !== box.imei) {
      dispatch({
        type: START_TELEMATICS_BOX_POLLS_STREAM,
        payload: { imei: box.imei },
      });
    }
  }, [prevBox, box, dispatch]);

  function completeTelematicsStep(values) {
    setBox(values);
    next();
  }

  function completeVehicleStep(vehicle) {
    setDriverIdInstalled(vehicle.driverIdLocation !== NO_DRIVER_ID);
    setVehicle(vehicle);
    next();
  }

  function validateVehicle(vehicle) {
    const errors = {};

    const vehiclesImei = vehiclesById?.[vehicle?.identificationNumber]?.telematicsBoxImei;
    const commissioningImei = (box || initialBox).imei;

    if (!!vehiclesImei && vehiclesImei !== commissioningImei) {
      errors.identificationNumber =
        'Vehicle already has telematics box associated';
    }

    return errors;
  }

  function completeInputsStep(complete, results) {
    setInputTestComplete(complete);
    setInputTestResults(results);
  }

  // function restartInputTest() {
  //   setInputTestComplete(false);
  //   setInputTestResults({});
  //   setInputTestStart(new Date());
  // }

  function completeDriverIdTestStep(complete, results) {
    setDriverIdTestComplete(complete);
    setDriverIdTestResults(results);
  }

  function completeDriverIdActionsStep(complete, results) {
    setDriverIdActionsComplete(complete);
    setDriverIdActionsResults(results);
  }

  function back() {
    setActiveStep((s) => Math.max(s - 1, 0));
  }

  function next() {
    setActiveStep((s) => s + 1);
  }

  async function completeCommission() {
    let exists = false;

    if (vehicle?.identificationNumber) {
      exists = await doesIdExist('vehicles', vehicle.identificationNumber);
    }
    
    const type = initialVehicle || exists ? UPDATE_VEHICLE : CREATE_VEHICLE;
    const vehiclePayload = {
      ...(_.pick(initialVehicle || vehicle, [
        'identificationNumber',
        'driverIdLocation',
        'driverIdLocationImage',
        'driverIdLocationOther',
        'installLocation',
        'installLocationImage',
        'installLocationOther',
        'fleetNumber',
        'type',
        'registrationNumber'
      ])),
      telematicsBoxImei: (initialBox || box).imei,
      installationNotes,
      ...(type === CREATE_VEHICLE? { redirect: false } : {}),
    };

    log('Create', 'Telematics Box Commission', {
      vehicle: vehiclePayload,
      telematicsBox: box,
      inputTestResults,
      driverIdActionsResults,
      driverIdTestResults,
      installationNotes,
    });

    dispatch({
      type,
      payload: vehiclePayload,
    });

    if (!initialBox) {
      dispatch({
        type: CREATE_TELEMATICS_BOX,
        payload: {
          imei: box.imei,
          // make & model of box (huf/astra) for later...
          redirect: (redirect === undefined ? true : redirect)
        }
      });
    }

    onClose && onClose();
  }

  function handleCancel() {
    setBox(initialBox);
    setVehicle(initialVehicle);
    setActiveStep(0);

    // if there was no initial box & we created a placeholder to get polls
    // remove it
    if (!initialBox && box?.imei) {
      // stop listening to this box
      dispatch({
        type: END_TELEMATICS_BOX_POLLS_STREAM,
        payload: box.imei,
      });

      // get rid of it too (will refresh the full list)
      dispatch({
        type: DELETE_TELEMATICS_BOX,
        payload: box.imei,
      });
    }

    onClose && onClose();
  }

  const devSkip = manualPolls ? (
    <Button onClick={next} className={classes.passButton}>
      Pass
    </Button>
  ) : (
    <></>
  );

  const steps = [
    (
      <Step>
        <StepLabel>Enter Telematics Box Information</StepLabel>
        <StepContent>
          <Form
            initialValues={box}
            onSubmit={completeTelematicsStep}
            render={({ handleSubmit, invalid, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="imei"
                    className={classes.textField}
                    label="IMEI"
                    component={TextField}
                    disabled={!!initialBox}
                    validate={composeValidators(
                      required,
                      imeiErrors,
                      initialBox ? ()=>{} : imeiExists
                    )}
                  />
                  <Field
                    name="imsi"
                    className={classes.textField}
                    label="IMSI"
                    component={TextField}
                  />
                  {/* <Field
                    name="make"
                    className={classes.textField}
                    label="Make"
                    component={SelectField}
                    values={[
                      { label: 'Huf', value: 'huf' },
                      { label: 'Astra', value: 'astra' },
                    ]}
                  />
                  <Field
                    name="model"
                    className={classes.textField}
                    label="Model"
                    component={SelectField}
                    values={[
                      { label: 'A', value: 'a' },
                      { label: 'B', value: 'b' },
                    ]}
                  />
                  <Field
                    name="hardwareVersion"
                    className={classes.textField}
                    label="Hardware Version"
                    component={SelectField}
                    values={[
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                    ]}
                  /> */}
                  <div className={classes.buttonContainer}>
                    <Button
                      type="submit"
                      className={classes.positiveButton}
                      disabled={submitting}
                    >
                      Next
                    </Button>
                    {devSkip}
                  </div>
                </form>
              );
            }}
          ></Form>
        </StepContent>
      </Step>
    ),
    !initialVehicle && (
      <Step>
        <StepLabel>Enter Vehicle Information</StepLabel>
        <StepContent>
          <Form
            // I need vehicle if they edit and come back, box if there's no
            // initialVehicle
            initialValues={vehicle || box}
            onSubmit={completeVehicleStep}
            validate={validateVehicle}
            render={({ handleSubmit, invalid, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className={classes.noteAndKeyFields}>
                    {!!box?.identificationNumber && (
                      <div>
                        Vehicle already associated; if commissioning a new
                        vehicle, cancel and remove the association first
                      </div>
                    )}
                    <div className={classes.section}>
                      <VehicleKeyFields
                        classes={classes}
                        vehicle={box}
                        disabled={!!box?.identificationNumber}
                      />
                    </div>
                  </div>
                  {/* TODO refactor these 3 locationContainers into a component */}
                  <div className={classes.locationContainer}>
                    <div className={classes.locationFields}>
                      <Field
                        name="installLocation"
                        values={[
                          'Under the driver seat',
                          'Under passenger seat',
                          'Other',
                        ].map((value) => ({ label: value, value }))}
                        className={classes.textField}
                        label="Telematics box location"
                        component={SelectField}
                      />
                      <Condition when="installLocation" is="Other">
                        <Field
                          name="installLocationOther"
                          className={classes.textField}
                          label="Other box location"
                          component={TextField}
                        />
                      </Condition>
                      <IconButton
                        onClick={() => setShowTelematicsCamera((s) => !s)}
                      >
                        <CameraIcon />
                      </IconButton>
                    </div>
                    <div>
                      {showTelematicsCamera && (
                        <Field name="installLocationImage" component={Camera} />
                      )}
                    </div>
                  </div>
                  {showDriverId && <div className={classes.locationContainer}>
                    <div className={classes.locationFields}>
                      <Field
                        name="driverIdLocation"
                        values={[
                          'Beside cigarette lighter',
                          'Right of centre console',
                          'Other',
                          NO_DRIVER_ID,
                        ].map((value) => ({ label: value, value }))}
                        className={classes.textField}
                        label="Driver ID location"
                        component={SelectField}
                      />
                      <Condition when="driverIdLocation" is="Other">
                        <Field
                          name="driverIdLocationOther"
                          className={classes.textField}
                          label="Other ID location"
                          component={TextField}
                        />
                      </Condition>
                      <IconButton
                        onClick={() => setShowDriverIdCamera((s) => !s)}
                      >
                        <CameraIcon />
                      </IconButton>
                    </div>
                    <div>
                      {showDriverIdCamera && (
                        <Field
                          name="driverIdLocationImage"
                          component={Camera}
                        />
                      )}
                    </div>
                  </div>}
                  <div className={classes.locationContainer}>
                    <div className={classes.locationFields}>
                      <Field
                        name="aerialLocation"
                        values={[
                          "Driver's windscreen",
                          "Passenger's windscreen",
                          "Under driver's dash",
                          "Under passenger's dash",
                          'Other'
                        ].map((value) => ({ label: value, value }))}
                        className={classes.textField}
                        label="Aerial location"
                        component={SelectField}
                      />
                      <Condition when="aerialLocation" is="Other">
                        <Field
                          name="aerialLocationOther"
                          className={classes.textField}
                          label="Other location"
                          component={TextField}
                        />
                      </Condition>
                      <IconButton
                        onClick={() => setShowDriverIdCamera((s) => !s)}
                      >
                        <CameraIcon />
                      </IconButton>
                    </div>
                    <div>
                      {showDriverIdCamera && (
                        <Field
                          name="driverIdLocationImage"
                          component={Camera}
                        />
                      )}
                    </div>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button onClick={back} className={classes.positiveButton}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      className={classes.positiveButton}
                      disabled={submitting}
                    >
                      Next
                    </Button>
                    {devSkip}
                  </div>
                </form>
              );
            }}
          ></Form>
        </StepContent>
      </Step>
    ),
    <Step>
      <StepLabel>Perform Input Test</StepLabel>
      <StepContent>
        <InputsSection
          onCompleteChanged={completeInputsStep}
          imei={box?.imei}
        />
        <div className={classes.buttonContainer}>
          <Button className={classes.positiveButton} onClick={back}>
            Back
          </Button>
          <Button
            className={classes.positiveButton}
            disabled={!inputTestComplete}
            onClick={next}
          >
            Next
          </Button>
          {devSkip}
        </div>
      </StepContent>
    </Step>,
    showDriverId && driverIdInstalled && (
      <Step>
        <StepLabel>Set Driver ID</StepLabel>
        <StepContent>
          <DriverIdActions
            onCompleteChanged={completeDriverIdActionsStep}
            imei={box?.imei}
            cancelPending={true}
          />
          <div className={classes.buttonContainer}>
            <Button className={classes.positiveButton} onClick={back}>
              Back
            </Button>
            <Button
              type="submit"
              className={classes.positiveButton}
              disabled={!driverIdActionsComplete}
              onClick={next}
            >
              Next
            </Button>
            {devSkip}
          </div>
        </StepContent>
      </Step>
    ),
    showDriverId && driverIdInstalled && (
      <Step>
        <StepLabel>Test Driver ID</StepLabel>
        <StepContent>
          <DriverIdTest
            onCompleteChanged={completeDriverIdTestStep}
            imei={box?.imei}
            cancelPending={true}
          />
          <div className={classes.buttonContainer}>
            <Button className={classes.positiveButton} onClick={back}>
              Back
            </Button>
            <Button
              type="submit"
              className={classes.positiveButton}
              disabled={!driverIdTestComplete}
              onClick={next}
            >
              Next
            </Button>
            {devSkip}
          </div>
        </StepContent>
      </Step>
    ),
    <Step>
      <StepLabel>Notes</StepLabel>
      <StepContent>
        <div className={classes.notes}>
          <MuiTextField
            label="Optionally add notes..."
            multiline
            value={installationNotes}
            onChange={(e) => setInstallationNotes(e.target.value)}
            fullWidth
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button className={classes.positiveButton} onClick={back}>
            Back
          </Button>
          <Button className={classes.positiveButton} onClick={next}>
            Next
          </Button>
          {devSkip}
        </div>
      </StepContent>
    </Step>,
  ].filter(Boolean);

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth={'md'}>
        <DialogTitle id="alert-dialog-title">{`Commissioning ${
          initialBox?.imei || 'new telematics box'
        }`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter information and perform hardware tests
          </DialogContentText>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            children={React.Children.toArray(steps)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={completeCommission}
            color="primary"
            autoFocus
            disabled={activeStep !== steps.length}
            className={classes.positiveButton}
          >
            Complete
          </Button>
          <Button
            onClick={() => setConfirmCancel(true)}
            className={classes.negativeButton}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmCancel}
        onClose={() => setConfirmCancel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Cancel?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to cancel commissioning?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => setConfirmCancel(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const CommissioningDialogContext = createContext();
export const useCommissioningDialog = () =>
  useRef(useContext(CommissioningDialogContext)).current;

export function CommissioningDialogProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [box, setBox] = useState();
  const [redirect, setRedirect] = useState();

  function show({ vehicle, box, redirect } = {}) {
    setVehicle(vehicle);
    setBox(box);
    setRedirect(redirect);
    setOpen(true);
  }

  function hide() {
    setOpen(false);
  }

  return (
    <CommissioningDialogContext.Provider
      value={{
        show,
        vehicle,
        box,
      }}
    >
      {children}
      <CommissioningDialog
        open={open}
        onClose={hide}
        box={box}
        vehicle={vehicle}
        redirect={redirect}
        key={new Date()}
      />
    </CommissioningDialogContext.Provider>
  );
}

import {
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  Label as LabelIcon,
  Person as PersonIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PEOPLE,
  FETCH_TRIPS,
  FETCH_TRIPS_CANCELLED,
  UPDATE_TRIPS_FILTER,
  UPDATE_TRIPS_QUERY,
  UPDATE_TRIP_CLASSIFICATION,
  UPDATE_TRIP_DRIVER,
} from '../../../actions';
import {
  downloadCSV,
  getPerson,
  longPersonVehicleHeaders,
  tripHeaders,
} from '../../../apis/utilities';
import Container from '../../Container';
import {
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../../controls';
import { ClassificationDialog, PersonDialog } from '../../dialogs';
import { useSnackbar } from '../../Snackbar';
import Classification from './Classification';
import DownloadPollsLink from './DownloadPollsLink';
import ReplayLink from './ReplayLink';
import { filterLocally } from '../../../data/utilities';

const {
  useReducedResourceInformation,
  useDallasKeys,
  allowDriverChange,
  rowsPerPageOptions,
} = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },

  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Type',
          key: 'vehicle.type',
          type: 'text',
          filter: true,
          // Footer: `${filteredTrips.length} ${
          //   filteredTrips.length === 1 ? 'trip' : 'trips'
          // }`,
        },
        {
          label: 'Staff ID',
          key: 'driver.code',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
      ]
    : [
        {
          label: 'Registration',
          key: 'vehicle.registrationNumber',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
          // Footer: `${filteredTrips.length} ${
          //   filteredTrips.length === 1 ? 'trip' : 'trips'
          // }`,
        },
        { label: 'Role', key: 'vehicle.role', type: 'text', filter: true },
        {
          label: 'Type',
          key: 'vehicle.type',
          type: 'text',
          filter: true,
          // Footer: `${filteredTrips.length} ${
          //   filteredTrips.length === 1 ? 'trip' : 'trips'
          // }`,
        },
        {
          label: 'Forenames',
          key: 'driver.forenames',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Surname',
          key: 'driver.surname',
          type: 'text',
          filter: true,
          // Footer: 'Totals',
        },
        {
          label: 'Collar Number',
          key: 'driver.collarNumber',
          type: 'text',
          filter: true,
          // Footer: `${filteredTrips.length} ${
          //   filteredTrips.length === 1 ? 'trip' : 'trips'
          // }`,
        },
        {
          label: 'Driver Role',
          key: 'driver.role',
          type: 'text',
          filter: true,
        },
      ]),
  {
    label: useDallasKeys ? 'Dallas Key' : 'RFID',
    key: 'driver.identificationReference',
    type: 'text',
    filter: true,
  },
  {
    label: 'IMEI',
    key: 'vehicle.telematicsBoxImei',
    type: 'text',
    filter: true,
  },
  {
    label: 'Classification',
    key: 'classification',
    type: 'component',
    filter: true,
    component: Classification,
    // Footer: useReducedResourceInformation
    //   ? `${filteredTrips.length} ${
    //       filteredTrips.length === 1 ? 'trip' : 'trips'
    //     }`
    //   : '',
  },
  {
    label: 'Start Location Type',
    key: 'startLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Location Name',
    key: 'startLocationName',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Type',
    key: 'endLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Name',
    key: 'endLocationName',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Time',
    key: 'startTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'End Time',
    key: 'endTime',
    type: 'date',
    filter: false,
  },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
    // Footer: _.round(
    //   filteredTrips
    //     .map((trip) => trip.durationMinutes)
    //     .reduce((a, b) => a + b, 0) || 0,
    //   2
    // ).toString(),
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
    filter: false,
    // Footer: _.round(
    //   filteredTrips
    //     .map((trip) => trip.distanceMiles)
    //     .reduce((a, b) => a + b, 0) || 0.0,
    //   2
    // ).toString(),
  },
  {
    label: 'Maximum Speed (mph)',
    key: 'maxSpeedMilesPerHour',
    type: 'number',
    filter: false,
    // Footer: _.round(
    //   Math.max(
    //     ...filteredTrips.map((trip) => trip.maxSpeedMilesPerHour).concat(0)
    //   ),
    //   2
    // ).toString(),
  },
  {
    label: '',
    key: 'replay',
    type: 'component',
    filter: false,
    component: ReplayLink,
  },
  {
    label: '',
    key: 'polls',
    type: 'component',
    filter: false,
    component: DownloadPollsLink,
  },
];

export default function Trips() {
  const dispatch = useDispatch();
  const people = useSelector((state) => state.people.people, _.isEqual);
  const trips = useSelector((state) => state.events.trips.list, _.isEqual);
  const isLoading = useSelector((state) => state.events.trips.isLoading);
  const error = useSelector((state) => state.events.trips.error);
  const filter = useSelector((state) => state.events.trips.filter, _.isEqual);
  const query = useSelector((state) => state.events.trips.query, _.isEqual);
  const [classificationOpen, setClassificationOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    dispatch({
      type: FETCH_PEOPLE,
    });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_TRIPS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_TRIPS_CANCELLED,
    });
  }

  function handleClassificationClick() {
    setClassificationOpen(true);
  }

  function handleClassificationClose(classification) {
    if (classification) {
      filter.selectedIdentifiers.forEach((tripId) =>
        dispatch({
          type: UPDATE_TRIP_CLASSIFICATION,
          payload: { id: tripId, classification },
        })
      );
    }
    setClassificationOpen(false);
  }

  function handleDriverClick() {
    setDriverOpen(true);
  }

  async function handleDriverClose(driverId) {
    if (driverId) {
      const driver = await getPerson(driverId);

      filter.selectedIdentifiers.forEach((tripId) =>
        dispatch({
          type: UPDATE_TRIP_DRIVER,
          payload: {
            id: tripId,
            driver: { assigned: true, ...driver },
          },
        })
      );
    }
    setDriverOpen(false);
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_TRIPS_FILTER,
      payload,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      updateFilter({
        selectedIdentifiers: filterLocally(filter, trips).map(
          (n) => n.identifier
        ),
      });
    } else {
      updateFilter({
        selectedIdentifiers: [],
      });
    }
  }

  function handleSelectClick(id) {
    const index = filter.selectedIdentifiers.indexOf(id);

    if (index === -1) {
      updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers.concat(id),
      });
    } else {
      updateFilter({
        selectedIdentifiers: filter.selectedIdentifiers
          .slice(0, index)
          .concat(filter.selectedIdentifiers.slice(index + 1)),
      });
    }
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_TRIPS_QUERY,
      payload: query,
    });
  }

  const vehicleTripsHeaders = [...longPersonVehicleHeaders, ...tripHeaders];

  const filteredTrips = filterLocally(filter, trips);

  function handleDownloadClick() {
    const filename = 'Vehicle Trips.csv';
    const data = filteredTrips.map((trip) => ({
      ...trip,
      staffId: trip.driver.code,
      registrationNumber: trip.vehicle.registrationNumber,
      fleetNumber: trip.vehicle.fleetNumber,
      type: trip.vehicle.type,
      name: trip.driver.forenames + ' ' + trip.driver.surname,
      collarNumber: trip.driver.collarNumber,
      rfidCards: trip.driver.identificationReference,
      imei: trip.vehicle.telematicsBoxImei,
      startTime: new Date(trip.startTime),
      endTime: new Date(trip.endTime),
      durationMinutes: _.round(trip.durationMinutes || 0.0, 2),
      distanceMiles: _.round(trip.distanceMiles || 0.0, 2),
      maxSpeedMilesPerHour: _.round(trip.maxSpeedMilesPerHour || 0.0, 2),
    }));

    downloadCSV(data, filename, vehicleTripsHeaders);
  }

  return (
    <Container title="Trips">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        vehicle
        driver
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Trips</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Trips
          </Typography>
          {filter.selectedIdentifiers.length > 0 && (
            <Fragment>
              <Typography>
                {filter.selectedIdentifiers.length} selected
              </Typography>
              <IconButton
                title="Classification"
                onClick={handleClassificationClick}
              >
                <LabelIcon />
              </IconButton>
              {allowDriverChange && (
                <IconButton title="Driver" onClick={handleDriverClick}>
                  <PersonIcon />
                </IconButton>
              )}
            </Fragment>
          )}
          <FilterPicker
            headers={headers}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredTrips.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            selectMode="multi"
            classes={classes}
            data={filteredTrips}
            headers={headers}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            keyName="identifier"
            selectedKeys={filter.selectedIdentifiers}
            onSelectAllClick={handleSelectAllClick}
            onSelectClick={handleSelectClick}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          <ClassificationDialog
            open={classificationOpen}
            onClose={handleClassificationClose}
          />
          <PersonDialog
            open={driverOpen}
            onClose={handleDriverClose}
            people={people}
          />
        </Paper>
      </div>
    </Container>
  );
}

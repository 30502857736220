import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  Typography,
  IconButton,
} from '@material-ui/core';
import { LocationSearching as FollowIcon } from '@material-ui/icons';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import avatarForItem from './avatarUtility';
import VehicleLiveListItem from './VehicleLiveListItem';
import { areasToLabelAccessors, ItemRows, toLabelAccessors } from './ItemRows';

const { useReducedResourceInformation } = window.config;
// example object
// $type: "add"
// eventType: "ACCELEROMETERALERT"
// identifier: "ACCELEROMETERALERT-2018-05-17T13:50:35.000Z-HA0812"
// locations: Array(1)
// 0: {code: "OS_WMIDQNT", name: "West Mids RCC & Quinton Outstation", type: "Base"}
// people: Array(0)
// length: 0
// point:
// $reql_type$: "GEOMETRY"
// coordinates: (2) [-2.009744, 52.453684]
// type: "Point"
// searchString: "accelerometeralert+accelerometeralert-2018-05-17t13:50:35.000z-ha0812+os_wmidqnt+west mids rcc & quinton outstation+base++geometry++point+2018-05-17t13:50:35.000z+west midlands+region+ha0812+west mids rcc & quinton outstation+ha0812+bw17 ztf+traffic officer+359394057295853+add"
// tagChanged: true
// time: "2018-05-17T13:50:35.000Z"
// vehicle:
// areas: Array(1)
// 0: {name: "West Midlands", type: "region"}
// fleetNumber: "HA0812"
// homeStation: "West Mids RCC & Quinton Outstation"
// identificationNumber: "HA0812"
// registrationNumber: "BW17 ZTF"
// role: "Traffic Officer"
// telematicsBoxImei: "359394057295853"

function EventLiveItem({
  item,
  classes,
  onSubItemClick,
  onSubItemHover,
  onFollowToggle,
  followedIdsByType,
  hoveredId,
}) {
  const type = 'events';
  const following = followedIdsByType?.[type]?.[item.id];
  const hideFollow = !item.point;

  const handleFollowToggle = () => {
    onFollowToggle(type, item.id);
  };

  // find associated vehicle
  const vehicles = useSelector((state) => state.live.vehicles);
  const vehicle = vehicles[item.vehicle.identificationNumber];

  const sections = {
    details: [
      {
        label: 'Event time',
        value: moment(item.time).format('DD/MM/YYYY HH:mm:ss'),
      },
      { label: 'Type', value: item.eventType },
      ...toLabelAccessors(item.locations, 'type', 'name'),
    ],
    vehicle: [
      { label: 'Fleet Number', value: item.vehicle.fleetNumber },
      ...(useReducedResourceInformation
        ? [{ label: 'Home Location', value: item.vehicle.homeStation }]
        : [
            { label: 'Registration', value: item.vehicle.registrationNumber },
            { label: 'Role', value: item.vehicle.role },
            { label: 'Home Station', value: item.vehicle.homeStation },
            {
              label: 'Identification Number',
              value: item.vehicle.identificationNumber,
            },
          ]),
      { label: 'IMEI', value: item.vehicle.telematicsBoxImei },
      ...areasToLabelAccessors(item.vehicle.areas),
    ],
  };

  return (
    <Card className={classes.card}>
      <Helmet>
        <title>{`IR3 | Live | Events | ${item.identifier}`}</title>
      </Helmet>
      <CardHeader
        avatar={avatarForItem(item, 'events')}
        title={
          useReducedResourceInformation
            ? item.vehicle.fleetNumber
            : item.vehicle.registrationNumber
        }
        subheader={moment(item.time).format('DD/MM/YYYY hh:mm')}
        action={
          <>
            {!hideFollow && (
              <IconButton
                aria-label="Toggle follow event"
                onClick={handleFollowToggle}
                title="Toggle follow event"
              >
                <FollowIcon
                  fontSize="small"
                  color={following ? 'primary' : 'disabled'}
                />
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" className={classes.table}>
          <TableBody>{ItemRows(sections.details)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        {vehicle ? (
          <VehicleLiveListItem
            key={vehicle.id}
            onClick={onSubItemClick}
            highlighted={hoveredId === vehicle.id}
            onHoverItem={onSubItemHover}
            onFollowToggle={onFollowToggle}
            followedIdsByType={followedIdsByType}
            classes={classes}
            item={vehicle}
            hideLive={false}
          />
        ) : (
          <Table size="small" className={classes.table}>
            <TableBody>{ItemRows(sections.vehicle)}</TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

export default EventLiveItem;

import React from 'react';
import {
  makeStyles,
  Avatar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import { GpsFixed as TrackingIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_TRACKING,
  CANCEL_TRACKING,
} from '../../../actions';
import { log } from '../../../apis/utilities';
import { useAuth } from '../../Auth';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 600,
    minWidth: 466,
  },
  negativeButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: 'white',
    width: '150px',
  },
  positiveButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: 'white',
    width: '150px',
  },
  enabledTracking: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  disabledTracking: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.disabled,
  },
  listItem: {
    height: 56,
  },
}));


export function TrackingStatus({
  imei,
  onCancel,
  onEnable,
  onDisable,
  pendingMessage,
}) {
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useAuth();

  const canEdit = auth.getAuthorisation()?.commissioning?.write;

  const {
    trackingEnabled: currentlyEnabled,
    trackingWillBeEnabled: willBeEnabled,
    trackingRequest,
  } = box || {};
  let text = '<unknown>';
  let pendingText = '';
  let allowCancel = true;
  let undefinedTracking = false;

  if (typeof currentlyEnabled !== 'undefined') {
    if (currentlyEnabled) {
      text = 'Enabled';
    } else {
      text = 'Disabled';
    }
  } else {
    undefinedTracking = true;
  }

  if (typeof willBeEnabled !== 'undefined') {
    pendingText =
      `${willBeEnabled ? 'En' : 'Dis'}able pending, ` +
      (pendingMessage || `action will complete on next ignition off...`);
  } else {
    allowCancel = false;
  }

  if (trackingRequest) {
    pendingText = 'Requesting...';

    allowCancel = false;
  }

  function enableTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Enable' });

    onEnable && onEnable();

    dispatch({
      type: UPDATE_TRACKING,
      payload: {
        imei,
        enabled: true,
      },
    });
  }

  function disableTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Disable' });

    onDisable && onDisable();

    dispatch({
      type: UPDATE_TRACKING,
      payload: {
        imei,
        enabled: false,
      },
    });
  }

  function cancelTracking() {
    log('Update', 'Telematics Tracking', { imei, request: 'Cancel' });

    onCancel && onCancel();

    dispatch({
      type: CANCEL_TRACKING,
      payload: {
        imei,
      },
    });
  }

  return (
    <div>
      {undefinedTracking ? (
        <List>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemAvatar>
              <Avatar>
                {<Skeleton variant="circle" width="100%" height="100%" />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Unknown state`}>
              <Skeleton height={20} width="100%" />
            </ListItemText>
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemAvatar>
              <Avatar
                className={
                  currentlyEnabled
                    ? classes.enabledTracking
                    : classes.disabledTracking
                }
              >
                <TrackingIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${text}`} secondary={pendingText} />
          </ListItem>
        </List>
      )}
      {canEdit && (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.positiveButton}
            variant="contained"
            // disabled={!allowEnable} // should always be enabled in case reapply needed
            onClick={() => enableTracking()}
          >
            Enable
          </Button>
          <Button
            variant="contained"
            className={classes.positiveButton}
            // disabled={!allowDisable} // should always be enabled in case reapply needed
            onClick={() => disableTracking()}
          >
            Disable
          </Button>
          <Button
            variant="contained"
            className={classes.negativeButton}
            disabled={!allowCancel}
            onClick={() => cancelTracking()}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}
import {
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FETCH_VEHICLE_LOCATION_VISITS,
  FETCH_VEHICLE_LOCATION_VISITS_CANCELLED,
  UPDATE_VEHICLE_LOCATION_VISITS_FILTER,
  UPDATE_VEHICLE_LOCATION_VISITS_QUERY,
} from '../../actions';
import {
  downloadCSV,
  getVehiclePolls,
  shortVehicleHeaders,
  telematicsBoxPollHeaders,
} from '../../apis/utilities';
import Container from '../Container';
import {
  ExportPicker,
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../controls';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Type', key: 'vehicleType', type: 'text', filter: true },
      ]
    : [
        {
          label: 'Registration',
          key: 'vehicle.registrationNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Fleet Number',
          key: 'vehicle.fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Role', key: 'vehicle.role', type: 'text', filter: true },
        { label: 'Type', key: 'vehicle.type', type: 'text', filter: true },
      ]),
  { label: 'Location Type', key: 'location.type', type: 'text', filter: true },
  {
    label: 'Location Subtype',
    key: 'location.subtype',
    type: 'text',
    filter: true,
  },
  { label: 'Location Name', key: 'location.name', type: 'text', filter: true },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
    filter: false,
  },
];

function DownloadVehiclePollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      entry.vehicle.telematicsBoxImei,
      entry.startTime,
      entry.endTime
    );
    const data = polls.map((poll) => ({
      ...poll,
      identificationNumber: entry.vehicle.identificationNumber,
      registrationNumber: entry.vehicle.registrationNumber,
      fleetNumber: entry.vehicle.fleetNumber,
      time: new Date(poll.time),
      vehicleRole: entry.vehicle.role,
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      title="download"
      fetchDataToConvert={fetchDataToConvert}
      filename="Vehicle Location Visits"
      headers={[...shortVehicleHeaders, ...telematicsBoxPollHeaders]}
    />
  );
}

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/intersections/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const downloadColumn = {
  label: '',
  key: 'download',
  type: 'component',
  component: DownloadVehiclePollsLink,
};

export default function VehicleLocationVisits() {
  const dispatch = useDispatch();
  const visits = useSelector(
    (state) => state.events.vehicleLocationVisits.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.vehicleLocationVisits.isLoading
  );
  const error = useSelector(
    (state) => state.events.vehicleLocationVisits.error
  );
  const filter = useSelector(
    (state) => state.events.vehicleLocationVisits.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.vehicleLocationVisits.query,
    _.isEqual
  );
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_VEHICLE_LOCATION_VISITS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_VEHICLE_LOCATION_VISITS_CANCELLED,
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_VEHICLE_LOCATION_VISITS_QUERY,
      payload: query,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_VEHICLE_LOCATION_VISITS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  const filteredVisits = filterLocally(filter, visits);

  function handleDownloadClick() {
    const filename = 'Vehicle Location Visits.csv';
    const data = filteredVisits.map((visit) => ({
      ...visit,
      'vehicle.fleetNumber': visit.vehicle.fleetNumber,
      'vehicle.type': visit.vehicle.type,
      'vehicle.registrationNumber': visit.vehicle.registrationNumber,
      'vehicle.role': visit.vehicle.role,
      'location.type': visit.location.type,
      'location.subtype': visit.location.subtype,
      'location.name': visit.location.name,
      startTime: new Date(visit.startTime),
      endTime: new Date(visit.endTime),
      durationMinutes: _.round(visit.durationMinutes || 0.0, 2),
      distanceMiles: _.round(visit.distanceMiles || 0.0, 2),
    }));

    downloadCSV(data, filename, headers);
  }

  return (
    <Container title="Vehicle Location Visits">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        vehicle
        location
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Vehicle Location Visits</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Vehicle Location Visits
          </Typography>
          <FilterPicker
            headers={headers}
            data={visits}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredVisits.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredVisits}
            headers={headers.concat(replayColumn).concat(downloadColumn)}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredVisits.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import VirtualisedListboxComponent from './VirtualisedListboxComponent';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const { styles={} } = data;
  const { children: option } = data[index].props;

  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      ...(styles[option?.className] || {}),
    },
  });
}

export default function AutosuggestField({
  input: { value, onChange, ...input },
  meta,
  suggestions,
  disabled,
  styles = {},
  ...props
}) {
  function handleChange(event, suggestion) {
    onChange(suggestion ? suggestion.value : '');
  }

  return (
    <Autocomplete
      ListboxComponent={VirtualisedListboxComponent}
      ListboxProps={{ styles, options: suggestions, renderRow }}
      disableListWrap
      value={
        suggestions.find((suggestion) => suggestion.value === value) || null
      }
      disabled={disabled}
      onChange={handleChange}
      options={suggestions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
       const option = suggestions.find((s) => s.value === value);

        return (
          <TextField
            {...params}
            {...props}
            helperText={meta.touched && (meta.error || meta.submitError)}
            error={meta.touched && meta.invalid}
            style={styles[option?.className]}
          />
        );
      }}
    />
  );
}

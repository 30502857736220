import {
  IconButton,
  makeStyles,
  Paper,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
  FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY,
  UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
} from '../../actions';
import { downloadCSV } from '../../apis/utilities';
import Container from '../Container';
import { FilterPicker, Parameters, Table, TablePagination } from '../controls';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  textField: {
    margin: theme.spacing(1),
    width: 200,
  },
  popoverSheet: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  parameters: {
    width: 256,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? [
        { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
        { label: 'Vehicle Type', key: 'type', type: 'text' },
      ]
    : [
        { label: 'Registration', key: 'registrationNumber', type: 'text' },
        { label: 'Fleet Number', key: 'fleetNumber', type: 'text' },
        { label: 'Vehicle Role', key: 'role', type: 'text' },
        { label: 'Vehicle Type', key: 'type', type: 'text' },
      ]),
  { label: 'Start Time', key: 'startTime', type: 'date' },
  { label: 'End Time', key: 'endTime', type: 'date' },
  {
    label: 'Activation Time',
    key: 'malfunctionIndicatorLightOnTime',
    type: 'date',
  },
];

const diagnosticHeaders = [
  {
    label: 'On-Board Diagnostics Time',
    key: 'time',
    type: 'date',
  },
  {
    label: 'Area',
    key: 'area',
    type: 'text',
  },
  {
    label: 'Class',
    key: 'class',
    type: 'text',
  },
  {
    label: 'Code',
    key: 'code',
    type: 'text',
  },
  {
    label: 'Confirmed',
    key: 'isConfirmed',
    type: 'text',
  },
];

function Diagnostics({ entry }) {
  if (entry.diagnostics.length > 0) {
    return (
      <Table
        data={entry.diagnostics}
        headers={diagnosticHeaders}
        rowsPerPage={entry.diagnostics.length}
        page={0}
        footer={[]}
        keyName="identifier"
      />
    );
  }
  return null;
}

export default function MalfunctionIndicatorLight() {
  const dispatch = useDispatch();
  const trips = useSelector(
    (state) => state.events.tripsWithMalfunctionIndicatorLight.list,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.events.tripsWithMalfunctionIndicatorLight.isLoading
  );
  const error = useSelector(
    (state) => state.events.tripsWithMalfunctionIndicatorLight.error
  );
  const filter = useSelector(
    (state) => state.events.tripsWithMalfunctionIndicatorLight.filter,
    _.isEqual
  );
  const query = useSelector(
    (state) => state.events.tripsWithMalfunctionIndicatorLight.query,
    _.isEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const snackbar = useSnackbar();

  const tableHeaders = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: Diagnostics,
      filter: false,
    },
    ...(useReducedResourceInformation
      ? [
          {
            label: 'Fleet Number',
            key: 'fleetNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Vehicle Type',
            key: 'type',
            type: 'text',
            filter: true,
          },
        ]
      : [
          {
            label: 'Registration',
            key: 'registrationNumber',
            type: 'text',
            filter: true,
          },
          {
            label: 'Fleet Number',
            key: 'fleetNumber',
            type: 'text',
            filter: true,
          },
          { label: 'Vehicle Role', key: 'role', type: 'text', filter: true },
          { label: 'Vehicle Type', key: 'type', type: 'text', filter: true },
        ]),
    { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
    { label: 'End Time', key: 'endTime', type: 'date', filter: false },
    {
      label: 'Activation Time',
      key: 'malfunctionIndicatorLightOnTime',
      type: 'date',
      filter: false,
    },
  ];

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_CANCELLED,
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_QUERY,
      payload: query,
    });
  }

  function handleFilterClose() {
    setAnchorEl(null);
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_TRIPS_FOR_MALFUNCTION_INDICATOR_LIGHT_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Malfunction Indicator Light.csv';
    const data = filteredTrips.map((trip) => ({
      ...trip,
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredTrips = filterLocally(filter, trips);

  return (
    <Container title="Malfunction Indicator Light">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        value={query}
        onChange={handleQueryChange}
        className={classes.parameters}
        vehicle
        pointEvent
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Malfunction Indicator Light</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Malfunction Indicator Light
          </Typography>
          <FilterPicker
            headers={tableHeaders}
            data={trips}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredTrips.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
          <Popover
            id="filter-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          ></Popover>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredTrips}
            headers={tableHeaders}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrips.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

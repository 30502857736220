import React from 'react';
import {
  Field,
  TextField,
  CheckboxField,
  SelectField,
  required,
} from '../fields';
import { useAuth } from '../Auth';
import { vinErrors } from './TelematicsBoxList/utilities';

const {
  vehicleRoles,
  vehicleTypes,
  vehicleEditableFields,
  useRestricted,
  useReducedResourceInformation,
} = window.config;

export default function VehicleKeyFields({ vehicle, classes, disabled }) {
  const auth = useAuth();
  const editable = auth.isAuthorised('vehicles', true);

  function isDisabled(fieldName) {
    return disabled || !(
      editable &&
      (vehicleEditableFields[fieldName] || vehicle === null)
    );
  }

  const composeValidators = (...validators) => (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

  function validateIfNotDisabled(disabled, validateFunction) {
    if (disabled) {
      return undefined;
    } else {
      return validateFunction;
    }
  }

  const vinDisabled = !editable || disabled;

  return (
    <>
      <Field
        name="identificationNumber"
        component={TextField}
        label="VIN"
        className={classes.textField}
        validate={validateIfNotDisabled(
          vinDisabled,
          composeValidators(required, vinErrors)
        )}
        disabled={vinDisabled}
      >
        {(props) => (
          <TextField
            {...props}
            value={props.input.value}
            inputProps={{
              className: classes.vinText,
              spellCheck: 'false',
            }}
          />
        )}
      </Field>
      {!useReducedResourceInformation && (
        <Field
          name="registrationNumber"
          component={TextField}
          label="Registration"
          className={classes.textField}
          validate={validateIfNotDisabled(
            isDisabled('registrationNumber'),
            required
          )}
          disabled={isDisabled('registrationNumber')}
        >
          {(props) => (
            <TextField
              {...props}
              value={props.input.value}
              inputProps={{
                className: classes.vinText,
                spellCheck: 'false',
              }}
            />
          )}
        </Field>
      )}
      <Field
        name="fleetNumber"
        component={TextField}
        label="Fleet Number"
        className={classes.textField}
        validate={validateIfNotDisabled(isDisabled('fleetNumber'), required)}
        disabled={isDisabled('fleetNumber')}
      >
        {(props) => (
          <TextField
            {...props}
            value={props.input.value}
            inputProps={{
              className: classes.vinText,
              spellCheck: 'false',
            }}
          />
        )}
      </Field>
      {useReducedResourceInformation ? (
        <Field
          name="type"
          component={SelectField}
          label="Type"
          className={classes.textField}
          validate={validateIfNotDisabled(
            isDisabled('type'),
            required
          )}
          values={vehicleTypes}
          disabled={isDisabled('type')}
        />
      ) : (
        <Field
          name="role"
          component={SelectField}
          label="Role"
          className={classes.textField}
          validate={validateIfNotDisabled(
            isDisabled('role'),
            required
          )}
          values={vehicleRoles}
          disabled={isDisabled('role')}
        />
      )}
      {useRestricted && (
        <Field
          name="restricted"
          component={CheckboxField}
          label="Restricted"
          className={classes.textField}
          disabled={isDisabled('restricted')}
        />
      )}
    </>
  );
}

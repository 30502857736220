import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import Container from '../../Container';
import {
  FilterPicker,
  DatePicker,
  TablePagination,
  Table,
} from '../../controls';
import { useSnackbar } from '../../Snackbar';
import { downloadCSV } from '../../../apis/utilities';
import {
  FETCH_VEHICLE_ODOMETERS,
  UPDATE_VEHICLE_ODOMETERS_FILTER,
} from '../../../actions';
import { filterLocally } from '../../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  root: {
    padding: theme.spacing(1),
    height: 'calc(100% - 48px)',
  },
  paper: {
    width: '100%',
    height: '100%',
    overflow: 'none',
  },
  tableContainer: {
    height: 'calc(100% - 116px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing(1),
  },
  filterField: {
    width: 144,
  },
}));

const headers = [
  ...(useReducedResourceInformation
    ? []
    : [
        {
          key: 'registrationNumber',
          type: 'text',
          label: 'Registration',
          filter: true,
        },
      ]),
  {
    key: 'fleetNumber',
    type: 'text',
    label: 'Fleet Number',
    filter: true,
  },
  {
    key: 'readingTime',
    type: 'date',
    label: 'Reading Date & Time',
    filter: false,
  },
  {
    key: 'readingMiles',
    type: 'number',
    label: 'Reading Miles',
    filter: false,
  },
  {
    key: 'pollAfterReadingTime',
    type: 'text',
    label: 'Next Poll Date & Time',
    filter: false,
  },
  {
    key: 'pollAfterReadingMiles',
    type: 'number',
    label: 'Next Poll Miles',
    filter: false,
  },
  {
    key: 'pollAfterGapHours',
    type: 'number',
    label: 'Next Poll Gap Hours',
    filter: false,
  },
  {
    key: 'latestPollTime',
    type: 'text',
    label: 'Latest Poll Date & Time',
    filter: false,
  },
  {
    key: 'latestPollMiles',
    type: 'number',
    label: 'Latest Poll Miles',
    filter: false,
  },
  {
    key: 'calculatedMiles',
    type: 'number',
    label: 'Calculated Miles',
    filter: false,
  },
];

export default function Mileage() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reports.mileage.data, _.isEqual);
  const filter = useSelector(
    (state) => state.reports.mileage.filter,
    _.isEqual
  );
  const isLoading = useSelector((state) => state.reports.mileage.isLoading);
  const error = useSelector((state) => state.reports.mileage.error);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    dispatch({
      type: FETCH_VEHICLE_ODOMETERS,
      payload: filter.date,
    });
  }, [dispatch, filter.date]);

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
      fields: {
        ...filter.fields,
      },
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_VEHICLE_ODOMETERS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDateChange(date) {
    updateFilter({ date });
  }

  function formatDate(date) {
    return date ? moment(date).format('DD/MM/YYYY, HH:mm:ss') : '-';
  }

  async function handleDownloadClick() {
    const filename = 'Mileage.csv';
    const formattedData = filteredReadings.map((reading) => ({
      ...reading,
      readingTime: formatDate(reading.readingTime),
      latestPollTime: formatDate(reading.latestPollTime),
      pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
    }));

    downloadCSV(formattedData, filename, headers);
  }

  const filteredReadings = filterLocally(filter, data);

  const mappedReadings = filteredReadings.map((reading) => ({
    ...reading,
    pollAfterReadingTime: formatDate(reading.pollAfterReadingTime),
    latestPollTime: formatDate(reading.latestPollTime),
  }));

  return (
    <Container title="Mileage">
      <div className={classes.itemSection}>
        <div className={classes.root}>
          <Helmet>
            <title>IR3 | Mileage</title>
          </Helmet>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolbar}>
              <Typography
                className={classes.title}
                variant="subtitle1"
                // component="div"
              >
                Mileage
              </Typography>
              {isLoading && (
                <CircularProgress
                  className={classes.progress}
                  size={16}
                  thickness={6}
                />
              )}
              <DatePicker
                value={filter.date}
                onChange={handleDateChange}
                className={classes.filterField}
              />
              <FilterPicker
                headers={headers}
                data={data}
                filter={filter}
                onFilterChange={onFilterChange}
              />
              <IconButton
                title="Download"
                disabled={filteredReadings.length === 0}
                onClick={handleDownloadClick}
              >
                <GetAppIcon />
              </IconButton>
            </Toolbar>
            <Table
              classes={classes}
              data={mappedReadings}
              headers={headers}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              order={filter.order}
              orderBy={filter.orderBy}
              onOrderChange={handleOrderChange}
              onOrderByChange={handleOrderByChange}
            />
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={mappedReadings.length}
              rowsPerPage={filter.rowsPerPage}
              page={filter.page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </div>
      </div>
    </Container>
  );
}

import { CancelToken } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  FETCH_VEHICLE_DAILY_UTILISATION,
  FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
  FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
  FETCH_VEHICLE_HOURLY_UTILISATION,
  FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED,
  FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
  FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_DAILY_UTILISATION,
  LOAD_VEHICLE_DAILY_UTILISATION_FAILURE,
  LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS,
  LOAD_VEHICLE_HOURLY_UTILISATION,
  LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE,
  LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS,
} from '../actions';
import api from '../apis';
import {
  getHeaders,
  log,
  reduceByType as reduceAreas,
  areasFilter,
  getGroupKey,
} from '../apis/utilities';
import db, { fetchCachedData } from '../data/db';

const { useReducedResourceInformation } = window.config;

let cancel;

function vehicleUtilisationFilter(record, filter) {
  if (
    filter.registrationNumber.length !== 0 &&
    !filter.registrationNumber.includes(record.registrationNumber)
  ) {
    return false;
  }

  if (
    filter.fleetNumber.length !== 0 &&
    !filter.fleetNumber.includes(record.fleetNumber)
  ) {
    return false;
  }

  if (filter.role.length !== 0 && !filter.role.includes(record.role)) {
    return false;
  }

  if (filter.type.length !== 0 && !filter.type.includes(record.type)) {
    return false;
  }

  return areasFilter(record, filter);
}

function getVehicleUtilisationFilterAndGroupByValues(data, filter) {
  const { areas: _, ...fields } = filter;
  const result = { areas: {} };
  const areas = Array.from(
    new Set([].concat(...data.map((record) => Object.keys(record.areas))))
  );

  for (const key in fields) {
    const keyFilter = { ...filter, [key]: [] };
    result[key] = Array.from(
      new Set(
        data
          .filter((record) => vehicleUtilisationFilter(record, keyFilter))
          .map((record) => record[key])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  for (const area of areas) {
    const keyFilter = { ...filter, areas: { ...filter.areas, [area]: [] } };
    result.areas[area] = Array.from(
      new Set(
        data
          .filter((record) => vehicleUtilisationFilter(record, keyFilter))
          .map((record) => record.areas[area])
      )
    )
      .filter((value) => value !== undefined)
      .sort();
  }

  return {
    filterValues: result,
    groupByValues: useReducedResourceInformation
      ? ['date', 'month', 'fleetNumber', 'type', 'homeStation', ...areas]
      : [
          'date',
          'month',
          'registrationNumber',
          'fleetNumber',
          'role',
          'type',
          'homeStation',
          ...areas,
        ],
  };
}

function getVehicleDailyUtilisation(
  rawData,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification
) {
  const groupedData =
    rawData.length === 0
      ? new Map()
      : rawData.reduce((accumulator, record) => {
          const groupKey = getGroupKey(groupBy, record);
          let current = accumulator.get(groupKey);

          if (!current) {
            current = {
              group: groupKey,
              movingSeconds: [],
              stoppedBaseSeconds: [],
              stoppedWorkshopSeconds: [],
              stoppedElsewhereSeconds: [],
              idleBaseSeconds: [],
              idleWorkshopSeconds: [],
              idleElsewhereSeconds: [],
              unaccountableSeconds: [],
              tripStarts: [],
              movingKilometres: [],
              identifiers: [],
            };
            accumulator.set(groupKey, current);
          }

          current.movingSeconds.push(
            record.movingSeconds -
              record.idleBaseSeconds -
              record.idleWorkshopSeconds -
              record.idleElsewhereSeconds
          );
          current.stoppedBaseSeconds.push(record.stoppedBaseSeconds);
          current.stoppedWorkshopSeconds.push(record.stoppedWorkshopSeconds);
          current.stoppedElsewhereSeconds.push(record.stoppedElsewhereSeconds);
          current.idleBaseSeconds.push(record.idleBaseSeconds);
          current.idleWorkshopSeconds.push(record.idleWorkshopSeconds);
          current.idleElsewhereSeconds.push(record.idleElsewhereSeconds);
          current.unaccountableSeconds.push(record.unaccountableSeconds);
          current.tripStarts.push(record.tripStarts);
          current.movingKilometres.push(record.movingKilometres);

          if (
            !current.identifiers.includes(
              useReducedResourceInformation
                ? record.fleetNumber
                : record.registrationNumber
            )
          ) {
            current.identifiers.push(
              useReducedResourceInformation
                ? record.fleetNumber
                : record.registrationNumber
            );
          }

          return accumulator;
        }, new Map());

  function roundedAverageHours(groupedValues) {
    return _.round(
      groupedValues.reduce((a, b) => a + b, 0) / groupedValues.length / 3600,
      2
    );
  }

  let data = Array.from(groupedData.values()).map((group) => ({
    group:
      groupBy === 'date' || groupBy === 'month'
        ? new Date(group.group)
        : group.group,
    count: group.identifiers.length,
    moving: roundedAverageHours(group.movingSeconds),
    stoppedBase: roundedAverageHours(group.stoppedBaseSeconds),
    stoppedWorkshop: roundedAverageHours(group.stoppedWorkshopSeconds),
    stoppedElsewhere: roundedAverageHours(group.stoppedElsewhereSeconds),
    idleBase: roundedAverageHours(group.idleBaseSeconds),
    idleWorkshop: roundedAverageHours(group.idleWorkshopSeconds),
    idleElsewhere: roundedAverageHours(group.idleElsewhereSeconds),
    unaccountable: roundedAverageHours(group.unaccountableSeconds),
    totalMileage: _.round(
      group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119,
      2
    ),
    averageMileage: _.round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.identifiers.length,
      2
    ),
    dailyMileage: _.round(
      (group.movingKilometres.reduce((a, b) => a + b, 0) * 0.62137119) /
        group.movingKilometres.length,
      2
    ),
    totalTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0),
      2
    ),
    averageTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.identifiers.length,
      2
    ),
    dailyTrips: _.round(
      group.tripStarts.reduce((a, b) => a + b, 0) / group.tripStarts.length,
      2
    ),
  }));

  if (usedUnusedUnavailableClassification) {
    data = data.map(
      ({
        idleBase = 0,
        idleElsewhere = 0,
        idleWorkshop = 0,
        moving = 0,
        stoppedBase = 0,
        stoppedElsewhere = 0,
        stoppedWorkshop = 0,
        unaccountable = 0,
        ...item
      }) => ({
        used: _.round(moving + stoppedElsewhere + idleElsewhere, 2),
        unused: _.round(stoppedBase + idleBase, 2),
        unavailable: _.round(stoppedWorkshop + idleWorkshop, 2),
        unaccountable: _.round(unaccountable, 2),
        ...item,
      })
    );
  }

  if (orderBy === 'date' || orderBy === 'month') {
    data.sort((a, b) =>
      moment(a.group, 'DD/MM/YYYY').diff(moment(b.group, 'DD/MM/YYYY'))
    );
  } else {
    data = _.orderBy(data, orderBy);
  }

  return data;
}

async function fetchVehicleDailyUtilisationRequest(
  query,
  filter,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification
) {
  const response = await api.get('/vehicleDailySummaries', {
    params: {
      query,
      projection: {
        time: true,
        vehicle: true,
        movingKilometres: true,
        movingSeconds: true,
        stoppedBaseSeconds: true,
        stoppedElsewhereSeconds: true,
        stoppedWorkshopSeconds: true,
        idleBaseSeconds: true,
        idleElsewhereSeconds: true,
        idleWorkshopSeconds: true,
        tripStarts: true,
        accelerometerAlerts: true,
        accelerometerEvents: true,
        respondingToIncidents: true,
        respondingToIncidentSeconds: true,
        unaccountableSeconds: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({ vehicle: { areas, ...vehicle }, ...record }) => ({
      ...record,
      ...vehicle,
      areas: reduceAreas(areas),
    })
  );

  await db.vehicleDailyUtilisation.clear();
  await db.vehicleDailyUtilisation.bulkAdd(data);
  await db.parameters.put({
    store: 'vehicleDailyUtilisation',
    query,
  });

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      usedUnusedUnavailableClassification
    ),
    usedUnusedUnavailableClassification,
  };

  log('Read', 'Vehicle Daily Utilisation', query);

  return results;
}

export function fetchVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_DAILY_UTILISATION),
    mergeMap(
      ({
        payload: {
          query,
          filter,
          groupBy,
          orderBy,
          usedUnusedUnavailableClassification,
        },
      }) =>
        from(
          fetchVehicleDailyUtilisationRequest(
            query,
            filter,
            groupBy,
            orderBy,
            usedUnusedUnavailableClassification
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_DAILY_UTILISATION_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_DAILY_UTILISATION_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_DAILY_UTILISATION_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

async function loadVehicleDailyUtilisationRequest({
  filter,
  groupBy,
  orderBy,
  usedUnusedUnavailableClassification,
}) {
  const reportName = 'vehicleDailyUtilisation';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    groupBy,
    ...getVehicleUtilisationFilterAndGroupByValues(data, filter),
    data: getVehicleDailyUtilisation(
      filteredData,
      groupBy,
      orderBy,
      usedUnusedUnavailableClassification
    ),
    query: parameters?.query || {},
    usedUnusedUnavailableClassification,
  };

  log('Load', 'Vehicle Daily Utilisation', parameters);

  return results;
}

export function loadVehicleDailyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_DAILY_UTILISATION),
    mergeMap(({ payload }) =>
      from(loadVehicleDailyUtilisationRequest(payload)).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_DAILY_UTILISATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_DAILY_UTILISATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function getVehicleHourlyUtilisation(
  rawData,
  usedUnusedUnavailableClassification
) {
  const MS = 'movingSeconds';
  const SBS = 'stoppedBaseSeconds';
  const SES = 'stoppedElsewhereSeconds';
  const SWS = 'stoppedWorkshopSeconds';
  const IBS = 'idleBaseSeconds';
  const IES = 'idleElsewhereSeconds';
  const IWS = 'idleWorkshopSeconds';
  const UAS = 'unaccountableSeconds';

  // translate mongo result
  const mapResponseToResult = (response) => {
    const transformedResults = [];
    response.forEach((data) => {
      const hourlyObject = convertHourlyObject(data.hourly);
      transformedResults.push({ ...hourlyObject });
    });
    return transformedResults;
  };

  // convert hourly object
  const convertHourlyObject = (data) => {
    const result = {};
    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      result[key] = data[i];
    }
    return result;
  };

  // build hourly object
  const buildHouryObject = (data) => {
    const hourlyObject = {};

    for (let i = 0; i < 24; i++) {
      const key = `${i.toString().padStart(2, '0')}:00`;
      hourlyObject[key] = [];
    }

    data.forEach((data) => {
      const hourKeys = Object.keys(data);
      hourKeys.forEach((key) => {
        const hourData = data[key] ? data[key] : 0; // There are missing hours between 0 and 23, add 0 for them
        const hours = hourlyObject[key];
        if (Array.isArray(hours)) {
          hours.push(hourData);
          hourlyObject[key] = [...hours];
        }
      });
    });
    return hourlyObject;
  };

  // calculate the value for each hour by converting seconds to minutes
  const calculateForHour = (seconds) => {
    const totalRecords = rawData.length;
    const value = _.round((60 * (seconds / 60)) / (totalRecords * 60), 2);
    return value;
  };

  // change field name and calculate values
  const buildObjectForReport = (obj) => {
    const result = {};
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      switch (key) {
        case 'hour':
          result['Hour'] = obj[key];
          break;
        case MS:
          result['Moving'] = calculateForHour(
            obj[key] - obj[IBS] - obj[IES] - obj[IWS]
          );
          break;
        case SBS:
          result['Stopped @ Base'] = calculateForHour(obj[key]);
          break;
        case SES:
          result['Stopped Elsewhere'] = calculateForHour(obj[key]);
          break;
        case SWS:
          result['Stopped @ Workshop'] = calculateForHour(obj[key]);
          break;
        case IBS:
          result['Idle @ Base'] = calculateForHour(obj[key]);
          break;
        case IES:
          result['Idle Elsewhere'] = calculateForHour(obj[key]);
          break;
        case IWS:
          result['Idle @ Workshop'] = calculateForHour(obj[key]);
          break;
        case UAS:
          result['Unaccounted'] = calculateForHour(obj[key]);
          break;
        default:
          result[key] = obj[key];
      }
    });

    return result;
  };

  // add the same hourly field for each hour
  const calculateHourlyValue = (data) => {
    const hourlyObjectKeys = Object.keys(data);
    const result = [];

    hourlyObjectKeys.forEach((key) => {
      if (data[key] && data[key].length > 0) {
        const obj = data[key].reduce(
          (sum, tuple) => {
            return {
              hour: key,
              [MS]: !isNaN(tuple[MS]) ? sum[MS] + tuple[MS] : sum[MS] + 0,
              [SBS]: !isNaN(tuple[SBS]) ? sum[SBS] + tuple[SBS] : sum[SBS] + 0,
              [SES]: !isNaN(tuple[SES]) ? sum[SES] + tuple[SES] : sum[SES] + 0,
              [SWS]: !isNaN(tuple[SWS]) ? sum[SWS] + tuple[SWS] : sum[SWS] + 0,
              [IBS]: !isNaN(tuple[IBS]) ? sum[IBS] + tuple[IBS] : sum[IBS] + 0,
              [IES]: !isNaN(tuple[IES]) ? sum[IES] + tuple[IES] : sum[IES] + 0,
              [IWS]: !isNaN(tuple[IWS]) ? sum[IWS] + tuple[IWS] : sum[IWS] + 0,
              [UAS]: !isNaN(tuple[UAS]) ? sum[UAS] + tuple[UAS] : sum[UAS] + 0,
            };
          },
          {
            [MS]: 0,
            [SBS]: 0,
            [SES]: 0,
            [SWS]: 0,
            [IBS]: 0,
            [IES]: 0,
            [IWS]: 0,
            [UAS]: 0,
          }
        );

        const calculatedObj = buildObjectForReport(obj);
        result.push(calculatedObj);
      }
    });

    return result;
  };

  const mappedData = mapResponseToResult(rawData);
  const hourlyData = buildHouryObject(mappedData);
  let data = calculateHourlyValue(hourlyData);

  if (usedUnusedUnavailableClassification) {
    data = hourlyToUsedUnusedAvailable(data);
  }

  return data;
}

async function fetchVehicleHourlyUtilisationRequest(
  query,
  filter,
  usedUnusedUnavailableClassification
) {
  const response = await api.get('/vehicleDailySummaries', {
    params: {
      query,
      projection: {
        time: true,
        vehicle: true,
        hourly: true,
      },
    },
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  const data = response.data.map(
    ({ vehicle: { areas, ...vehicle }, ...record }) => ({
      ...record,
      ...vehicle,
      areas: reduceAreas(areas),
    })
  );

  await db.vehicleHourlyUtilisation.clear();
  await db.vehicleHourlyUtilisation.add(data);
  await db.parameters.put({
    store: 'vehicleHourlyUtilisation',
    query,
  });

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehicleUtilisationFilterAndGroupByValues(data, filter)
      .filterValues,
    data: getVehicleHourlyUtilisation(
      filteredData,
      usedUnusedUnavailableClassification
    ),
    usedUnusedUnavailableClassification,
  };

  log('Read', 'Vehicle Hourly Utilisation', query);

  return results;
}

function hourlyToUsedUnusedAvailable(data) {
  return data.map(
    ({
      'Idle @ Base': idleBase = 0,
      'Idle Elsewhere': idleElsewhere = 0,
      'Idle @ Workshop': idleWorkshop = 0,
      Moving: moving = 0,
      'Stopped @ Base': stoppedBase = 0,
      'Stopped Elsewhere': stoppedElsewhere = 0,
      'Stopped @ Workshop': stoppedWorkshop = 0,
      ...item
    }) => ({
      Used: _.round(moving + stoppedElsewhere + idleElsewhere, 2),
      Unused: _.round(stoppedBase + idleBase, 2),
      Unavailable: _.round(stoppedWorkshop + idleWorkshop, 2),
      ...item,
    })
  );
}

export function fetchVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE_HOURLY_UTILISATION),
    switchMap(
      ({ payload: { query, filter, usedUnusedUnavailableClassification } }) =>
        from(
          fetchVehicleHourlyUtilisationRequest(
            query,
            filter,
            usedUnusedUnavailableClassification
          )
        ).pipe(
          map((payload) => ({
            type: FETCH_VEHICLE_HOURLY_UTILISATION_SUCCESS,
            payload,
          })),
          takeUntil(
            action$.pipe(
              ofType(FETCH_VEHICLE_HOURLY_UTILISATION_CANCELLED),
              tap((ev) => cancel('cancelled'))
            )
          ),
          catchError(({ message: payload }) =>
            of({
              type: FETCH_VEHICLE_HOURLY_UTILISATION_FAILURE,
              payload,
            })
          )
        )
    )
  );
}

async function loadVehicleHourlyUtilisationRequest(
  filter,
  usedUnusedUnavailableClassification
) {
  const reportName = 'vehicleHourlyUtilisation';
  const data = await fetchCachedData(reportName);
  const parameters = await db.parameters.get(reportName);

  const filteredData = data.filter((record) =>
    vehicleUtilisationFilter(record, filter)
  );

  const results = {
    filter,
    filterValues: getVehicleUtilisationFilterAndGroupByValues(data, filter)
      .filterValues,
    data: getVehicleHourlyUtilisation(
      filteredData,
      usedUnusedUnavailableClassification
    ),
    query: parameters?.query || {},
  };

  log('Load', 'Vehicle Hourly Utilisation', parameters);

  return results;
}

export function loadVehicleHourlyUtilisationEpic(action$) {
  return action$.pipe(
    ofType(LOAD_VEHICLE_HOURLY_UTILISATION),
    mergeMap(({ payload: { filter, usedUnusedUnavailableClassification } }) =>
      from(
        loadVehicleHourlyUtilisationRequest(
          filter,
          usedUnusedUnavailableClassification
        )
      ).pipe(
        map((payload) => ({
          type: LOAD_VEHICLE_HOURLY_UTILISATION_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: LOAD_VEHICLE_HOURLY_UTILISATION_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { inputsStringToBinary, OnOffIcon } from './utilities';

const { dioStates } = window.config;

export default function DioTable({
  polls,
  pollsToShow, // how many to show sorted by date
  includeNonInputChanges, // don't hide polls with a different diagnostic code to 7
  useInputsBinary, // use the binary in deviceProperties instead of object property name
  excludedInputs = [], // don't label these inputs, use (1) etc. instead
  showTime = true,
}) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down(1000));

  const dioLabels = () => {
    return (
      <TableRow>
        {showTime && <TableCell>Time</TableCell>}
        {Object.keys(dioStates).map((k, index) => (
          <TableCell key={k} style={{ width: '12%' }}>
            {excludedInputs.includes(k) ? `(${index + 1})` : dioStates[k]}
          </TableCell>
        ))}
        <TableCell>Driver</TableCell>
      </TableRow>
    );
  };

  const rawInputOn = (inputsBin, i) => {
    return inputsBin[7 - i] === '1';
  };

  const dioValuesFromConfig = (poll) => {
    const inputsBin = inputsStringToBinary(poll?.deviceProperties?.inputs || 0);

    return Object.keys(dioStates).map((k, i) => {
      const inputBitOn = rawInputOn(inputsBin, i);

      return (
        <TableCell key={k} style={{ width: '12%' }}>
          <OnOffIcon
            style={{ margin: '0 auto' }}
            on={
              k.startsWith('unused') || useInputsBinary ? inputBitOn : poll[k]
            }
          />
        </TableCell>
      );
    });
  };

  const dioRows = (polls) => {
    const yearFormat = isMedium ? 'DD/MM/YY ' : 'DD/MM/YYYY, ';

    return _.filter(
      polls,
      (p) => includeNonInputChanges || p.diagnosticCode === '7' || p.driver
    )
      .slice(0, pollsToShow || polls.length)
      .map((poll) => {
        const time = moment(poll.time);
        return (
          <TableRow key={poll.identifier}>
            {showTime && (
              <TableCell>{time.format(`${yearFormat}HH:mm:ss`)}</TableCell>
            )}
            {dioValuesFromConfig(poll)}
            <TableCell>
              {poll.driver ? poll.driver.identificationReference : ''}
            </TableCell>
          </TableRow>
        );
      });
  };

  return (
    <Table>
      <TableHead>{dioLabels()}</TableHead>
      <TableBody>{dioRows(polls)}</TableBody>
    </Table>
  );
}

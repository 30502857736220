import React, { useState, useRef } from 'react';
import moment from 'moment';
import {
  Avatar,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { Skeleton } from '@material-ui/lab';
import { Nfc as RfidIcon, VpnKey as DallasKeyIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  RECEIVE_TELEMATICS_BOX_POLL,
  UPDATE_DRIVER_ID,
  CANCEL_DRIVER_ID,
} from '../../../actions';
import StepsCheckList from './StepsCheckList';
import useLatestPoll from './useLatestPoll';
import { OnOffIcon } from './utilities';
import { log } from '../../../apis/utilities';
import { useAuth } from '../../Auth';
import { useEffectOnce } from '../../../hooks';

const {
  useDallasKeys,
  dioOptions: { manualPolls },
} = window.config;

function offToOnStatus(poll, prevPoll) {
  const alreadyOnMessage = 'Ignition already on, turn off first';
  const maybeWaitMessage = 'This could take 30 seconds to update if ignition has been off for a while, please wait...';

  function offAWhile(poll) {
    return moment().diff(poll.time || moment(), 'minutes') > 2
  }
  
  // if there's no current poll, nothing has come in since the test began, look at latest
  if (!poll) {
    // if the latest poll has ignition on, warn that it's on already
    if (prevPoll?.ignitionOn) {
      return alreadyOnMessage;
    }

    // otherwise it might take a while to register the off-to-on
    return offAWhile(prevPoll) ? maybeWaitMessage : '';
  } else {
    // if it's on and the previous poll was on, we didn't get an off-to-on
    if (poll.ignitionOn && (prevPoll ? prevPoll.ignitionOn : true)) {
      return alreadyOnMessage;
    }

    // otherwise it's off, waiting for an off to on
    return offAWhile(poll) ? maybeWaitMessage : '';
  }
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 600,
    minWidth: 466,
  },
  wakeMessage: {
    justifyContent: 'center',
  },
  negativeButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: 'white',
    width: '150px',
  },
  positiveButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: 'white',
    width: '150px',
  },
  status: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  enabledDriverId: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  disabledDriverId: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.disabled,
  },
  listItem: {
    height: 56,
  },
  warning: {
    color: amber[900],
    margin: theme.spacing(1),
  },
}));

export function DriverIdStatus({
  imei,
  onCancel,
  onEnable,
  onDisable,
  pendingMessage,
}) {
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useAuth();

  const canEdit = auth.getAuthorisation()?.commissioning?.write;

  const {
    driverIdEnabled: currentlyEnabled,
    driverIdWillBeEnabled: willBeEnabled,
    driverIdRequest,
  } = box || {};
  let text = '<unknown>';
  let pendingText = '';
  let allowCancel = true;
  const Icon = useDallasKeys ? DallasKeyIcon : RfidIcon;
  let undefinedDriverId = false;

  if (typeof currentlyEnabled !== 'undefined') {
    if (currentlyEnabled) {
      text = 'Enabled';
    } else {
      text = 'Disabled';
    }
  } else {
    undefinedDriverId = true;
  }

  if (typeof willBeEnabled !== 'undefined') {
    pendingText =
      `${willBeEnabled ? 'En' : 'Dis'}able pending, ` +
      (pendingMessage || `action will complete on next ignition off...`);
  } else {
    allowCancel = false;
  }

  if (driverIdRequest) {
    pendingText = 'Requesting...';

    allowCancel = false;
  }

  function enableDriverId() {
    log('Update', 'Telematics Driver ID', { imei, request: 'Enable' });

    onEnable && onEnable();

    dispatch({
      type: UPDATE_DRIVER_ID,
      payload: {
        imei,
        enabled: true,
      },
    });
  }

  function disableDriverId() {
    log('Update', 'Telematics Driver ID', { imei, request: 'Disable' });

    onDisable && onDisable();

    dispatch({
      type: UPDATE_DRIVER_ID,
      payload: {
        imei,
        enabled: false,
      },
    });
  }

  function cancelDriverId() {
    log('Update', 'Telematics Driver ID', { imei, request: 'Cancel' });

    onCancel && onCancel();

    dispatch({
      type: CANCEL_DRIVER_ID,
      payload: {
        imei,
      },
    });
  }

  return (
    <div>
      {undefinedDriverId ? (
        <List>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemAvatar>
              <Avatar>
                {<Skeleton variant="circle" width="100%" height="100%" />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Unknown state`}>
              <Skeleton height={20} width="100%" />
            </ListItemText>
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem disableGutters className={classes.listItem}>
            <ListItemAvatar>
              <Avatar
                className={
                  currentlyEnabled
                    ? classes.enabledDriverId
                    : classes.disabledDriverId
                }
              >
                {<Icon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${text}`} secondary={pendingText} />
          </ListItem>
        </List>
      )}
      {canEdit && (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.positiveButton}
            variant="contained"
            // disabled={!allowEnable} // should always be enabled in case reapply needed
            onClick={() => enableDriverId()}
          >
            Enable
          </Button>
          <Button
            variant="contained"
            className={classes.positiveButton}
            // disabled={!allowDisable} // should always be enabled in case reapply needed
            onClick={() => disableDriverId()}
          >
            Disable
          </Button>
          <Button
            variant="contained"
            className={classes.negativeButton}
            disabled={!allowCancel}
            onClick={() => cancelDriverId()}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

function useSendPoll(imei) {
  const dispatch = useDispatch();
  const latestPoll = useLatestPoll(imei);

  function sendPoll(change) {
    const time = new Date().toISOString();
    dispatch({
      type: RECEIVE_TELEMATICS_BOX_POLL,
      payload: {
        imei,
        poll: {
          ...latestPoll,
          ...change,
          identifier: imei + '-' + time,
          time,
        },
      },
    });
  }

  return sendPoll;
}

function useCountdown(total) {
  const secondsLeftRef = useRef(0);
  const countdownFrom = (elapsed) => {
    secondsLeftRef.current = Math.max(total - elapsed, 0);

    return secondsLeftRef.current === 0;
  };
  const countdownStatus = () => `${secondsLeftRef.current} seconds left...`;

  return [countdownFrom, countdownStatus];
}


function DriverIdPollTable({ imei, driverId }) {
  const latestPoll = useLatestPoll(imei);
  const sendPoll = useSendPoll(imei);

  function pollHeaders() {
    return (
      <TableRow>
        <TableCell>Ignition</TableCell>
        <TableCell>Time</TableCell>
        <TableCell>Buzzer</TableCell>
        <TableCell>Driver ID</TableCell>
        {manualPolls && <TableCell>Cached History</TableCell>}
      </TableRow>
    );
  }

  // TODO tidy this up, duplicate in InputsStatus
  function sendCached(data) {
    const mostRecent = data.payload.telematicsBoxes[imei].mostRecentPoll;
    const cached = data.payload.telematicsBoxes[imei].cachedPolls;
    const allPolls = _.orderBy(
      _.uniqBy([mostRecent, ...cached].filter(Boolean), "identifier"),
       "identifier");

    // if the imei was just created, it won't have a mostRecentPoll
    allPolls.forEach(sendPoll);
  }

  function pollValues() {
    if (!latestPoll) {
      return <></>;
    }

    const time = latestPoll.time
      ? moment(latestPoll.time).format(`DD/MM/YY HH:mm:ss`)
      : '';

    return (
      <TableRow>
        <TableCell>
          <OnOffIcon
            style={{ margin: '0 auto' }}
            on={latestPoll.ignitionOn}
            onClick={
              manualPolls &&
              (() =>
                sendPoll({
                  ignitionOn: !latestPoll.ignitionOn,
                  diagnosticCode: latestPoll.ignitionOn ? '4' : '3',
                }))
            }
          />
        </TableCell>
        <TableCell>{time}</TableCell>
        <TableCell>
          <OnOffIcon
            style={{ margin: '0 auto' }}
            on={latestPoll.diagnosticCode === '6'}
            onClick={
              manualPolls &&
              (() =>
                sendPoll({
                  diagnosticCode: latestPoll.diagnosticCode === '6' ? '0' : '6',
                }))
            }
          />
        </TableCell>
        <TableCell
          style={{ width: '40%' }}
          onClick={
            manualPolls &&
            (() => {
              const date = new Date().toISOString();
              const ref = [...date]
                .map((c, i) => date.charCodeAt(i).toString(16))
                .reverse()
                .slice(0, 16)
                .join('');
              sendPoll({
                driver: {
                  identificationTime: date,
                  identificationReference: ref,
                },
              });
            })
          }
        >
          {driverId || (latestPoll.driver ? latestPoll.driver.identificationReference : '')}
        </TableCell>
        {manualPolls && <TableCell>
          <OnOffIcon
            style={{ margin: '0 auto' }}
            on={latestPoll.cachedPolls?.length > 0}
            onClick={
              (() =>
              sendCached({
                  payload: { telematicsBoxes: { [imei]: { 
                    cachedPolls: [
                      {
                        time: new Date(Date.now() - 3000).toISOString(),
                        identifier: imei + '-' + new Date(Date.now() - 3000).toISOString(),
                        diagnosticCode: '2',
                        ignitionOn: true,
                      },
                      {
                        time: new Date(Date.now() - 2000).toISOString(),
                        identifier: imei + '-' + new Date(Date.now() - 2000).toISOString(),
                        diagnosticCode: '3',
                        ignitionOn: true,
                      },
                      {
                        time: new Date(Date.now() - 1000).toISOString(),
                        identifier: imei + '-' + new Date(Date.now() - 1000).toISOString(),
                        diagnosticCode: '0',
                        ignitionOn: true,
                      },
                    ],
                    mostRecentPoll: {
                      ...latestPoll,
                    bufferCount: 1,
                    ignitionOn: true,
                    diagnosticCode: '2',
                    }
                  }}}}
                  ))
            }
          />
        </TableCell>}

      </TableRow>
    );
  }

  return (
    <Table>
      <TableHead>{pollHeaders()}</TableHead>
      <TableBody>{pollValues()}</TableBody>
    </Table>
  );
}

export function DriverIdActions({ onCompleteChanged, imei, cancelPending }) {
  const classes = useStyles();
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);

  const [stepsStartTime, setStepsStartTime] = useState();
  const [isTargetEnabled, setIsTargetEnabled] = useState();
  const [needsToCancelPending, setNeedsToCancelPending] = useState(false);

  const boxUpdateSeconds = manualPolls ? 10 : isTargetEnabled ? 150 : 10;
  const [boxUpdateCountdown, boxUpdateStatus] = useCountdown(boxUpdateSeconds);

  useEffectOnce(() => {
    if (cancelPending && typeof box?.driverIdWillBeEnabled !== 'undefined') {
      setNeedsToCancelPending(true);
    }
    onCompleteChanged?.(true, {});
  });

  const driverIdSequence = {
    awaitingIgnitionOn: {
      label: 'Ignition on',
      checkFunction: (currPoll) => currPoll?.ignitionOn,
    },
    turnIgnitionOff: {
      label: 'Turn ignition off',
      checkFunction: (currPoll) => currPoll?.diagnosticCode === '4',
    },
    waitForRequestAcceptance: {
      label: 'Please wait while sending update to telematics box...',
      timerFunction: () => {
        if (typeof box.driverIdRequest === 'undefined') {
          return false;
        }

        return !box.driverIdRequest;
      },
    },
    keepIgnitionOffFor2Minutes: {
      label: isTargetEnabled
        ? 'Keep ignition off for 2 minutes 30 seconds'
        : 'Keep ignition off for 10 seconds',
      restartTimerIfFunction: (poll) => poll?.ignitionOn,
      timerFunction: boxUpdateCountdown,
      statusFunction: boxUpdateStatus,
      errorFunction: (poll) => (poll?.ignitionOn ? 'Turn ignition off' : null),
      timeBased: true,
    },
  };

  function handleEnable() {
    onCompleteChanged?.(false, {});
    setStepsStartTime(new Date().toISOString());
    setIsTargetEnabled(true);
    setNeedsToCancelPending(false);
  }

  function handleDisable() {
    onCompleteChanged?.(false, {});
    setStepsStartTime(new Date().toISOString());
    setIsTargetEnabled(false);
    setNeedsToCancelPending(false);
  }

  function handleCancel() {
    onCompleteChanged?.(true, {});
    setStepsStartTime();
    setNeedsToCancelPending(false);
  }

  function handleActionsComplete(complete, actions) {
    onCompleteChanged?.(complete, actions);
  }

  return (
    <>
      <DriverIdStatus
        imei={imei}
        onCancel={handleCancel}
        onEnable={handleEnable}
        onDisable={handleDisable}
        pendingMessage={
          needsToCancelPending ? 'cancel before proceeding...' : ''
        }
      />
      {needsToCancelPending && (
        <div className={classes.warning}>
          <Typography variant="caption">
            Warning: a previous driver ID state change is pending, cancel to
            proceed
          </Typography>
        </div>
      )}
      {stepsStartTime && (
        <>
          {' '}
          <DriverIdPollTable imei={imei} />
          {stepsStartTime && (
            <StepsCheckList
              sequenceToVerify={driverIdSequence}
              stepsStartTime={stepsStartTime}
              allowOverride={false}
              ignoreSequenceChanges={true}
              onCompleteChanged={handleActionsComplete}
              imei={imei}
            />
          )}
        </>
      )}
    </>
  );
}

export function DriverIdTest({ onCompleteChanged, imei }) {
  const box = useSelector((state) => state.telematicsBoxes.boxesByImei[imei]);

  const [buzzerActivated, setBuzzerActivated] = useState(false);
  const [driverId, setDriverId] = useState(undefined);
  const [stepsStartTime, setStepsStartTime] = useState(new Date());

  const buzzerSeconds = manualPolls ? 10 : 30;
  const [buzzerCountdown, buzzerCountdownStatus] = useCountdown(buzzerSeconds);

  function ifAIsUndefinedUseB(a, b) {
    return typeof a === 'undefined' ? b : a;
  }

  useEffectOnce(() => {
    setStepsStartTime(new Date().toISOString());
  });

  // we expect the buzzer if the target state is enabled and
  // don't expect the buzzer if the target state is disabled
  // if the request has gone through by now, use the current
  // state for testing the buzzer
  const expectedBuzzer = ifAIsUndefinedUseB(
    box.driverIdWillBeEnabled,
    box.driverIdEnabled
  ) || false;

  function checkOffToOn(poll) {
    return poll?.diagnosticCode === '3';
  }

  const testSequence = {
    turnIgnitionOffToOn: {
      label: 'Turn ignition off to on',
      // checkFunction: (poll) => poll?.diagnosticCode === '3',
      checkFunction: checkOffToOn,
      statusFunction: offToOnStatus,
    },
    wait30Seconds: {
      label: 'Wait 30 seconds',
      restartTimerIfFunction: (poll) => !poll?.ignitionOn,
      timerFunction: (elapsedSeconds, poll) => {
        const buzzerOn = poll?.diagnosticCode === '6';
        if (buzzerOn) {
          setBuzzerActivated(true);
        }

        return buzzerActivated || buzzerCountdown(elapsedSeconds);
      },
      statusFunction: buzzerCountdownStatus,
      errorFunction: (poll) =>
        poll && !poll.ignitionOn ? 'Turn ignition on' : null,
      timeBased: true,
    },
    checkForBuzzer: {
      label: `Check buzzer ${expectedBuzzer ? 'activates' : "didn't activate"}`,
      checkFunction: () => expectedBuzzer === buzzerActivated,
      errorFunction: () => {
        if (expectedBuzzer === buzzerActivated) {
          return null;
        } else {
          return expectedBuzzer
            ? 'Expected buzzer but not detected, try enabling driver ID again'
            : 'Buzzer detected when not expected, try disabling driver ID again';
        }
      },
      statusFunction: () => {
        if (buzzerActivated) {
          return 'Driver ID is enabled';
        }

        return 'Driver ID is disabled';
      },
    },
  };

  const isEnabledSequence = {
    scanDriverId: {
      label: 'Scan driver ID',
      checkFunction: (currPoll, prevPoll) => {
        const currDriverIdTime = currPoll?.driver?.identificationTime;
        const prevDriverIdTime = prevPoll?.driver?.identificationTime;

        const scanned = currDriverIdTime && currDriverIdTime !== prevDriverIdTime;
        if (scanned) {
          setDriverId(currPoll?.driver?.identificationReference);
        }

        return scanned;
      },
    },
  };

  function handleInputCheckComplete(complete, verifiedInputs) {
    onCompleteChanged && onCompleteChanged(complete, verifiedInputs);
  }

  return (
    <>
      <DriverIdPollTable imei={imei} driverId={driverId} />
      {stepsStartTime && (
        <StepsCheckList
          sequenceToVerify={{
            ...testSequence,
            ...(expectedBuzzer ? isEnabledSequence : {}), // additional enable test
          }}
          stepsStartTime={stepsStartTime}
          allowOverride={false}
          ignoreSequenceChanges={true}
          onCompleteChanged={handleInputCheckComplete}
          imei={imei}
          lookBackMinutes={0}
        />
      )}
    </>
  );
}

import {
  Avatar,
  Button,
  Paper,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { useSnackbar } from '../Snackbar';
import {
  FETCH_OBJECTIVE,
  FETCH_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE,
  CREATE_OBJECTIVE,
  DELETE_OBJECTIVE,
  FETCH_BRIEFS,
  FETCH_FEATURE,
  FETCH_FEATURES,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
} from '../../actions';
import { doesIdExist } from '../../apis/utilities';
import {
  featureSubtypes,
  objectiveTypes,
  locationTypes,
} from '../../data/constants';
import { ConfirmationDialog } from '../dialogs';
import {
  Field,
  TextField,
  SelectField,
  ContentField,
  DateField,
  TypesField,
  SelectMultipleField,
  GeometryField,
  ToggleListField,
  required,
  requiredInclusiveDateAfter,
  requiredInclusiveDateBefore,
  validateIdentifier,
} from '../fields';
import { BoundaryMap, RouteLeavingGuard } from '../controls';

const {
  maxUploadSize,
  occurrenceNumberOnObjectives,
  personGroups,
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  cardContent: {
    padding: 0,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subHeader: {
    marginTop: 10,
  },
  editor: {
    width: '100%',
    marginBottom: 16,
  },
  edits: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  briefs: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const mutators = {
  clearValue: ([name], state, { changeValue }) => {
    changeValue(state, name, () => undefined);
  },
  resetFilter: ({ 1: name }, state, { changeValue }) => {
    function wipeSelections(filter) {
      delete filter.value;
      return filter;
    }

    changeValue(state, name, wipeSelections);
  },
};

function TypeFields({ type }) {
  const classes = useStyles();

  switch (type) {
    case 'Patrol':
      return (
        <Field
          name="complianceSeconds"
          component={TextField}
          type="number"
          label="Compliant Minutes"
          placeholder="0"
          className={classes.textField}
          format={(value) => (value == null || value === '' ? '' : value / 60)}
          parse={(value) => (value === '' ? '' : value * 60)}
          inputProps={{ min: 0 }}
          validate={required}
        />
      );
    case 'Visit':
      return (
        <Fragment>
          <Field
            name="requiredVisits"
            component={TextField}
            type="number"
            label="Required Visits"
            className={classes.textField}
          />
          <Field
            name="requiredFrequency"
            component={SelectField}
            label="Required Frequency"
            className={classes.textField}
            validate={required}
            values={['total', 'daily', 'hourly'].map((item) => ({
              value: item,
              label: _.startCase(item),
            }))}
          />
        </Fragment>
      );
    default:
      return '';
  }
}

function BoundaryFields({ type, subtype, mutators }) {
  const dispatch = useDispatch();
  const locationNames = useSelector(
    (state) => state.locations.locationNames,
    _.isEqual
  );
  const featureNames = useSelector(
    (state) => state.features.featureNames,
    _.isEqual
  );
  const classes = useStyles();

  useEffect(() => {
    if (featureNames.length === 0) {
      dispatch({
        type: FETCH_FEATURES,
        payload: 'Perimeter',
      });
    }
  }, [featureNames, dispatch]);

  function getBoundarySubtypes(type) {
    switch (type) {
      case 'Perimeter':
        return Object.entries(featureSubtypes['perimeters']).map((item) => {
          return { value: item[0], label: item[1] };
        });
      case 'Location':
        return Object.values(locationTypes).map((item) => {
          return { value: item.name, label: item.name };
        });
      default:
        return [];
    }
  }

  function getBoundaries(type, subtype) {
    switch (type) {
      case 'Perimeter':
        return (featureNames || [])
          .filter((feature) => feature.subtype === subtype)
          .map((item) => {
            return {
              value: item.identifier,
              label: item.title || item.identifier,
            };
          });
      case 'Location':
        return (locationNames || []).map((item) => {
          return { value: item.code, label: item.name };
        });
      default:
        return [];
    }
  }

  function handleSubtypeChange(event) {
    mutators.clearValue('boundaryIdentifier');

    if (type === 'Location') {
      dispatch({
        type: FETCH_LOCATIONS,
        payload: event.target.value,
      });
    }
  }

  function handleIdentifierChange(event) {
    switch (type) {
      case 'Location':
        dispatch({
          type: FETCH_LOCATION,
          payload: event.target.value,
        });
        break;
      case 'Perimeter':
        dispatch({
          type: FETCH_FEATURE,
          payload: event.target.value,
        });
        break;
      default:
        break;
    }
  }

  return (
    <Fragment>
      <Field
        name="boundarySubtype"
        component={SelectField}
        label="Subtype"
        className={classes.textField}
        validate={required}
        values={getBoundarySubtypes(type)}
        onChange={handleSubtypeChange}
      />
      <Field
        name="boundaryIdentifier"
        component={SelectField}
        label="Name"
        values={getBoundaries(type, subtype)}
        className={classes.textField}
        validate={required}
        onChange={handleIdentifierChange}
      />
    </Fragment>
  );
}

function BoundaryField({ type }) {
  const location = useSelector((state) => state.locations.location, _.isEqual);
  const feature = useSelector((state) => state.features.feature, _.isEqual);

  function getBoundary(type) {
    switch (type) {
      case 'Perimeter':
        return (feature || {}).geometry;
      case 'Location':
        return (location || {}).boundary;
      default:
        return null;
    }
  }

  const boundary = getBoundary(type);

  return type === 'Custom' ? (
    <Field
      name="boundary"
      component={GeometryField}
      geoType="Polygon"
      validate={required}
    />
  ) : boundary ? (
    <BoundaryMap boundary={boundary} />
  ) : (
    <Fragment />
  );
}

export default function Objective() {
  const history = useHistory();
  const { id, objectiveType } = useParams();
  const dispatch = useDispatch();
  const objective = useSelector(
    (state) => state.objectives.objective,
    _.isEqual
  );
  const error = useSelector((state) => state.objectives.error);
  const briefs = useSelector((state) => state.briefs.briefNames, _.isEqual);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  useEffect(() => {
    if (id === 'new') {
      dispatch({
        type: FETCH_OBJECTIVE_SUCCESS,
        payload: null,
      });
    } else {
      dispatch({
        type: FETCH_OBJECTIVE,
        payload: id,
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (briefs.length === 0) {
      dispatch({
        type: FETCH_BRIEFS,
      });
    }
  }, [briefs, dispatch]);

  useEffect(() => {
    if (
      objective &&
      objective.boundaryType === 'Location' &&
      objective.boundarySubtype
    ) {
      dispatch({
        type: FETCH_LOCATIONS,
        payload: objective.boundarySubtype,
      });

      if (objective.boundaryIdentifier) {
        dispatch({
          type: FETCH_LOCATION,
          payload: objective.boundaryIdentifier,
        });
      }
    }

    if (
      objective &&
      objective.boundaryType === 'Perimeter' &&
      objective.boundaryIdentifier
    ) {
      dispatch({
        type: FETCH_FEATURE,
        payload: objective.boundaryIdentifier,
      });
    }
  }, [objective, dispatch]);

  function handleDelete() {
    if (objective) {
      dispatch({
        type: DELETE_OBJECTIVE,
        payload: objective.identifier,
      });
    }
  }

  async function validate(values) {
    const errors = {};

    if (!objective) {
      if (values.identifier) {
        errors.identifier = validateIdentifier(values.identifier);

        const exists = await doesIdExist('objectives', values.identifier);
        if (exists) {
          errors.identifier = 'Exists';
        }
      }
    }

    const blob = new Blob([JSON.stringify(values)], {
      type: 'application/json',
    });

    if (blob.size > maxUploadSize) {
      snackbar.notify('warning', 'Unable to save, content greater than 5MB');
      errors.description = 'Content greater than 5MB';
    }

    // start/end validation
    errors.startTime = requiredInclusiveDateBefore(values.endTime)(
      values.startTime
    );
    errors.endTime = requiredInclusiveDateAfter(values.startTime)(
      values.endTime
    );

    return errors;
  }

  function onSubmit(values) {
    if (objective) {
      dispatch({
        type: UPDATE_OBJECTIVE,
        payload: values,
      });
    } else {
      dispatch({
        type: CREATE_OBJECTIVE,
        payload: values,
      });
    }
  }

  return (
    <Form
      initialValues={
        objective || {
          type: objectiveTypes[objectiveType].name,
          days: [...Array(7).keys()],
          hours: [...Array(24).keys()],
        }
      }
      validate={validate}
      mutators={mutators}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form: { mutators, reset },
        submitting,
        dirty,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Helmet>
            <title>
              IR3 | {objectiveTypes[objectiveType].name}
              {values.title ? ` | ${values.title}` : ''}
            </title>
          </Helmet>
          <Paper className={classes.card}>
            <CardHeader
              avatar={
                <Avatar aria-label="Objective" className={classes.avatar}>
                  {objectiveTypes[objectiveType].icon}
                </Avatar>
              }
              title={values.title}
              subheader={values.identifier}
            />
            <CardContent className={classes.cardContent}>
              {values.created && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Created by ${values.created.userId} ${moment(
                      values.created.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              {values.lastEdit && (
                <div className={classes.edits}>
                  <Typography variant="caption">
                    {`Last edited by ${values.lastEdit.userId} ${moment(
                      values.lastEdit.time
                    ).format('DD/MM/YYYY, HH:mm')}`}
                  </Typography>
                </div>
              )}
              <List>
                <ListSubheader disableSticky>Key Information</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="title"
                      component={TextField}
                      label="Title"
                      className={classes.textField}
                      validate={required}
                    />
                    <Field
                      name="identifier"
                      component={TextField}
                      label="Identifier"
                      className={classes.textField}
                      disabled={objective !== null}
                    />
                    {occurrenceNumberOnObjectives && (
                      <Field
                        name="occurrenceNumber"
                        component={TextField}
                        label="Occurrence Number"
                        className={classes.textField}
                      />
                    )}
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Description</ListSubheader>
                <ListItem>
                  <Field
                    name="description"
                    component={ContentField}
                    className={classes.editor}
                    validate={required}
                  />
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Active Time</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="startTime"
                      component={DateField}
                      label="Start Date"
                      className={classes.textField}
                      maxDate={values.endTime || '2100-01-01'}
                      parse={(value) =>
                        !value ? value : moment(value).startOf('day').toDate()
                      }
                    />
                    <Field
                      name="endTime"
                      component={DateField}
                      label="End Date"
                      className={classes.textField}
                      minDate={values.startTime || '1900-01-01'}
                      parse={(value) =>
                        !value ? value : moment(value).endOf('day').toDate()
                      }
                    />
                    <TypeFields type={objectiveTypes[objectiveType].name} />
                    <Field
                      name="days"
                      component={ToggleListField}
                      label="Days"
                      className={classes.textField}
                      values={['M', 'T', 'W', 'T', 'F', 'S', 'S']}
                    />
                    <Field
                      name="hours"
                      component={ToggleListField}
                      label="Hours"
                      className={classes.textField}
                      values={[...Array(24).keys()]}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Groups & Areas</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="areas"
                      types={personGroups}
                      component={TypesField}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Briefs</ListSubheader>
                <ListItem>
                  <div className={classes.briefs}>
                    <Field
                      name="briefs"
                      fullWidth
                      component={SelectMultipleField}
                      suggestions={briefs.map((brief) => ({
                        label: brief.title,
                        value: brief.identifier,
                      }))}
                    />
                  </div>
                </ListItem>
                <Divider />
                <ListSubheader disableSticky>Boundary</ListSubheader>
                <ListItem>
                  <div className={classes.section}>
                    <Field
                      name="boundaryType"
                      component={SelectField}
                      label="Type"
                      className={classes.textField}
                      validate={required}
                      values={['Perimeter', 'Location', 'Custom'].map(
                        (item) => {
                          return { value: item, label: item };
                        }
                      )}
                      onChange={() => {
                        mutators.clearValue('boundarySubtype');
                        mutators.clearValue('boundaryIdentifier');
                        mutators.clearValue('boundary');
                      }}
                    />
                    {values.boundaryType !== 'Custom' && (
                      <BoundaryFields
                        type={values.boundaryType}
                        subtype={values.boundarySubtype}
                        mutators={mutators}
                      />
                    )}
                  </div>
                </ListItem>
                <BoundaryField type={values.boundaryType} />
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Button
                color="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                Save
              </Button>
              <Button
                color="primary"
                disabled={pristine || submitting}
                onClick={() => {
                  reset();

                  if (
                    objective &&
                    objective.boundaryType === 'Location' &&
                    objective.boundarySubtype
                  ) {
                    dispatch({
                      type: FETCH_LOCATIONS,
                      payload: objective.boundarySubtype,
                    });

                    if (objective.boundaryIdentifier) {
                      dispatch({
                        type: FETCH_LOCATION,
                        payload: objective.boundaryIdentifier,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_LOCATION,
                      payload: 'new',
                    });
                  }

                  if (
                    objective &&
                    objective.boundaryType === 'Perimeter' &&
                    objective.boundaryIdentifier
                  ) {
                    dispatch({
                      type: FETCH_FEATURE,
                      payload: objective.boundaryIdentifier,
                    });
                  } else {
                    dispatch({
                      type: FETCH_FEATURE,
                      payload: 'new',
                    });
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={dirty || submitting || !objective}
                onClick={() => history.push(`${id}/attendances`)}
              >
                Attendances
              </Button>
              <Button
                className={classes.delete}
                onClick={() => setDeleteOpen(true)}
                disabled={objective === null}
              >
                Delete
              </Button>
            </CardActions>
          </Paper>
          <ConfirmationDialog
            action="Delete"
            open={deleteOpen}
            itemId={values.title || values.identifier}
            onOk={handleDelete}
            onCancel={() => setDeleteOpen(false)}
          />
          <RouteLeavingGuard when={dirty} />
        </form>
      )}
    />
  );
}

import {
  RECEIVE_TELEMATICS_BOX_POLL,
  FETCH_TELEMATICS_BOX_POLLS_SUCCESS,
  FETCH_TELEMATICS_BOX_POLLS_FAILURE,
  FETCH_TELEMATICS_BOXES,
  FETCH_TELEMATICS_BOXES_SUCCESS,
  FETCH_TELEMATICS_BOXES_FAILURE,
  UPDATE_TELEMATICS_BOXES_FILTER,
  UPDATE_TELEMATICS_BOXES_SORT,
  START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  START_TELEMATICS_BOX_POLLS_STREAM_FAILURE,
  END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
  REMOVE_VEHICLE_TELEMATICS_BOX,
  REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
  UPDATE_VEHICLE_IMEI,
  UPDATE_VEHICLE_IMEI_SUCCESS,
  UPDATE_VEHICLE_IMEI_FAILURE,
  FETCH_DRIVER_ID_SUCCESS,
  UPDATE_DRIVER_ID,
  CANCEL_DRIVER_ID,
  UPDATE_DRIVER_ID_SUCCESS,
  CANCEL_DRIVER_ID_SUCCESS,
  UPDATE_DRIVER_ID_FAILURE,
  CANCEL_DRIVER_ID_FAILURE,
  FETCH_TRACKING_SUCCESS,
  UPDATE_TRACKING,
  CANCEL_TRACKING,
  UPDATE_TRACKING_SUCCESS,
  CANCEL_TRACKING_SUCCESS,
  UPDATE_TRACKING_FAILURE,
  CANCEL_TRACKING_FAILURE,
} from '../actions';
import { reduceByType } from '../apis/utilities';

const INITIAL_STATE = {
  vehiclesById: {},
  boxesByImei: {},
  socketIsSubscribed: false,
  allBoxRequestInProgress: false,
  filterSelections: {
    lastContactDays: window.config.lastContactDefaultDays || 7,
  },
  tableSort: [{ id: 'mostRecentTime', desc: false }],
  error: null,
  updatingImei: false,
};

export default function telematicsBoxesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DRIVER_ID_SUCCESS:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            driverIdRequest: false,
            driverIdEnabled: action.payload.currentlyEnabled,
            driverIdWillBeEnabled: action.payload.willBeEnabled, // undefined, true or false
          },
        },
      };
    case UPDATE_DRIVER_ID:
    case CANCEL_DRIVER_ID:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            driverIdRequest: true,
          },
        },
      };
    case UPDATE_DRIVER_ID_SUCCESS:
    case CANCEL_DRIVER_ID_SUCCESS:
    case UPDATE_DRIVER_ID_FAILURE:
    case CANCEL_DRIVER_ID_FAILURE:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            driverIdRequest: false,
          },
        },
      };
    case FETCH_TRACKING_SUCCESS:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            trackingRequest: false,
            trackingEnabled: action.payload.currentlyEnabled,
            trackingWillBeEnabled: action.payload.willBeEnabled, // undefined, true or false
          },
        },
      };
    case UPDATE_TRACKING:
    case CANCEL_TRACKING:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            trackingRequest: true,
          },
        },
      };
    case UPDATE_TRACKING_SUCCESS:
    case CANCEL_TRACKING_SUCCESS:
    case UPDATE_TRACKING_FAILURE:
    case CANCEL_TRACKING_FAILURE:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            trackingRequest: false,
          },
        },
      };
    case START_TELEMATICS_BOX_POLLS_STREAM_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS:
    case END_TELEMATICS_BOX_POLLS_STREAM_SUCCESS:
      return {
        ...state,
        socketIsSubscribed:
          action.type === START_TELEMATICS_BOX_POLLS_STREAM_SUCCESS,
      };
    case RECEIVE_TELEMATICS_BOX_POLL:
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            polls: [
              action.payload.poll,
              ...(state.boxesByImei[action.payload.imei]?.polls || []).filter(
                (p) => p.identifier !== action.payload.poll.identifier
              ),
            ],
          },
        },
      };
    case FETCH_TELEMATICS_BOXES:
      return {
        ...state,
        allBoxRequestInProgress: true,
      };
    case FETCH_TELEMATICS_BOXES_SUCCESS:
      // don't wipe any existing polls (subscribe & get polls could happen before this returns)
      const { boxesByImei, vehiclesById } = action.payload;
      Object.keys(boxesByImei).forEach((imei) =>
        // old way
        // (boxesByImei[imei].polls =
        //   imei in state.boxesByImei ? state.boxesByImei[imei].polls : [])
        {
          const existing = state.boxesByImei[imei];

          boxesByImei[imei] = {
            ...boxesByImei[imei],
            polls: existing?.polls || [],
            // TEMPJL
            driverIdEnabled: existing?.driverIdEnabled,
          };
        }
      );
      return {
        ...state,
        boxesByImei,
        vehiclesById,
        allBoxRequestInProgress: false,
      };
    case FETCH_TELEMATICS_BOXES_FAILURE:
      return {
        ...state,
        boxesByImei: {},
        allBoxRequestInProgress: false,
        error: action.payload,
      };
    case FETCH_TELEMATICS_BOX_POLLS_SUCCESS:
    case FETCH_TELEMATICS_BOX_POLLS_FAILURE:
      const oldPolls = state.boxesByImei[action.payload.imei]?.polls || [];
      const newPolls = (action.payload.polls || []).filter(
        (newPoll) =>
          !oldPolls.some((oldPoll) => oldPoll.identifier === newPoll.identifier)
      );
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [action.payload.imei]: {
            ...state.boxesByImei[action.payload.imei],
            polls:
              action.type === FETCH_TELEMATICS_BOX_POLLS_SUCCESS
                ? oldPolls.concat(newPolls)
                : [],
          },
        },
        error: action.payload.message,
      };
    case UPDATE_TELEMATICS_BOXES_FILTER:
      return {
        ...state,
        filterSelections: action.payload,
      };
    case UPDATE_TELEMATICS_BOXES_SORT:
      return {
        ...state,
        tableSort: action.payload,
      };
    case REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS:
      const { identificationNumber, previousImei } = action.payload;
      let modifiedBox = null;

      const box = state.boxesByImei[previousImei];
      if (box) {
        modifiedBox = disassociateVehicleFromBox(identificationNumber, box);

        if (modifiedBox) {
          return {
            ...state,
            boxesByImei: {
              ...state.boxesByImei,
              [previousImei]: modifiedBox,
            },
            vehiclesById: {
              ...state.vehiclesById,
              [identificationNumber]: {
                ...state.vehiclesById[identificationNumber],
                telematicsBoxImei: undefined,
              },
            },
            updatingImei: false,
          };
        }
      }

      return state;
    case REMOVE_VEHICLE_TELEMATICS_BOX:
    case UPDATE_VEHICLE_IMEI:
      return {
        ...state,
        updatingImei: true,
      };
    case UPDATE_VEHICLE_IMEI_FAILURE:
      return {
        ...state,
        updatingImei: false,
        error: action.payload,
      };
    case UPDATE_VEHICLE_IMEI_SUCCESS:
      const vehicle = action.payload;
      return {
        ...state,
        boxesByImei: {
          ...state.boxesByImei,
          [vehicle.telematicsBoxImei]: {
            ...state.boxesByImei[vehicle.telematicsBoxImei],
            ...vehicle,
            areas: reduceByType(vehicle.areas),
          },
        },
        vehiclesById: {
          ...state.vehiclesById,
          [vehicle.identificationNumber]: vehicle,
        },
        updatingImei: false,
      };
    default:
      return state;
  }
}

function disassociateVehicleFromBox(vehicleId, box) {
  // has at least 2 associated vehicles
  if (box.isMultiAssigned) {
    let vehicleIndex = box.multiAssignments.findIndex(
      (v) => v.identificationNumber === vehicleId
    );

    // if we found it
    if (vehicleIndex >= 0) {
      // remove it from the multi assigned list
      box.multiAssignments = box.multiAssignments.splice(vehicleIndex, 1);

      // there's either one left (it's no longer multi assigned)
      // or more than one left (it had more than 2 associated vehicles at start)
      // either way we need some detail to replace what was there
      box.identificationNumber = box.multiAssignments[0].identificationNumber;
      box.registrationNumber = box.multiAssignments[0].registrationNumber;
      box.fleetNumber = box.multiAssignments[0].fleetNumber;
      box.areas = reduceByType(box.multiAssignments[0].areas);

      if (box.multiAssignments.length === 1) {
        box.isMultiAssigned = false;
        box.multiAssignments = [];
      }

      return { ...box };
    }
  } else if (box.identificationNumber === vehicleId) {
    // it wasn't multiply assigned, only singly so need to wipe main details
    // as there's no associated box now
    box.identificationNumber = undefined;
    box.registrationNumber = undefined;
    box.fleetNumber = undefined;
    box.areas = {};
    box.isMultiAssigned = false;
    box.multiAssignments = [];

    return { ...box };
  }

  return false;
}

import {
  Paper,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../Snackbar';
import { Helmet } from 'react-helmet-async';
import {
  FETCH_OUTAGES,
  FETCH_OUTAGES_CANCELLED,
  UPDATE_OUTAGES_FILTER,
} from '../../actions';
import Container from '../Container';
import { FilterPicker, Parameters, TablePagination, Table } from '../controls';
import { downloadCSV } from '../../apis/utilities';
import { filterLocally } from '../../data/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  tableContainer: {
    height: 'calc(100vh - 180px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  parameters: {
    width: 280,
  },
}));

const headers = [
  { label: 'IMEI', key: 'imei', type: 'text', filter: true },
  ...(useReducedResourceInformation
    ? [
        {
          label: 'Fleet Number',
          key: 'fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Type', key: 'type', filter: true },
      ]
    : [
        {
          label: 'Registration',
          key: 'registrationNumber',
          type: 'text',
          filter: true,
        },
        {
          label: 'Fleet Number',
          key: 'fleetNumber',
          type: 'text',
          filter: true,
        },
        { label: 'Role', key: 'role', type: 'text', filter: true },
      ]),
  {
    label: 'Start Location Type',
    key: 'startLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Location Name',
    key: 'startLocationName',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Type',
    key: 'endLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Name',
    key: 'endLocationName',
    type: 'text',
    filter: true,
  },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
  },
  {
    label: 'Distance (miles)',
    key: 'distanceMiles',
    type: 'number',
    filter: false,
  },
];

export default function Outages() {
  const dispatch = useDispatch();
  const outages = useSelector((state) => state.events.outages.list, _.isEqual);
  const filter = useSelector((state) => state.events.outages.filter, _.isEqual);
  const isLoading = useSelector((state) => state.events.outages.isLoading);
  const error = useSelector((state) => state.events.outages.error);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_OUTAGES,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_OUTAGES_CANCELLED,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_OUTAGES_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  function handleDownloadClick() {
    const filename = 'Telematics Box Outages.csv';
    const data = filteredOutages.map((outage) => ({
      imei: outage.imei,
      registrationNumber: outage.registrationNumber,
      fleetNumber: outage.fleetNumber,
      role: outage.role,
      type: outage.type,
      startLocationType: outage.startLocationType,
      startLocationName: outage.startLocationName,
      endLocationType: outage.endLocationType,
      endLocationName: outage.endLocationName,
      startTime: moment(outage.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(outage.endTime).format('YYYY-MM-DD HH:mm:ss'),
      durationMinutes: _.round(outage.durationMinutes || 0.0, 2),
      distanceMiles: _.round(outage.distanceMiles || 0.0, 2),
    }));

    downloadCSV(data, filename, headers);
  }

  const filteredOutages = filterLocally(filter, outages);

  // const footer = [
  //   {
  //     value: 'Totals',
  //     type: 'text',
  //     colSpan: useReducedResourceInformation ? 9 : 10,
  //   },
  //   {
  //     value: _.round(
  //       filteredOutages
  //         .map((outage) => outage.durationMinutes)
  //         .reduce((a, b) => a + b, 0) || 0,
  //       2
  //     ),
  //     type: 'number',
  //   },
  //   {
  //     value: _.round(
  //       filteredOutages
  //         .map((outage) => outage.distanceMiles)
  //         .reduce((a, b) => a + b, 0) || 0.0,
  //       2
  //     ),
  //     type: 'number',
  //   },
  // ];

  return (
    <Container title="Outages">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading}
        className={classes.parameters}
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Outages</title>
        </Helmet>
        <Toolbar>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Outages
          </Typography>
          <FilterPicker
            headers={headers}
            data={outages}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            title="Download data"
            disabled={filteredOutages.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredOutages}
            headers={headers}
            // footer={footer}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            dense={true}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredOutages.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

import {
  Paper,
  CircularProgress,
  Collapse,
  IconButton,
  Toolbar,
  makeStyles,
  InputAdornment,
  TextField,
  Typography,
  Checkbox,
  Switch,
  Grid,
} from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import {
  Close as CloseIcon,
  FilterList as FilterListIcon,
  GetApp as GetAppIcon,
  Autorenew as AutorenewIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useSnackbar } from '../../Snackbar';
import {
  FETCH_VEHICLE_AVAILABILITY,
  FETCH_VEHICLE_AVAILABILITY_CANCELLED,
  FILTER_VEHICLE_AVAILABILITY,
  UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
  UPDATE_FILTER_END_TIME,
  UPDATE_FILTER_START_TIME,
} from '../../../actions';
import Container from '../../Container';
import {
  DatePicker,
  SelectMultiple,
  TablePagination,
  Table,
} from '../../controls';
import { downloadCSV } from '../../../apis/utilities';

const { useReducedResourceInformation, rowsPerPageOptions } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
    fontSize: 12,
  },
  filterField: {
    width: 144,
  },
  textField: {
    width: 100,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    // '& .MuiInputAdornment-positionEnd': {
    //   marginLeft: -theme.spacing(1),
    // },
  },
  progress: {
    margin: theme.spacing(1),
  },
  flex: {
    flexGrow: 1,
  },
  collapseContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 276px)',
    overflowY: 'scroll',
  },
  graphs: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 2, 2),
  },
  graphSwitch: {
    marginLeft: theme.spacing(1),
  },
}));

const useDrillDownStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const CustomAvailabilityTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const { hour, vehicleCount } = payload[0].payload;
    const hourString = hour.format('DD/MM/YYYY, HH:mm');
    const nextHour = moment(hour).add(1, 'hour');
    const nextHourString = nextHour.format(
      hour.dayOfYear() === nextHour.dayOfYear() ? 'HH:mm' : 'DD/MM/YYYY HH:mm'
    );

    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
          {`${vehicleCount} vehicle${
            vehicleCount === 1 ? '' : 's'
          } between ${hourString} and ${nextHourString}`}
        </p>
      </div>
    );
  }

  return null;
};

export default function Availability() {
  const dispatch = useDispatch();
  const homeOnly = useSelector(
    (state) => state.reports.vehicleAvailability.homeOnly
  );
  const data = useSelector(
    (state) => state.reports.vehicleAvailability.filteredData,
    _.isEqual
  );
  const isLoading = useSelector(
    (state) => state.reports.vehicleAvailability.isLoading
  );
  const error = useSelector((state) => state.reports.vehicleAvailability.error);
  const filter = useSelector(
    (state) => state.reports.vehicleAvailability.filter,
    _.isEqual
  );
  const filterOptions = useSelector(
    (state) => state.reports.vehicleAvailability.filterOptions,
    _.isEqual
  );
  const startTime = useSelector((state) => state.filters.startTime, _.isEqual);
  const endTime = useSelector((state) => state.filters.endTime, _.isEqual);
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Group');
  const [customConfidence, setCustomConfidence] = useState({
    error: false,
    curr: 0,
    next: 0,
  });
  const [timeDescendingChart, setTimeDescendingChart] = useState(true);
  const classes = useStyles();
  const snackbar = useSnackbar();

  function percentValue(value) {
    return _.round(100 * value, 2);
  }

  function percentString(value) {
    return `${percentValue(value)}%`;
  }

  function handleGraphSwitch() {
    setTimeDescendingChart(!timeDescendingChart);
  }

  function DrillDown({ entry: { histogram, availabilities } }) {
    const classes = useDrillDownStyles();
    let mainChartData = histogram;

    if (timeDescendingChart) {
      // do a descending subtractive sum, so instead of
      // 0 for 1 hour, 1 for 3 hours, 2 for 1 hour
      // we get
      // 0 or more for 5 hours, 1 or more for 4 hours, 2 or more for 1 hour
      // turn it into a percentage altogether
      // 0 or more 100% (5/5), 1 or more 80% (4/5), 2 or more 20% (1/5)
      const overallTotal = histogram.reduce((total, h) => total + h.hours, 0);
      // remove the one for 0 as it's redundant...
      mainChartData = histogram
        .filter(({ vehicleCount }) => vehicleCount - 0 !== 0)
        .map(({ vehicleCount }) => ({
          vehicleCount,
          hours: percentValue(
            histogram.reduce(
              (total, h) =>
                total + h.hours * (h.vehicleCount - vehicleCount >= 0),
              0
            ) / overallTotal
          ),
        }));
    }

    return (
      <div className={classes.graphs}>
        <Typography component="div" className={classes.graphSwitch}>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Availability percent</Grid>
            <Grid item>
              <Switch
                checked={!timeDescendingChart}
                onChange={handleGraphSwitch}
              ></Switch>
            </Grid>
            <Grid item>Histogram</Grid>
          </Grid>
        </Typography>
        {/* <Typography className={classes.cardHeader} variant="subtitle1">
          Histogram
        </Typography>
        <Switch checked={timeDescendingChart} onChange={handleGraphSwitch}></Switch> */}
        <ResponsiveContainer width="99%" height={400}>
          <BarChart
            data={mainChartData}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            barCategoryGap={4}
          >
            <XAxis
              dataKey="vehicleCount"
              // angle={-90}
              // textAnchor="end"
              interval={0}
            >
              <Label value="Vehicles available" offset={6} position="bottom" />
            </XAxis>
            <YAxis>
              <Label
                value={timeDescendingChart ? 'Percent' : 'Total Hours'}
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <Tooltip
              cursor={false}
              content={<CustomHistogramTooltip data={histogram} />}
            />
            <Bar dataKey="hours" fill={teal[500]} isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="99%" height={200}>
          <BarChart
            data={availabilities}
            margin={{ top: 0, right: 16, left: 0, bottom: 32 }}
            // barCategoryGap={4}
          >
            <XAxis
              dataKey="hour"
              // angle={-90}
              textAnchor="end"
              // interval={0}
            >
              <Label value="Time" offset={-6} position="bottom" />
            </XAxis>
            <YAxis>
              <Label
                value="Vehicles"
                angle={-90}
                position="left"
                offset={-24}
              />
            </YAxis>
            <Tooltip cursor={false} content={<CustomAvailabilityTooltip />} />
            <Bar
              dataKey="vehicleCount"
              fill={teal[500]}
              isAnimationActive={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  const CustomHistogramTooltip = ({ active, payload, label, data }) => {
    if (active) {
      const totalHours = data.reduce((total, el) => total + el.hours, 0);
      const atLeast = data.reduce(
        (total, el) => total + (el.vehicleCount - label >= 0 ? el.hours : 0),
        0
      );
      const value = data.find((d) => d.vehicleCount - label === 0)?.hours || 0;
      const more =
        label - 0 !== Math.max(...data.map((d) => parseInt(d.vehicleCount)));

      return (
        <div
          className="recharts-default-tooltip"
          style={{
            margin: '0px',
            padding: '10px',
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap',
          }}
        >
          {!timeDescendingChart ? (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`Exactly ${label} vehicle${label === '1' ? ' was' : 's were'}
              available for ${value} out of ${totalHours} hours or 
              ${percentString(value / totalHours)} of the time.`}
            </p>
          ) : (
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
              {`${label} ${
                more ? 'or more' : ''
              } vehicles were available ${percentString(
                atLeast / totalHours
              )} of the time`}
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  const dropdownHeader = [
    {
      label: '',
      key: 'expand',
      type: 'expand',
      component: DrillDown,
    },
  ];

  const headers = [
    {
      label: 'Location',
      key: 'location',
      type: 'text',
    },
    {
      label: 'Location Type',
      key: 'locationType',
      type: 'text',
    },
    {
      label: useReducedResourceInformation ? 'Vehicle Type' : 'Role',
      key: 'vehicleGrouping',
      type: 'text',
    },
    customConfidence.curr !== 0 && {
      label: `${customConfidence.curr}%`,
      key: 'pCustom',
      type: 'number',
    },
    {
      label: '95%',
      key: 'p95',
      type: 'number',
    },
    {
      label: '97.5%',
      key: 'p975',
      type: 'number',
    },
    {
      label: '99%',
      key: 'p99',
      type: 'number',
    },
    {
      label: 'Mean',
      key: 'mean',
      type: 'number',
    },
    {
      label: 'Deviation',
      key: 'std',
      type: 'number',
    },
  ].filter(Boolean);

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFilterToggle() {
    setShowFilter(!showFilter);
  }

  function handleRefreshClick() {
    if (isLoading) {
      dispatch({
        type: FETCH_VEHICLE_AVAILABILITY_CANCELLED,
      });
    } else {
      dispatch({
        type: FETCH_VEHICLE_AVAILABILITY,
        payload: {
          startTime,
          endTime,
          filter,
          homeOnly,
          customConfidence: customConfidence.next,
        },
      });

      setCustomConfidence({
        ...customConfidence,
        curr: customConfidence.next,
      });
    }
  }

  function handleFilterFieldChanged(name, value) {
    const newFilter =
      name in filter
        ? {
            ...filter,
            [name]: (value || []).map((value) => value.value),
          }
        : {
            ...filter,
            areas: {
              ...filter.areas,
              [name]: (value || []).map((value) => value.value),
            },
          };

    dispatch({
      type: FILTER_VEHICLE_AVAILABILITY,
      payload: { filter: newFilter },
    });
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOrderChange(order) {
    setOrder(order);
  }

  function handleOrderByChange(orderBy) {
    setOrderBy(orderBy);
    setOrder('asc');
  }

  async function handleDownloadClick() {
    const filename = 'Vehicle Availability.csv';

    downloadCSV(data, filename, headers);
  }

  // const xAxisHeight = 16;
  // data.length === 0
  //   ? 0
  //   : getTextWidth(
  //       data.map((item) => item.Group).sort((a, b) => b.length - a.length)[0],
  //       '12px Roboto'
  //     ) + 16;

  function handleCustomConfidenceChange(event) {
    const string = event.target.value;
    const value = /^-?[\d]*(\.[\d]+)?$/g.test(string) && parseFloat(string);

    if (value && 0 < value && value < 100) {
      setCustomConfidence({
        error: false,
        next: value,
        curr: customConfidence.curr,
      });
    } else {
      setCustomConfidence({
        error: !!string, // if it's blank don't show an error
        next: 0,
        curr: customConfidence.curr,
      });
    }
  }

  function handleHomeOnlyChanged(event) {
    dispatch({
      type: UPDATE_VEHICLE_AVAILABILITY_HOME_ONLY,
      payload: event.target.checked,
    });
  }

  return (
    <Container title="Availability">
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Availability</title>
        </Helmet>
        <Toolbar>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Custom Confidence
          </Typography>
          <TextField
            type="number"
            error={customConfidence.error}
            onChange={handleCustomConfidenceChange}
            className={classes.textField}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <Checkbox checked={homeOnly} onChange={handleHomeOnlyChanged} />
          <Typography className={classes.cardHeader} variant="subtitle1">
            {`${
              useReducedResourceInformation ? 'Home Location' : 'Home Station'
            } only`}
          </Typography>
          <div className={classes.flex} />
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={16}
              thickness={6}
            />
          )}
          <DatePicker
            value={startTime}
            onChange={(date) =>
              dispatch({
                type: UPDATE_FILTER_START_TIME,
                payload: date,
              })
            }
            clearable
            className={classes.filterField}
            maxDate={endTime || '2100-01-01'}
          />
          &nbsp;-&nbsp;
          <DatePicker
            value={endTime}
            onChange={(date) =>
              dispatch({
                type: UPDATE_FILTER_END_TIME,
                payload: date,
              })
            }
            clearable
            className={classes.filterField}
            minDate={startTime || '1900-01-01'}
            disableFuture
          />
          <IconButton
            title={isLoading ? 'Cancel' : 'Fetch'}
            onClick={handleRefreshClick}
          >
            {isLoading ? <CloseIcon color="error" /> : <AutorenewIcon />}
          </IconButton>
          <IconButton
            title={showFilter ? 'Hide filter' : 'Show filter'}
            onClick={handleFilterToggle}
          >
            <FilterListIcon color={showFilter ? 'primary' : 'inherit'} />
          </IconButton>
          <IconButton
            title="Download data"
            disabled={data.length === 0}
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Collapse in={showFilter} timeout="auto">
          <div className={classes.collapseContainer}>
            <SelectMultiple
              fullWidth
              label="Locations"
              placeholder="Select..."
              value={filter.location?.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) => handleFilterFieldChanged('location', value)}
              suggestions={filterOptions.location?.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              fullWidth
              label="Location Types"
              placeholder="Select..."
              value={filter.locationType?.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('locationType', value)
              }
              suggestions={filterOptions.locationType?.map((value) => ({
                label: value,
                value,
              }))}
            />
            <SelectMultiple
              fullWidth
              label={useReducedResourceInformation ? 'Types' : 'Roles'}
              placeholder="Select..."
              value={filter.vehicleGrouping?.map((value) => ({
                label: value,
                value,
              }))}
              onChange={(value) =>
                handleFilterFieldChanged('vehicleGrouping', value)
              }
              suggestions={filterOptions.vehicleGrouping?.map((value) => ({
                label: value,
                value,
              }))}
            />
          </div>
        </Collapse>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={data}
            headers={[...dropdownHeader, ...headers]}
            rowsPerPage={rowsPerPage}
            page={page}
            keyName="stopKey"
            dense={true}
            order={order}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

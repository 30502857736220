import { IconButton, makeStyles } from '@material-ui/core';
import {
  Map as MapIcon,
  Satellite as SatelliteIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { Map, View, Feature } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { click, pointerMove } from 'ol/events/condition';
import { applyTransform, createEmpty, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import {
  defaults as defaultInteractions,
  DoubleClickZoom as DoubleClickZoomInteraction,
  Draw as DrawInteraction,
  Select as SelectInteraction,
} from 'ol/interaction';
import {
  Group as LayerGroup,
  Heatmap as HeatmapLayer,
  Vector as VectorLayer,
  VectorImage as VectorImageLayer,
} from 'ol/layer';
import { getTransform } from 'ol/proj';
import { Cluster as ClusterSource, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import React, { useEffect, useRef, useState } from 'react';
import { hexToRgba, retrospectiveTypeGlyphs } from '../../data/constants';
// import { useDebounce } from '../../hooks';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { setBaseLayers } from '../../mapStyles';
import { orderAndFilterFeatures, tooManyMapItems } from './constants';

const { mapExtent, layerSwitcher } = window.config;

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: '#fff',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  zoomInButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 100,
  },
  zoomOutButton: {
    position: 'absolute',
    top: 40,
    left: 5,
    zIndex: 100,
  },
  fitButton: {
    position: 'absolute',
    top: 75,
    left: 5,
    zIndex: 100,
  },
  labelButton: {
    position: 'absolute',
    top: 110,
    left: 5,
    zIndex: 100,
  },
  layerButton: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    zIndex: 100,
  },
});

function getSelectStyle(feature) {
  const geometry = feature.getGeometry();
  const count = feature.get('count');
  const type = geometry ? geometry.getType() : null;

  switch (type) {
    case 'Point':
      return [
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgb(33,150,243)',
            }),
          }),
          text: count ? new Text({
              font: '10px Roboto,sans-serif',
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: '#FFFFFF',
              }),
              text: `${count}`,
              textAlign: 'left',
              offsetX: 10
            }) : undefined,
        }),
        new Style({
          image: new Icon({
            src: retrospectiveTypeGlyphs[feature.get('source')],
            color: 'rgb(255,255,255)',
            scale: 0.5,
          }),
        }),
      ];
    case 'LineString':
      const coordinates = feature.getGeometry().getCoordinates();

      return [
        new Style({
          stroke: new Stroke({
            color: 'rgb(33,150,243)',
            width: 4,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgba(76,175,80,1)',
            }),
          }),
          geometry: new Point(coordinates[0]),
        }),
        new Style({
          image: new Icon({
            src: retrospectiveTypeGlyphs.pathStart,
            color: 'rgb(255,255,255)',
            scale: 0.5,
          }),
          geometry: new Point(coordinates[0]),
        }),
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgba(244,67,54,1)',
            }),
          }),
          geometry: new Point(coordinates[coordinates.length - 1]),
        }),
        new Style({
          image: new Icon({
            src: retrospectiveTypeGlyphs.pathEnd,
            color: 'rgb(255,255,255)',
            scale: 0.5,
          }),
          geometry: new Point(coordinates[coordinates.length - 1]),
        }),
      ];
    case 'Polygon':
      return new Style({
        fill: new Fill({
          color: 'rgba(33,150,243,0.5)',
        }),
        stroke: new Stroke({
          color: 'rgb(33,150,243)',
          width: 2,
        }),
      });
    default:
      return null;
  }
}

function getHoverStyle(feature) {
  const geometry = feature.getGeometry();
  const type = geometry ? geometry.getType() : null;

  switch (type) {
    case 'Point':
      const id = feature.getId();
      const count = feature.get('count');

      if (Number.isInteger(id)) {
        return [
          new Style({
            image: new Circle({
              radius: 8,
              fill: new Fill({
                color: 'rgb(255,235,59)',
              }),
            }),
            text: count ? new Text({
              font: '10px Roboto,sans-serif',
              fill: new Fill({
                color: '#000000',
              }),
              stroke: new Stroke({
                color: '#FFFFFF',
              }),
              text: `${count}`,
              textAlign: 'left',
              offsetX: 10
            }) : undefined,
          }),
          new Style({
            image: new Icon({
              src: retrospectiveTypeGlyphs[feature.get('source')],
              color: 'rgb(0,0,0)',
              scale: 0.5,
            }),
          }),
        ];
      } else {
        const features = feature.get('features');

        if (features.length === 1) {
          return [
            new Style({
              image: new Circle({
                radius: 8,
                fill: new Fill({
                  color: 'rgb(255,235,59)',
                }),
              }),
            }),
            new Style({
              image: new Icon({
                src: retrospectiveTypeGlyphs[features[0].get('source')],
                color: 'rgb(0,0,0)',
                scale: 0.5,
              }),
            }),
          ];
        } else {
          return new Style({
            image: new Circle({
              // radius: Math.round(
              //   // prettier-ignore
              //   4 + ((layer.growth || 0.1) * features.length)
              // ),
              radius: 8 + 0.1 * features.length,
              fill: new Fill({
                color: 'rgb(255,235,59)',
              }),
            }),
            text: new Text({
              font: '8px Roboto,sans-serif',
              fill: new Fill({
                color: '#00000',
              }),
              text: `${features.length}`,
              textAlign: 'center',
            }),
          });
        }
      }
    case 'LineString':
      const coordinates = feature.getGeometry().getCoordinates();

      return [
        new Style({
          stroke: new Stroke({
            color: 'rgb(255,235,59)',
            width: 4,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgba(76,175,80,1)',
            }),
          }),
          geometry: new Point(coordinates[0]),
        }),
        new Style({
          image: new Icon({
            src: retrospectiveTypeGlyphs.pathStart,
            color: 'rgb(255,255,255)',
            scale: 0.5,
          }),
          geometry: new Point(coordinates[0]),
        }),
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({
              color: 'rgba(244,67,54,1)',
            }),
          }),
          geometry: new Point(coordinates[coordinates.length - 1]),
        }),
        new Style({
          image: new Icon({
            src: retrospectiveTypeGlyphs.pathEnd,
            color: 'rgb(255,255,255)',
            scale: 0.5,
          }),
          geometry: new Point(coordinates[coordinates.length - 1]),
        }),
      ];
    case 'Polygon':
      return new Style({
        fill: new Fill({
          color: 'rgba(255,235,59,0.5)',
        }),
        stroke: new Stroke({
          color: 'rgb(255,235,59)',
          width: 2,
        }),
      });
    default:
      return null;
  }
}

function getBoundaryStyle(feature) {
  const colors = feature.get('colors');

  return new Style({
    stroke: new Stroke({
      color: colors.length > 1 ? 'white' : colors[0],
      width: 2,
    }),
  });
}

function heatWeightForCollection(featureCollection) {
  const maxCount = Math.max.apply(
    Math,
    featureCollection.features.map(function (f) {
      return f?.properties?.count || 0;
    })
  );

  function heatWeight(feature) {
    const min = 0.1;
    // return min + ((feature.get('count') || 0) / maxCount)*(1-min);
    return (
      min + -Math.log2(1 - (feature.get('count') || 0) / maxCount) * (1 - min)
    );
  }

  return maxCount ? heatWeight : undefined;
}

function toMapFeature(feature, id) {
  const { geometry, properties } = feature;
  feature = new Feature({
    ...properties,
    geometry: new GeoJSON().readGeometry(geometry, {
      featureProjection: 'EPSG:3857',
    }),
  });

  feature.setId(id);

  return feature;
}

export default function RetrospectiveMap({
  layers,
  onHover,
  hoveredItemIndex,
  onSelect,
  selectedItemIndex,
  hiddenLayerIndexes,
  drawIndex,
  onDrawEnd,
  expandedLayerIndex,
}) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  // const [showLabels, setShowLabels] = useState(false);
  const [satelliteMapVisible, setSatelliteMapVisible] = useState(false);
  const [geometry, setGeometry] = useState(null);
  const [layerCache, setLayerCache] = useState({});
  const mapDiv = useRef(null);
  const map = useRef(null);
  const boundaryLayer = useRef(new VectorLayer({ style: getBoundaryStyle }));
  const itemLayerGroup = useRef(new LayerGroup());
  const select = useRef(null);
  const hover = useRef(null);
  const hoverSource = useRef(null);
  const selectSource = useRef(null);
  const draw = useRef(null);
  const doubleClickZoom = useRef(null);
  const styleCache = useRef({});
  const classes = useStyles();
  // const layers = useDebounce(layers, 1000);

  useEffect(() => {
    let cacheChange = false;

    function getCachedLayer(layer, index) {
      if (!layer.featureCollection || hiddenLayerIndexes.includes(index)) {
        return new VectorLayer();
      }

      // certain properties won't change a map layer such as the label, server-side filters
      // so omit these and compare the rest when deciding to create a new map layer
      const mapParams = _.pick(layer, [
        'virtualize',
        'window',
        'featureCollection',
        'clientFilters',
        'sort',
        'searchText',
        'type',
        'colors',
        'distance',
        'growth',
        'blur',
        'radius',
      ]);
      if (!_.isEqual(layerCache[index]?.mapParams, mapParams)) {
        const orderedFilteredFeatures = orderAndFilterFeatures(layer);
        layerCache[index] = {
          mapParams,
          mapLayer: getLayer(layer, index, orderedFilteredFeatures),
          orderedFilteredFeatures,
        };
        cacheChange = true;
      }

      return layerCache[index].mapLayer;
    }

    function getLayer(layer, index, orderedFilteredFeatures) {
      // if (!layer.featureCollection || hiddenLayerIndexes.includes(index)) {
      //   console.log(`layer ${index} has no features or is hidden`);
      //   return new VectorLayer();
      // }
      const shouldVirtualize =
        layer.virtualize === undefined
          ? tooManyMapItems(layer)
          : layer.virtualize;

      const featureCollection = {
        type: layer.featureCollection.type,
        features:
          // if we're virtualizing and have a window use that
          shouldVirtualize && layer.window?.length > 0
            ? layer.window
            : orderedFilteredFeatures
                // if we are virtualising but don't have a window yet, pick the top 10
                .slice(
                  shouldVirtualize ? 0 : undefined,
                  shouldVirtualize ? 10 : undefined
                )
                .map((feature, index) => ({ ...feature, id: index })),
      };

      switch (layer.type) {
        case 'shape':
          return new VectorImageLayer({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            style: new Style({
              image: new Circle({
                radius: 4,
                fill: new Fill({
                  color: layer.colors[0],
                }),
              }),
              fill: new Fill({
                color: hexToRgba(layer.colors[0], 0.5),
              }),
              stroke: new Stroke({
                color: layer.colors[0],
                width: 2,
              }),
            }),
            zIndex: index,
          });
        case 'bubble':
          return new VectorLayer({
            source: new ClusterSource({
              source: new VectorSource({
                features: new GeoJSON().readFeatures(featureCollection, {
                  featureProjection: 'EPSG:3857',
                }),
              }),
              distance: Math.max(
                layer.distance ? parseInt(layer.distance) : 10,
                0
              ),
              geometryFunction: (feature) => feature?.getGeometry() || null,
            }),
            style: (feature) => {
              const size = feature.get('features').length;
              if (!styleCache.current[layer.colors[0]]) {
                styleCache.current[layer.colors[0]] = {};
              }

              if (!styleCache.current[layer.colors[0]][layer.growth]) {
                styleCache.current[layer.colors[0]][layer.growth] = {};
              }

              if (!styleCache.current[layer.colors[0]][layer.growth][size]) {
                styleCache.current[layer.colors[0]][layer.growth][
                  size
                ] = new Style({
                  image: new Circle({
                    radius: Math.max(
                      Math.round(
                        // prettier-ignore
                        4 + ((layer.growth || 0.1) * size)
                      ),
                      0
                    ),
                    fill: new Fill({
                      color: layer.colors[0],
                    }),
                  }),
                });
              }

              return styleCache.current[layer.colors[0]][layer.growth][size];
            },
            zIndex: index,
          });
        case 'heat':
          return new HeatmapLayer({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            blur: layer.blur ? Math.max(parseInt(layer.blur), 0) : 15,
            radius: layer.radius ? Math.max(parseInt(layer.radius), 0) : 8,
            // weight: () => Math.random(), //heatWeightForCollection(featureCollection),
            weight: heatWeightForCollection(featureCollection),
            gradient:
              layer.colors && layer.colors.length > 1
                ? layer.colors
                : ['white', 'black'],
            zIndex: index,
          });
        case 'area':
          return new VectorLayer({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            style: (feature) => {
              const quantile = feature.get('quantile');
              if (!styleCache.current[layer.colors[0]]) {
                styleCache.current[layer.colors[0]] = {};
              }

              if (!styleCache.current[layer.colors[0]][quantile]) {
                styleCache.current[layer.colors[0]][quantile] = new Style({
                  fill: new Fill({
                    color: hexToRgba(layer.colors[0], quantile),
                  }),
                  stroke: new Stroke({
                    color: hexToRgba(layer.colors[0], quantile),
                    width: 2,
                  }),
                });
              }

              return styleCache.current[layer.colors[0]][quantile];
            },
            zIndex: index,
          });
        default:
          return null;
      }
    }

    const boundaryFeatureCollection = {
      type: 'FeatureCollection',
      features: layers.map((layer, index) => ({
        type: 'Feature',
        id: index,
        geometry: !hiddenLayerIndexes.includes(index)
          ? layer.boundaryGeometry
          : undefined,
        properties: {
          colors: layer.colors,
        },
      })),
    };

    boundaryLayer.current.setSource(
      new VectorSource({
        features: new GeoJSON().readFeatures(boundaryFeatureCollection, {
          featureProjection: 'EPSG:3857',
        }),
      })
    );

    // const itemLayers = layers.map(getLayer);
    const itemLayers = layers.map(getCachedLayer);

    itemLayerGroup.current.getLayers().clear();

    if (itemLayers.length > 0) {
      itemLayerGroup.current.getLayers().extend(itemLayers);
    }

    if (cacheChange) {
      setLayerCache({ ...layerCache });
    }
  }, [layers, hiddenLayerIndexes, layerCache]);

  useEffectOnce(() => {
    select.current = new SelectInteraction({
      layers: itemLayerGroup.current.getLayers().getArray(),
      style: getSelectStyle,
      condition: click,
    });
    select.current.on('select', handleSelect);

    hover.current = new SelectInteraction({
      layers: itemLayerGroup.current.getLayers().getArray(),
      style: getHoverStyle,
      condition: pointerMove,
    });
    const debouncedHover = _.debounce(handleHover, 150);
    hover.current.on('select', debouncedHover);

    hoverSource.current = new VectorSource();
    const hoverLayer = new VectorLayer({
      source: hoverSource.current,
      style: getHoverStyle,
    });
    selectSource.current = new VectorSource();
    const selectLayer = new VectorLayer({
      source: selectSource.current,
      style: getSelectStyle,
    });

    doubleClickZoom.current = new DoubleClickZoomInteraction();

    draw.current = new DrawInteraction({
      // source: new VectorSource({wrapX: false}),
      type: 'Polygon',
    });
    draw.current.on('drawend', handleDrawEnd);

    map.current = new Map({
      target: mapDiv.current,
      layers: [
        boundaryLayer.current,
        itemLayerGroup.current,
        hoverLayer,
        selectLayer,
      ],
      interactions: defaultInteractions({
        doubleClickZoom: false,
        pinchRotate: false,
        altShiftDragRotate: false,
      }).extend([doubleClickZoom.current, select.current, hover.current]),
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
    });

    setBaseLayers(map.current);

    fitMap();
  });

  useEffect(() => {
    selectSource.current.clear();

    if (
      selectedItemIndex.itemIndex &&
      itemLayerGroup.current.getLayers().getLength() > 0 &&
      !hiddenLayerIndexes.includes(selectedItemIndex.layerIndex)
    ) {
      const { layerIndex, itemIndex } = selectedItemIndex;
      const feature = layerCache[layerIndex].orderedFilteredFeatures[itemIndex];

      if (feature) {
        selectSource.current.addFeature(toMapFeature(feature, itemIndex));
      }
    }
  }, [selectedItemIndex, hiddenLayerIndexes, layerCache]);

  useEffect(() => {
    hoverSource.current.clear();

    if (
      Number.isInteger(hoveredItemIndex.itemIndex) &&
      itemLayerGroup.current.getLayers().getLength() > 0 &&
      !hiddenLayerIndexes.includes(hoveredItemIndex.layerIndex)
    ) {
      const { layerIndex, itemIndex } = hoveredItemIndex;
      const feature = layerCache[layerIndex].orderedFilteredFeatures[itemIndex];

      if (feature) {
        hoverSource.current.addFeature(toMapFeature(feature, itemIndex));
      }
    }
  }, [hoveredItemIndex, hiddenLayerIndexes, layerCache]);

  useEffect(() => {
    if (geometry !== null) {
      onDrawEnd(drawIndex, geometry);
      setGeometry(null);
    }
  }, [drawIndex, geometry, onDrawEnd]);

  useEffect(() => {
    if (Number.isInteger(drawIndex)) {
      // select.current.getFeatures().clear();
      // hover.current.getFeatures().clear();
      selectSource.current.clear();
      hoverSource.current.clear();
      map.current.removeInteraction(select.current);
      map.current.removeInteraction(hover.current);
      map.current.removeInteraction(doubleClickZoom.current);
      map.current.addInteraction(draw.current);
    }
  }, [drawIndex]);

  function handleDrawEnd(event) {
    const geometry = new GeoJSON().writeGeometryObject(
      event.feature.getGeometry(),
      {
        featureProjection: 'EPSG:3857',
        rightHanded: true,
      }
    );

    setGeometry(geometry);

    setTimeout(() => {
      map.current.removeInteraction(draw.current);
      map.current.addInteraction(select.current);
      map.current.addInteraction(hover.current);
      map.current.addInteraction(doubleClickZoom.current);
    }, 0);
  }

  function fitMap() {
    try {
      if (selectedItemIndex.itemIndex) {
        map.current
          .getView()
          .fit(
            itemLayerGroup.current
              .getLayers()
              .item(parseInt(selectedItemIndex.layerIndex))
              .getSource()
              .getFeatureById(parseInt(selectedItemIndex.itemIndex))
              .getGeometry()
              .getExtent(),
            { maxZoom: 18 }
          );
      } else if (selectedItemIndex.layerIndex) {
        map.current
          .getView()
          .fit(
            itemLayerGroup.current
              .getLayers()
              .item(parseInt(selectedItemIndex.layerIndex))
              .getSource()
              .getExtent(),
            { maxZoom: 18 }
          );
      } else if (Number.isInteger(expandedLayerIndex)) {
        // if the layer has a boundaryGeometry it'll be in boundaryLayer
        // otherwise use its own features as the extent
        const boundaryGeometry = boundaryLayer.current
          ?.getSource()
          ?.getFeatureById(expandedLayerIndex)
          ?.getGeometry()
          ?.getExtent();

        const expandedLayerGeometry = itemLayerGroup.current
          ?.getLayers()
          ?.item(expandedLayerIndex)
          ?.getSource()
          ?.getExtent();

        map.current.getView().fit(boundaryGeometry || expandedLayerGeometry, {
          maxZoom: 18,
        });
      } else if (
        layers.filter(
          (layer) => 'featureCollection' in layer || 'boundaryGeometry' in layer
        ).length > 0
      ) {
        const itemsExtent = itemLayerGroup.current
          .getLayers()
          .getArray()
          .reduce((accumulator, layer) => {
            const source = layer.getSource();

            if (source) {
              if (accumulator) {
                accumulator = extend(accumulator, source.getExtent());
              } else {
                accumulator = source.getExtent();
              }
            }

            return accumulator;
          }, createEmpty());

        const extent = extend(
          itemsExtent,
          boundaryLayer.current.getSource().getExtent()
        );

        map.current.getView().fit(extent, { maxZoom: 18 });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857'))
          );
      }
    } catch {
      map.current
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
    }

    if (
      zoomInDisabled !==
        (map.current.getView().getZoom() ===
          map.current.getView().getMaxZoom()) ||
      zoomOutDisabled !==
        (map.current.getView().getZoom() === map.current.getView().getMinZoom())
    ) {
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom()
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom()
      );
    }
  }

  function handleMapToggleClick() {
    if (map.current) {
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 0 : 1)
        .setVisible(true);
      map.current
        .getLayers()
        .item(satelliteMapVisible ? 1 : 0)
        .setVisible(false);
      setSatelliteMapVisible(!satelliteMapVisible);
    }
  }

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom()
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom()
      );
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom()
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom()
      );
    }
  }

  function handleSelect(event) {
    // hover.current.getFeatures().clear();
    // select.current.getFeatures().clear();
    hoverSource.current.clear();
    selectSource.current.clear();

    if (event.selected.length > 0) {
      const id = event.selected[0].getId();

      if (Number.isInteger(id)) {
        onSelect({
          layerIndex: select.current.getLayer(event.selected[0]).getZIndex(),
          itemIndex: id,
        });
      } else {
        const features = event.selected[0].get('features');

        if (features.length === 1) {
          onSelect({
            layerIndex: select.current.getLayer(event.selected[0]).getZIndex(),
            itemIndex: features[0].getId(),
          });
        } else {
          const source = new VectorSource({ features });
          map.current.getView().fit(source.getExtent());
        }
      }
    } else {
      onSelect({});
    }
  }

  function handleHover(event) {
    hoverSource.current.clear();
    if (event.selected.length > 0) {
      hoverSource.current.addFeature(event.selected[0]);

      const id = event.selected[0].getId();

      if (Number.isInteger(id)) {
        const layer = hover.current.getLayer(event.selected[0]);

        if (layer) {
          onHover({
            layerIndex: layer.getZIndex(),
            itemIndex: id,
          });
        }
      }
    } else {
      onHover({});
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.map} ref={mapDiv} />
      <IconButton
        title="Zoom In"
        className={classes.zoomInButton}
        aria-label="Zoom In"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomInDisabled}
        onClick={handleZoomInClick}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        title="Zoom Out"
        className={classes.zoomOutButton}
        aria-label="Zoom Out"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        disabled={zoomOutDisabled}
        onClick={handleZoomOutClick}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        title="Fit"
        className={classes.fitButton}
        aria-label="Fit"
        color={satelliteMapVisible ? 'inherit' : 'default'}
        onClick={fitMap}
      >
        <ZoomOutMapIcon />
      </IconButton>
      {layerSwitcher && (
        <IconButton
          title={satelliteMapVisible ? 'Road Map' : 'Satellite Map'}
          className={classes.layerButton}
          aria-label="Map"
          color={satelliteMapVisible ? 'inherit' : 'default'}
          onClick={handleMapToggleClick}
        >
          {satelliteMapVisible ? <MapIcon /> : <SatelliteIcon />}
        </IconButton>
      )}
    </div>
  );
}

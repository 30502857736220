import { CancelToken } from 'axios';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import _ from 'lodash';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { quantileRankSorted } from 'simple-statistics';
import moment from 'moment';
import {
  CREATE_RETROSPECTIVE,
  CREATE_RETROSPECTIVE_FAILURE,
  CREATE_RETROSPECTIVE_SUCCESS,
  DELETE_RETROSPECTIVE,
  DELETE_RETROSPECTIVE_FAILURE,
  DELETE_RETROSPECTIVE_SUCCESS,
  FETCH_RETROSPECTIVE,
  FETCH_RETROSPECTIVES,
  FETCH_RETROSPECTIVES_FAILURE,
  FETCH_RETROSPECTIVES_SUCCESS,
  FETCH_RETROSPECTIVE_FAILURE,
  FETCH_RETROSPECTIVE_ITEM,
  FETCH_RETROSPECTIVE_ITEM_FAILURE,
  FETCH_RETROSPECTIVE_ITEM_SUCCESS,
  FETCH_RETROSPECTIVE_SUBITEM,
  FETCH_RETROSPECTIVE_SUBITEM_FAILURE,
  FETCH_RETROSPECTIVE_SUBITEM_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
  FETCH_RETROSPECTIVE_LAYER_CANCELLED,
  FETCH_RETROSPECTIVE_LAYER_FAILURE,
  FETCH_RETROSPECTIVE_LAYER_SUCCESS,
  FETCH_RETROSPECTIVE_SUCCESS,
  PUSH_RETROSPECTIVE_FORM,
  SYNC_RETROSPECTIVE_FORM,
  UPDATE_RETROSPECTIVE,
  UPDATE_RETROSPECTIVE_FAILURE,
  UPDATE_RETROSPECTIVE_SUCCESS,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE,
  ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS,
} from '../actions';
import api from '../apis';
import { getHeaders, log } from '../apis/utilities';
import history from '../history';
import { mongoizeFilters } from 'components/retrospective/constants';

const { dioStates } = window.config;

const cancels = {};
let estimationCancels = {};

function reduceByLocationCode(data, areaType) {
  const dataByLocation = data.reduce(
    (accumulator, { location: singleLocation, locations }) => {
      for (let location of locations || [singleLocation]) {
        if (!areaType || location.type === areaType) {
          if (accumulator[location.code]) {
            accumulator[location.code]++;
          } else {
            accumulator[location.code] = 1;
          }
        }
      }

      return accumulator;
    },
    {}
  );

  return dataByLocation;
}

async function fetchRetrospectivesRequest(isAudit) {
  const response = await api.get('/retrospectives', {
    params: {
      query: isAudit
        ? undefined
        : {
            'created.userId': localStorage.getItem('username'),
          },
      projection: {
        identifier: true,
        title: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  return response.data;
}

export function fetchRetrospectivesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVES),
    mergeMap(({ payload: isAudit }) =>
      from(fetchRetrospectivesRequest(isAudit)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVES_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVES_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchBoundary(type, identifier) {
  switch (type) {
    case 'Location': {
      const response = await api.get(`/locations/${identifier}`, {
        params: {
          projection: {
            boundary: true,
          },
        },
        headers: getHeaders(),
      });

      return response.data.boundary;
    }
    case 'Perimeter': {
      const response = await api.get(`/features/${identifier}`, {
        params: {
          projection: {
            geometry: true,
          },
        },
        headers: getHeaders(),
      });

      return response.data.geometry;
    }
    case 'Objective': {
      let response = await api.get(`/objectives/${identifier}`, {
        params: {
          projection: {
            identifier: true,
            boundaryType: true,
            boundaryIdentifier: true,
            boundary: true,
          },
        },
        headers: getHeaders(),
      });

      if (response.data.boundaryType === 'Custom') {
        return response.data.boundary;
      } else if (response.data.boundaryType === 'Location') {
        response = await api.get(
          `/locations/${response.data.boundaryIdentifier}`,
          {
            params: {
              projection: {
                boundary: true,
              },
            },
            headers: getHeaders(),
          }
        );

        return response.data.boundary;
      } else {
        response = await api.get(
          `/features/${response.data.boundaryIdentifier}`,
          {
            params: {
              projection: {
                geometry: true,
              },
            },
            headers: getHeaders(),
          }
        );

        return response.data.geometry;
      }
    }
    default:
      return undefined;
  }
}

async function fetchRetrospectiveRequest(id) {
  const response = await api.get(`/retrospectives/${id}`, {
    params: {
      projection: {
        identifier: true,
        title: true,
        description: true,
        layers: true,
        created: true,
        lastEdit: true,
      },
    },
    headers: getHeaders(),
  });

  const layers = await Promise.all(
    response.data.layers.map(async (layer) => {
      const boundaryGeometry = await fetchBoundary(
        layer.boundaryType,
        layer.boundaryIdentifier
      );

      return { boundaryGeometry, ...layer };
    })
  );

  log('Read', 'Retrospective', { id });

  return { ...response.data, layers };
}

export function fetchRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      from(fetchRetrospectiveRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

function omitTransitory(retrospective) {
  return {
    ...retrospective,
    layers: retrospective.layers.map((layer) =>
      _.omit(
        layer,
        [
          'featureCollection',
          'originalMatch',
          'originalPrecision',
          'estimatedRecords',
          'window',
          'virtualize',
          // exclude the boundaryGeometry if it's not custom
          layer.boundaryType !== 'Custom' && 'boundaryGeometry',
        ].filter(Boolean)
      )
    ),
  };
}

async function createRetrospectiveRequest(retrospective) {
  const response = await api.post(
    '/retrospectives',
    omitTransitory(retrospective),
    { headers: getHeaders() }
  );

  history.replace(`/retrospective/${response.data.identifier}`);

  return { ...retrospective, identifier: response.data.identifier };
}

export function createRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(CREATE_RETROSPECTIVE),
    mergeMap(({ payload: values }) =>
      from(createRetrospectiveRequest(values)).pipe(
        map((payload) => ({
          type: CREATE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function updateRetrospectiveRequest(retrospective) {
  await api.patch(
    `/retrospectives/${retrospective.identifier}`,
    omitTransitory(retrospective),
    {
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/merge-patch+json',
      },
    }
  );

  return retrospective;
}

export function updateRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_RETROSPECTIVE),
    mergeMap(({ payload: values }) =>
      from(updateRetrospectiveRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function deleteRetrospectiveRequest(id) {
  await api.delete(`/retrospectives/${id}`, {
    headers: getHeaders(),
  });

  return id;
}

export function deleteRetrospectiveEpic(action$) {
  return action$.pipe(
    ofType(DELETE_RETROSPECTIVE),
    mergeMap(({ payload: id }) =>
      from(deleteRetrospectiveRequest(id)).pipe(
        map((payload) => ({
          type: DELETE_RETROSPECTIVE_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_RETROSPECTIVE_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function postGet(url, params, layerIndex, cancelsArray) {
  cancelsArray = cancelsArray || cancels;

  // to avoid max url post a query and get an id back
  const postResponse = await api.post(
    '/functions/query',
    {
      collection: url.replace(/\//g, ''),
      ...params,
      // pipeline: estimationPipeline,
    },
    {
      headers: getHeaders(),
      ...(layerIndex !== undefined
        ? {
            cancelToken: new CancelToken((c) => {
              cancelsArray[layerIndex] = c;
            }),
          }
        : {}),
    }
  );

  // we can get the data back with the id we got from the post stage
  const result = await api.get(`/functions/query/${postResponse.data}`, {
    headers: getHeaders(),
    cancelToken: new CancelToken((c) => {
      cancelsArray[layerIndex] = c;
    }),
  });

  return result;
}

async function fetchLayerData(index, layer, filters) {
  // const startTime = moment(layer.startTime)
  //   .utc()
  //   .startOf('day')
  //   .toDate();
  // const endTime = moment(layer.endTime)
  //   .utc()
  //   .startOf('day')
  //   .toDate();
  const query = await matchForLayer(layer, filters);
  const projection = projectionForLayer(layer);
  const params = { query, projection };

  switch (layer.source) {
    case 'vehicleTrips': {
      const response = await postGet('/trips', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleStops': {
      const response = await postGet('/stops', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleStopCount': {
      const response = await postGet('/stops', params, index);

      return reduceByLocationCode(response.data, layer.areaType);
    }
    case 'vehicleIdles': {
      const response = await postGet('/idles', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleIdleCount': {
      const response = await postGet('/idles', params, index);

      return reduceByLocationCode(response.data, layer.areaType);
    }
    case 'vehiclePolls': {
      const response = await postGet('/telematicsBoxPolls', params, index);

      const vehiclesResponse = await postGet(
        '/vehicles',
        {
          query: {
            telematicsBoxImei: query.imei,
          },
          projection: projectionForLayer('vehicles'),
        },
        index
      );

      const vehicleLookup = new Map(
        vehiclesResponse.data.map((vehicle) => [
          vehicle.telematicsBoxImei,
          vehicle,
        ])
      );

      return {
        type: 'FeatureCollection',
        features: response.data
          .filter((p) => !query.$and?.length || vehicleLookup.get(p.imei))
          .map(
            (
              { position: geometry, identifier: id, imei, ...properties },
              index
            ) => {
              return {
                type: 'Feature',
                id: index,
                properties: {
                  ...properties,
                  id,
                  imei,
                  source: layer.source,
                  vehicle: vehicleLookup.get(imei),
                },
                geometry,
              };
            }
          ),
      };
    }
    case 'vehicleVisits': {
      const response = await postGet('/intersections', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'vehicleTime': {
      const response = await postGet('/intersections', params, index);

      const data = response.data.reduce((accumulator, row) => {
        if (accumulator[row.location.code]) {
          // accumulator[location.code].add(
          //   moment.duration(
          //     moment
          //       .min(moment(row.endTime), moment(layer.endTime))
          //       .diff(
          //         moment.max(moment(row.startTime), moment(layer.startTime))
          //       )
          //   )
          // );
          accumulator[row.location.code] +=
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        } else {
          // accumulator[location.code] = moment.duration(
          //   moment
          //     .min(moment(row.endTime), moment(layer.endTime))
          //     .diff(
          //       moment.max(moment(row.startTime), moment(layer.startTime))
          //     )
          // );
          accumulator[row.location.code] =
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        }
        return accumulator;
      }, {});

      return data;
    }
    case 'vehicleVisitCount': {
      const response = await postGet('/intersections', params, index);

      return reduceByLocationCode(response.data, layer.areaType);
    }
    case 'incidents': {
      const response = await postGet('/incidents', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ point: geometry, number: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'incidentCount': {
      const response = await postGet('/incidents', params, index);

      return reduceByLocationCode(response.data, layer.areaType);
    }
    case 'personTrails': {
      const response = await postGet('/personTrails', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'personVisits': {
      const response = await postGet(
        '/personLocationIntersections',
        params,
        index
      );

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ path: geometry, identifier: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: { ...properties, id, source: layer.source },
              geometry,
            };
          }
        ),
      };
    }
    case 'personTime': {
      const response = await postGet(
        '/personLocationIntersections',
        params,
        index
      );

      const data = response.data.reduce((accumulator, row) => {
        if (accumulator[row.location.code]) {
          // accumulator[location.code].add(
          //   moment.duration(
          //     moment
          //       .min(moment(row.endTime), moment(layer.endTime))
          //       .diff(
          //         moment.max(moment(row.startTime), moment(layer.startTime))
          //       )
          //   )
          // );
          accumulator[row.location.code] +=
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        } else {
          // accumulator[location.code] = moment.duration(
          //   moment
          //     .min(moment(row.endTime), moment(layer.endTime))
          //     .diff(
          //       moment.max(moment(row.startTime), moment(layer.startTime))
          //     )
          // );
          accumulator[row.location.code] =
            Math.min(new Date(row.endTime), new Date(layer.endTime)) -
            Math.max(new Date(row.startTime), new Date(layer.startTime));
        }
        return accumulator;
      }, {});

      return data;
    }
    case 'personVisitCount': {
      const response = await postGet(
        '/personLocationIntersections',
        params,
        index
      );

      return reduceByLocationCode(response.data, layer.areaType);
    }
    case 'personPolls': {
      const response = await postGet('/radioPolls', params, index);

      const peopleResponse = await postGet(
        '/people',
        {
          query: { radioSsi: query.ssi },
          projection: projectionForLayer('people'),
        },
        index
      );

      const personLookup = new Map(
        peopleResponse.data.map((person) => [person.radioSsi, person])
      );

      return {
        type: 'FeatureCollection',
        features: response.data
          .filter((p) => !query.$and?.length || personLookup.get(p.ssi))
          .map(
            (
              { position: geometry, identifier: id, ssi, ...properties },
              index
            ) => {
              return {
                type: 'Feature',
                id: index,
                properties: {
                  ...properties,
                  ssi,
                  id,
                  source: layer.source,
                  person: personLookup.get(ssi),
                },
                geometry,
              };
            }
          ),
      };
    }
    case 'locations':
      const response = await postGet('/locations', params, index);

      return {
        type: 'FeatureCollection',
        features: response.data.map(
          ({ boundary: geometry, code: id, ...properties }, index) => {
            return {
              type: 'Feature',
              id: index,
              properties: {
                ...properties,
                id,
                code: id,
                source: 'locations',
              },
              geometry,
            };
          }
        ),
      };
    default:
      return {
        type: 'FeatureCollection',
        features: [],
      };
  }
}

async function fetchAreas(index, layer, data) {
  const response = await postGet(
    '/locations',
    {
      query: {
        type: layer.areaType,
        boundary: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
      },
      projection: {
        code: true,
        name: true,
        //  type: true, subtype: true,
        boundary: true,
      },
    },
    index
  );

  const counts = Object.values(data).sort((a, b) => a - b);

  return {
    type: 'FeatureCollection',
    features: response.data.map(
      ({ boundary: geometry, code: id, ...properties }, index) => {
        return {
          type: 'Feature',
          id: index,
          properties: {
            ...properties,
            // code: id,
            id,
            source: 'areas',
            measure: layer.source,
            count: data[id] || 0,
            quantile: data[id] ? quantileRankSorted(counts, data[id]) : 0,
          },
          geometry,
        };
      }
    ),
  };
}

function urlForLayer(layer) {
  switch (layer.source) {
    case 'vehicleTrips':
      return '/trips';
    case 'vehicleStopCount':
    case 'vehicleStops':
      return '/stops';
    case 'vehicleIdleCount':
    case 'vehicleIdles':
      return '/idles';
    case 'vehiclePolls':
      return '/telematicsBoxPolls';
    case 'vehicleVisitCount':
    case 'vehicleTime':
    case 'vehicleVisits':
      return '/intersections';
    case 'incidentCount':
    case 'incidents':
      return '/incidents';
    case 'personTrails':
      return '/personTrails';
    case 'personVisitCount':
    case 'personTime':
    case 'personVisits':
      return '/personLocationIntersections';
    case 'personPolls':
      return '/radioPolls';
    case 'locations':
      return '/locations';
    default:
      break;
  }
}

function projectionForLayer(layer) {
  // || layer in case someone just passed the source
  switch (layer.source || layer) {
    case 'vehicleTrips':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        path: true,
        distanceKilometres: true,
        maxSpeedKilometresPerHour: true,
        durationSeconds: true,
      };
    case 'vehicleVisitCount':
    case 'personVisitCount':
      return { location: true };
    case 'vehicleIdleCount':
    case 'vehicleStopCount':
    case 'incidentCount':
      return { locations: true };
    case 'vehicleStops':
      return {
        identifier: true,
        vehicle: true,
        startTime: true,
        endTime: true,
        point: true,
        durationSeconds: true,
        lastDriver: true,
      };
    case 'vehicleIdles':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        point: true,
        durationSeconds: true,
      };
    case 'vehiclePolls':
      return {
        identifier: true,
        imei: true,
        identificationNumber: true,
        time: true,
        position: true,
      };
    case 'vehicleTime':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
      };
    case 'vehicleVisits':
      return {
        identifier: true,
        vehicle: true,
        driver: true,
        startTime: true,
        endTime: true,
        path: true,
        location: true,
        distanceKilometres: true,
        durationSeconds: true,
      };
    case 'incidents':
      return {
        identifier: '$number',
        number: true,
        description: true,
        type: true,
        category: true,
        responseCategory: true,
        status: true,
        grade: true,
        point: true,
        openedTime: true,
        closingCodes: true,
        date: true,
        reference: true
      };
    case 'personTrails':
      return {
        identifier: true,
        person: true,
        startTime: true,
        endTime: true,
        path: true,
      };
    case 'personTime':
      return {
        identifier: true,
        location: true,
        startTime: true,
        endTime: true,
      };
    case 'personVisits':
      return {
        identifier: true,
        person: true,
        startTime: true,
        endTime: true,
        location: true,
        path: true,
        durationSeconds: true,
      };
    case 'personPolls':
      return {
        identifier: true,
        ssi: true,
        code: true,
        collarNumber: true,
        time: true,
        position: true,
      };
    case 'locations':
      return {
        code: true,
        name: true,
        subtype: true,
        type: true,
        boundary: true,
      };
    case 'people':
      return {
        code: true,
        collarNumber: true,
        radioSsi: true,
        rank: true,
        role: true,
        homeStation: true,
      };
    case 'vehicles':
      return {
        identificationNumber: true,
        fleetNumber: true,
        registrationNumber: true,
        role: true,
        homeStation: true,
        areas: true,
        telematicsBoxImei: true,
        type: true,
      };
    default:
      break;
  }
}

async function matchForLayer(layer, filters) {
  const mongoFilters = mongoizeFilters(filters);

  function extractMongoFiltersForEntity(entity) {
    return mongoFilters
      .filter((f) => Object.keys(f)?.[0]?.startsWith(entity))
      .map((f) => {
        const key = Object.keys(f)[0];

        return {
          [key.replace(`${entity}.`, '')]: f[key],
        };
      });
  }

  switch (layer.source) {
    // path-based
    case 'vehicleVisits':
    case 'vehicleTrips':
    case 'personTrails':
    case 'personVisits':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        path: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    // point-based
    case 'vehicleStops':
    case 'vehicleIdles':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        point: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    // count by type
    case 'vehicleTime':
    case 'vehicleVisitCount':
    case 'personTime':
    case 'personVisitCount':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        'location.type': layer.areaType,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    case 'vehicleStopCount':
    case 'vehicleIdleCount':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        'locations.type': layer.areaType,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    case 'incidentCount':
      return {
        openedTime: { $gte: layer.startTime, $lt: layer.endTime },
        'locations.type': layer.areaType,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    // polls
    case 'personPolls':
      // need to find ssis first
      const personMongoFilters = extractMongoFiltersForEntity('person');
      const ssisQuery = await postGet('/people', {
        query: {
          $and: personMongoFilters.length > 0 ? personMongoFilters : undefined,
        },
        projection: {
          radioSsi: true,
        },
      });

      const ssis = ssisQuery.data
        .map(({ radioSsi }) => radioSsi)
        .filter(Boolean);

      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        position: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        ...(filters?.person ? { ssi: { $in: ssis } } : {}),
        // $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    case 'vehiclePolls':
      // need to find imeis first
      const vehicleMongoFilters = extractMongoFiltersForEntity('vehicle');
      const imeisQuery = await postGet('/vehicles', {
        query: {
          $and:
            vehicleMongoFilters.length > 0 ? vehicleMongoFilters : undefined,
        },
        projection: {
          telematicsBoxImei: true,
        },
      });

      const imeis = imeisQuery.data
        .map(({ telematicsBoxImei }) => telematicsBoxImei)
        .filter(Boolean);

      return {
        time: { $gte: layer.startTime, $lt: layer.endTime },
        position: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        ...(filters?.vehicle ? { imei: { $in: imeis } } : {}),
        // $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    case 'incidents':
      return {
        openedTime: { $gte: layer.startTime, $lt: layer.endTime },
        point: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and:
          mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    case 'locations':
      return {
        startTime: { $lt: layer.endTime },
        endTime: { $gt: layer.startTime },
        boundary: layer.boundaryGeometry
          ? {
              $geoIntersects: {
                $geometry: layer.boundaryGeometry,
              },
            }
          : undefined,
        $and: mongoFilters.length > 0 ? mongoFilters : undefined,
      };
    default:
      return {};
  }
}

// estimate how many items will be returned by using the same filters
// for a shorter period of time & doing a count pipeline
async function estimateLayerResultCount(index, layer, filters) {
  const url = urlForLayer(layer);

  if (!url || !layer.startTime || !layer.endTime) {
    return {
      index,
      estimatedRecords: 0,
    };
  }

  let startTime = moment(layer.startTime);
  let endTime = moment(layer.endTime);

  const dayDifference = endTime.diff(startTime, 'days', true);

  // don't bother sampling if it's less than a day
  if (dayDifference > 1) {
    // otherwise get a typical day in the middle of the range
    startTime.add(dayDifference / 2, 'days');
    endTime = moment(startTime).add(1, 'days');
  }

  const $match = await matchForLayer(
    {
      ...layer,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    },
    filters
  );

  let pipeline = [
    {
      $match,
    },
    {
      $group: {
        _id: null,
        count: {
          $sum: 1,
        },
      },
    },
  ];

  // post the query as there may be a lot of parameters
  const estimationResult = await postGet(
    url,
    { pipeline },
    index,
    estimationCancels
  );

  let estimatedRecords = estimationResult?.data[0]?.count || 0;
  if (dayDifference > 1) {
    estimatedRecords *= dayDifference;
    estimatedRecords |= 0; // round down
  }

  return {
    index,
    estimatedRecords,
  };
}

export function estimateLayerResultCountEpic(action$) {
  return action$.pipe(
    ofType(ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT),
    tap(({ payload: { index } }) => {
      estimationCancels[index]?.('cancelled');
    }),
    debounceTime(300),
    mergeMap(({ payload: { index, layer, filters } }) =>
      from(estimateLayerResultCount(index, layer, filters)).pipe(
        map((payload) => ({
          type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_CANCELLED),
            filter((action) => action.payload === index),
            tap((ev) => estimationCancels[index]('cancelled'))
          )
        ),
        catchError(({ message }) =>
          of({
            type: ESTIMATE_RETROSPECTIVE_LAYER_RESULT_COUNT_FAILURE,
            payload: {
              message,
              index,
            },
          })
        )
      )
    )
  );
}

/*
{
    "ssi": "2865150",
    "time": "2021-01-01T00:00:00.000Z",
    "identifier": "2865150-2020-12-31T21:44:00.000Z",
    "position": {
        "coordinates": [
            -3.239443,
            51.480871
        ],
        "type": "Point"
    }
}
*/

async function fetchAggregatedHeat(index, layer, filters, precision) {
  const clustering = precision < 7;

  const $match = await matchForLayer(layer, filters);
  const projection = projectionForLayer(layer);
  const url = urlForLayer(layer);

  // used for getting the centre point
  // const mid = 10 ** -precision / 2;

  // the heat ones are either position or point depending on poll or not
  const isPollBased = layer.source.includes('olls');
  const geo = isPollBased ? '$position' : '$point';

  let pipeline = [
    // poll-based queries need a lookup to get ssis/imeis that match person/vehicle filters
    {
      $match,
    },
    ...(clustering
      ? [
          {
            $project: {
              origLon: {
                $arrayElemAt: [geo + '.coordinates', 0],
              },
              origLat: {
                $arrayElemAt: [geo + '.coordinates', 1],
              },
              lon: {
                $trunc: [
                  {
                    $toDecimal: {
                      $arrayElemAt: [geo + '.coordinates', 0],
                    },
                  },
                  precision,
                ],
              },
              lat: {
                $trunc: [
                  {
                    $toDecimal: {
                      $arrayElemAt: [geo + '.coordinates', 1],
                    },
                  },
                  precision,
                ],
              },
            },
          },
          {
            $group: {
              _id: {
                lon: { $toDouble: '$lon' },
                lat: { $toDouble: '$lat' },
              },
              count: {
                $sum: 1,
              },
              avgLon: { $avg: '$origLon' },
              avgLat: { $avg: '$origLat' },
            },
          },
          {
            // TODO this is only so the ui doesn't crash, ideally we have
            // a new object that the ui interprets as a cluster and will
            // decluster it
            $project: {
              // the corner of the cluster
              // all that's needed to decluster: get me all of the polls/incidents
              // between this position and this position + 1^-precision
              // with the same search criteria (homeStation: x/grade: y)
              lat: '$_id.lat',
              lon: '$_id.lon',

              count: true,

              //////////////////////
              // the map position
              //////////////////////

              // corner:
              /*position: {
                coordinates: ['$_id.lon', '$_id.lat'],
                type: 'Point',
              },*/

              // average:
              position: {
                coordinates: ['$avgLon', '$avgLat'],
                type: 'Point',
              },

              // centre:
              // the add, mul, div, abs horror show is used to get the centre of the
              // grid. We need to go in the same direction so if it's -1 go to -1.5 not -0.5
              // abs(x)/x gives us the sign, multiplying by half the precision gives us the
              // distance and add that to the lon/lat
              /*position: {
                coordinates: [
                  {
                    $add: [
                      '$_id.lon',
                      {
                        $multiply: [
                          {$divide: [{$abs: '$_id.lon'}, '$_id.lon']},
                          mid,
                        ],
                      },
                    ],
                  },
                  {
                    $add: [
                      '$_id.lat',
                      {
                        $multiply: [
                          {$divide: [{$abs: '$_id.lat'}, '$_id.lat']},
                          mid,
                        ],
                      },
                    ],
                  },
                ],
                type: 'Point',
              }, // end mid
              */
            },
          },
        ]
      : [
          {
            // this includes the identifier of the poll or the item
            // e.g. identifier:"2865150-2020-12-31T21:44:00.000Z" or number: 'inc02'
            // the old ui should work as normal here
            $project: projection,
          },
        ]),
  ];

  function pad(number) {
    const string = number.toString();
    const decimalPosition = string.indexOf('.');
    return string.padEnd(decimalPosition + precision + 1, '0');
  }

  // post the query as there may be a lot of parameters
  const response = await postGet(url, { pipeline }, index);

  return [
    {
      type: 'FeatureCollection',
      features: response.data.map(
        (
          { position: geometry, lon, lat, identifier, ...properties },
          index
        ) => {
          return {
            type: 'Feature',
            id: index,
            properties: {
              ...properties,
              lon,
              lat,
              id: lon
                ? `${pad(lon)}${String.fromCharCode(176)}, ${pad(
                    lat
                  )}${String.fromCharCode(176)}`
                : identifier,
              originalSource: layer.source,
              source: 'clusters',
            },
            geometry,
          };
        }
      ),
    },
    $match,
  ];
}

async function fetchRetrospectiveLayerRequest(index, layer, filters) {
  const parsed = parseInt(layer.precision);
  const precision = isNaN(parsed) ? 0 : parsed;
  const aggregateHeat = layer.type === 'heat' && precision < 7;

  let originalMatch, data;
  if (aggregateHeat) {
    // when we want to decluster one of the features, it's helpful to have the
    // orginal match criteria cached on the layer so this function returns both
    [data, originalMatch] = await fetchAggregatedHeat(
      index,
      layer,
      filters,
      precision
    );
  } else {
    data = await fetchLayerData(index, layer, filters);
  }

  log('Read', 'Layer', _.omit(layer, ['featureCollection', 'window']));

  switch (layer.type) {
    case 'shape':
    case 'bubble':
    case 'heat':
      return {
        index,
        layer: {
          ...layer,
          featureCollection: data,
          originalMatch,
          originalPrecision: precision,
          estimatedRecords: undefined,
        },
      };
    case 'area':
      const areas = await fetchAreas(index, layer, data);

      return { index, layer: { ...layer, featureCollection: areas } };
    default:
      return { index, layer };
  }
}

export function fetchRetrospectiveLayerEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER),
    mergeMap(({ payload: { index, layer, filters } }) =>
      from(fetchRetrospectiveLayerRequest(index, layer, filters)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_SUCCESS,
          payload,
        })),
        takeUntil(
          action$.pipe(
            ofType(FETCH_RETROSPECTIVE_LAYER_CANCELLED),
            filter((action) => action.payload === index),
            tap((ev) => cancels[index]('cancelled'))
          )
        ),
        catchError(({ message }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_FAILURE,
            payload: {
              message,
              index,
            },
          })
        )
      )
    )
  );
}

async function fetchRetrospectiveLayerBoundaryRequest(index, layer) {
  const boundaryGeometry = await fetchBoundary(
    layer.boundaryType,
    layer.boundaryIdentifier
  );

  return {
    index,
    layer: { ...layer, boundaryGeometry },
  };
}

export function fetchRetrospectiveLayerBoundaryEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_LAYER_BOUNDARY),
    mergeMap(({ payload: { index, layer } }) =>
      from(fetchRetrospectiveLayerBoundaryRequest(index, layer)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_LAYER_BOUNDARY_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

async function fetchRetrospectiveItemRequest({
  id,
  source,
  count,
  quantile,
  measure,
  ...properties
}) {
  let response, visitLocation, event;

  switch (source) {
    case 'vehicleTrips':
      response = await api.get(`/trips/${id}`, {
        params: {
          projection: {
            identifier: true,
            classification: true,
            driver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
            startLocations: true,
            endLocations: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleTrip', ...response.data };
    case 'vehicleStops':
      response = await api.get(`/stops/${id}`, {
        params: {
          projection: {
            identifier: true,
            lastDriver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleStop', ...response.data };
    case 'vehicleIdles':
      response = await api.get(`/idles/${id}`, {
        params: {
          projection: {
            identifier: true,
            driver: true,
            vehicle: true,
            startTime: true,
            endTime: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'vehicleIdle', ...response.data };
    case 'vehiclePolls':
      response = await api.get(`/vehiclePolls/${id}`, {
        params: {
          projection: {
            identifier: true,
            imei: true,
            identificationNumber: true,
            time: true,
            position: true,
            headingDegrees: true,
            speedKilometresPerHour: true,
            malfunctionIndicatorLightOn: true,
            accelerometerAlert: true,
            ignitionOn: true,
            driver: true,
            ...Object.keys(dioStates).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {}),
          },
        },
        headers: getHeaders(),
      });

      const vehicleResponse = await api.get(
        `/vehicles/${response.data.identificationNumber}`,
        {
          params: {
            projection: {
              identificationNumber: true,
              registrationNumber: true,
              fleetNumber: true,
              role: true,
              type: true,
              homeStation: true,
              areas: true,
            },
          },
          headers: getHeaders(),
        }
      );

      return {
        itemType: 'vehiclePoll',
        vehicle: vehicleResponse.data,
        ...response.data,
      };
    case 'vehicleVisits':
      response = await api.get(`/intersections/${id}`, {
        params: {
          projection: {
            identifier: true,
            driver: true,
            vehicle: true,
            location: true,
            startTime: true,
            endTime: true,
            distanceKilometres: true,
            maxSpeedKilometresPerHour: true,
          },
        },
        headers: getHeaders(),
      });

      ({ location: visitLocation, ...event } = response.data);

      return { itemType: 'vehicleVisit', visitLocation, ...event };
    case 'incidents':
      response = await api.get(`/incidents/${id}`, {
        params: {
          projection: {
            number: true,
            description: true,
            type: true,
            category: true,
            responseCategory: true,
            grade: true,
            point: true,
            address: true,
            openedTime: true,
            assignedTime: true,
            attendedTime: true,
            closedTime: true,
            status: true,
            closingCodes: true,
            reference: true,
            date: true
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'incident', ...response.data };
    case 'personTrails':
      response = await api.get(`/personTrails/${id}`, {
        params: {
          projection: {
            identifier: true,
            startTime: true,
            endTime: true,
            person: true,
            startLocations: true,
            endLocations: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'personTrail', ...response.data };
    case 'personVisits':
      response = await api.get(`/personLocationIntersections/${id}`, {
        params: {
          projection: {
            identifier: true,
            startTime: true,
            endTime: true,
            person: true,
            location: true,
          },
        },
        headers: getHeaders(),
      });

      ({ location: visitLocation, ...event } = response.data);

      return { itemType: 'personVisit', visitLocation, ...event };
    case 'clusters':
      const { lat, lon, originalSource } = properties;
      const url = urlForLayer({ source: originalSource });
      const projection = projectionForLayer({ source: originalSource });

      // get all the polls that are in the cluster's grid, the lat/lon passed serves
      // as a starting point, and the precision dictates how wide & long the grid is
      // a precision of 1 is a diff of .1, precision of 2 is a diff of .01 etc.
      const diff = 10 ** -properties.originalPrecision;
      function range(x) {
        return x < 0 ? { $lte: x, $gt: x - diff } : { $gte: x, $lt: x + diff };
      }

      // the heat ones are either position or point depending on poll or not
      const isPollBased = originalSource.includes('olls');
      const geo = isPollBased ? 'position' : 'point';

      response = await postGet(url, {
        query: {
          ...properties.originalMatch,
          [`${geo}.coordinates.1`]: range(lat),
          [`${geo}.coordinates.0`]: range(lon),
        },
        projection,
      });

      // // polls don't contain the person/vehicle with the data so we need to fetch and map
      // if (isPollBased) {
      //   const [collection, entity, entityKey, pollKey] = {
      //     personPolls: ['people', 'person', 'radioSsi', 'ssi'],
      //     vehiclePolls: ['vehicles', 'vehicle', 'telematicsBoxImei', 'imei'],
      //   }[originalSource];

      //   const entityResponse = await postGet(collection, {
      //     query: { [entityKey]: properties.originalMatch[pollKey] },
      //     projection: projectionForLayer({ source: collection }),
      //   });

      //   const entityLookup = new Map(
      //     entityResponse.data.map((entity) => [entity[entityKey], entity])
      //   );

      //   response.data.forEach((row) => {
      //     row[entity] = entityLookup.get(row[pollKey]);
      //   });
      // }

      return {
        itemType: 'clusters',
        polls: response.data.map((p) => ({
          ...p,
          id: p.identifier,
          originalSource,
          source: 'clusters',
        })),
        count,
        ...properties,
      };

    case 'personPolls':
      response = await api.get(`/radioPolls/${id}`, {
        params: {
          projection: {
            identifier: true,
            ssi: true,
            code: true,
            time: true,
            position: true,
            emergencyButtonOn: true,
          },
        },
        headers: getHeaders(),
      });

      const { ssi, time } = response.data;

      const personResponse = await api.get('/personTrails', {
        params: {
          query: {
            'person.radioSsi': ssi,
            startTime: { $lte: time },
            endTime: { $gte: time },
          },
          projection: {
            person: true,
          },
        },
        headers: getHeaders(),
      });

      return {
        itemType: 'personPoll',
        person: personResponse.data?.[0]?.person || {},
        ...response.data,
      };
    case 'areas':
      response = await api.get(`/locations/${id}`, {
        params: {
          projection: {
            code: true,
            name: true,
            type: true,
            areas: true,
            subtype: true,
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'area', count, quantile, measure, ...response.data };
    case 'locations':
      response = await api.get(`/locations/${id}`, {
        params: {
          query: {
            fields: ['code', 'name', 'type', 'areas', 'subtype'],
          },
        },
        headers: getHeaders(),
      });

      return { itemType: 'location', ...response.data };
    default:
      return null;
  }
}

export function fetchRetrospectiveItemEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_ITEM),
    mergeMap(({ payload: properties }) =>
      from(fetchRetrospectiveItemRequest(properties)).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_ITEM_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_ITEM_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function fetchRetrospectiveSubItemEpic(action$) {
  return action$.pipe(
    ofType(FETCH_RETROSPECTIVE_SUBITEM),
    mergeMap(({ payload: { originalSource, ...properties } }) =>
      from(
        fetchRetrospectiveItemRequest({ ...properties, source: originalSource })
      ).pipe(
        map((payload) => ({
          type: FETCH_RETROSPECTIVE_SUBITEM_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_RETROSPECTIVE_SUBITEM_FAILURE,
            payload,
          })
        )
      )
    )
  );
}

export function pushRetrospectiveFormEpic(action$) {
  return action$.pipe(
    ofType(PUSH_RETROSPECTIVE_FORM),
    // the debounce can lead to some timing issues such as results for a boundary
    // query coming back before there are any layers saved (pushed & synced)
    // debounceTime(5000),
    switchMap(({ payload }) =>
      of({
        type: SYNC_RETROSPECTIVE_FORM,
        payload,
      })
    )
  );
}

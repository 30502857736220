import {
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FETCH_TRAILS,
  FETCH_TRAILS_CANCELLED,
  UPDATE_TRAILS_FILTER,
  UPDATE_TRAILS_QUERY,
} from '../../actions';
import {
  downloadCSV,
  eventsPersonTableHeaders,
  getPersonPolls,
  radioPollHeaders,
  shortPersonHeaders,
  startAndEndLocationHeaders,
} from '../../apis/utilities';
import Container from '../Container';
import {
  ExportPicker,
  FilterPicker,
  Parameters,
  TablePagination,
  Table,
} from '../controls';
import { useSnackbar } from '../Snackbar';
import { filterLocally } from '../../data/utilities';

const { rowsPerPageOptions, useReducedResourceInformation } = window.config;

const useStyles = makeStyles((theme) => ({
  itemSection: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  card: {
    margin: theme.spacing(0, 1, 1),
    minWidth: 240,
  },
  cardHeader: {
    flexGrow: 1,
  },
  filterField: {
    width: 144,
  },
  progress: {
    margin: theme.spacing(1),
  },
  tableContainer: {
    height: 'calc(100vh - 172px)',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 750,
  },
  linkButton: {
    padding: 0,
  },
  parameters: {
    width: 280,
  },
  toolbar: {
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const headers = [
  ...eventsPersonTableHeaders,
  {
    label: 'Start Location Type',
    key: 'startLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'Start Location Name',
    key: 'startLocationName',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Type',
    key: 'endLocationType',
    type: 'text',
    filter: true,
  },
  {
    label: 'End Location Name',
    key: 'endLocationName',
    type: 'text',
    filter: true,
  },
  { label: 'Start Time', key: 'startTime', type: 'date', filter: false },
  { label: 'End Time', key: 'endTime', type: 'date', filter: false },
  {
    label: 'Duration (minutes)',
    key: 'durationMinutes',
    type: 'number',
    filter: false,
  },
];

function ReplayLink({ entry }) {
  const history = useHistory();

  const handleViewClick = (identifier) => () => {
    history.push(`/replay/personTrails/${identifier}`);
  };

  return (
    <IconButton
      title="View"
      size="small"
      aria-label="View"
      onClick={handleViewClick(entry.identifier)}
    >
      <PlayArrowIcon />
    </IconButton>
  );
}

function DownloadPersonPollsLink({ entry }) {
  const personTrailsHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.person.radioSsi,
      entry.startTime,
      entry.endTime
    );

    const data = polls.map((trail) => ({
      ...trail,
      ...(useReducedResourceInformation
        ? { staffId: entry.person.code }
        : {
            name: entry.person.forenames + ' ' + entry.person.surname,
            personRole: entry.person.role,
            collarNumber: entry.person.collarNumber,
          }),
      radioSsi: entry.person.radioSsi,
      time: new Date(trail.time),
      longitude: trail.position ? trail.position.coordinates[0] : 0,
      latitude: trail.position ? trail.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      title="download"
      fetchDataToConvert={fetchDataToConvert}
      filename="Person Trail"
      headers={personTrailsHeaders}
    />
  );
}

const replayColumn = {
  label: '',
  key: 'replay',
  type: 'component',
  component: ReplayLink,
};

const downloadColumn = {
  label: '',
  key: 'download',
  type: 'component',
  component: DownloadPersonPollsLink,
};

export default function Trails() {
  const dispatch = useDispatch();
  const trails = useSelector((state) => state.events.trails.list, _.isEqual);
  const isLoading = useSelector((state) => state.events.trails.isLoading);
  const error = useSelector((state) => state.events.trails.error);
  const filter = useSelector((state) => state.events.trails.filter, _.isEqual);
  const query = useSelector((state) => state.events.trails.query, _.isEqual);
  const classes = useStyles();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar.notify('error', error);
    }
  }, [error, snackbar]);

  function handleFetch(event, query) {
    dispatch({
      type: FETCH_TRAILS,
      payload: query,
    });
  }

  function handleCancel() {
    dispatch({
      type: FETCH_TRAILS_CANCELLED,
    });
  }

  function handleQueryChange(query) {
    dispatch({
      type: UPDATE_TRAILS_QUERY,
      payload: query,
    });
  }

  function updateFilter(update) {
    onFilterChange({
      ...filter,
      ...update,
    });
  }

  function onFilterChange(payload) {
    dispatch({
      type: UPDATE_TRAILS_FILTER,
      payload,
    });
  }

  function handlePageChange(event, page) {
    updateFilter({ page });
  }

  function handleRowsPerPageChange(event) {
    updateFilter({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  }

  function handleOrderChange(order) {
    updateFilter({ order });
  }

  function handleOrderByChange(orderBy) {
    updateFilter({
      orderBy,
      order: 'asc',
    });
  }

  const personTrailsHeaders = [
    ...shortPersonHeaders,
    ...startAndEndLocationHeaders,
  ];

  function handleDownloadClick() {
    const filename = 'Person Trails.csv';

    const data = filteredTrails.map((trail) => ({
      ...trail,
      ...(useReducedResourceInformation
        ? { staffId: trail.person.code }
        : {
            name: trail.person.forenames + ' ' + trail.person.surname,
            collarNumber: trail.person.collarNumber,
            personRole: trail.person.role,
          }),
      startLocationType: trail.startLocationType,
      startLocationName: trail.startLocationName,
      endLocationType: trail.endLocationType,
      endLocationName: trail.endLocationName,
      startTime: new Date(trail.startTime),
      endTime: new Date(trail.endTime),
      durationMinutes: _.round(trail.durationMinutes || 0.0, 2),
    }));

    downloadCSV(data, filename, personTrailsHeaders);
  }

  const filteredTrails = filterLocally(filter, trails);

  return (
    <Container title="Trails">
      <Parameters
        onFetch={handleFetch}
        onCancel={handleCancel}
        onChange={handleQueryChange}
        isFetching={isLoading}
        className={classes.parameters}
        value={query}
        person
      />
      <div className={classes.itemSection}>
        <Helmet>
          <title>IR3 | Trails</title>
        </Helmet>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.cardHeader} variant="subtitle1">
            Trails
          </Typography>
          <FilterPicker
            headers={headers}
            data={trails}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <IconButton
            disabled={filteredTrails.length === 0}
            title="Download"
            onClick={handleDownloadClick}
          >
            <GetAppIcon />
          </IconButton>
        </Toolbar>
        <Paper className={classes.card}>
          <Table
            classes={classes}
            data={filteredTrails}
            headers={headers.concat(replayColumn).concat(downloadColumn)}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            order={filter.order}
            orderBy={filter.orderBy}
            onOrderChange={handleOrderChange}
            onOrderByChange={handleOrderByChange}
          />
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredTrails.length}
            rowsPerPage={filter.rowsPerPage}
            page={filter.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </div>
    </Container>
  );
}

import { createTheme } from '@material-ui/core';
import { blue, yellow } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: blue[200],
      main: blue[500],
      dark: blue[800],
      background: blue[100],
    },
    secondary: {
      light: yellow.A200,
      main: yellow.A400,
      dark: yellow.A700,
    },
    type: 'light',
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
    MuiListItemText: {
      root: {
        width: '100%',
      },
      primary: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      secondary: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
});

export default theme;

import React from 'react';
import { Chip, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import VirtualisedListboxComponent from './VirtualisedListboxComponent';

const defaultValue = [];
const LISTBOX_PADDING = 8; // px

function getLabel(option) {
  return (
    (_.isString(option?.label) && option.label) ||
    (_.isString(option) && option) ||
    ''
  );
}

function renderRow(props) {
  const {
    data,
    index,
    style,
    data: { styles = {}, options = [] },
  } = props;
  let { children: option } = data[index].props;

  // if the option is a string, we've had to do getOptionLabel(getLabel)
  // because the option is an object but autocomplete wanted a string to
  // do it's own filtering. We can find the matching original object to
  // get the className property
  if (_.isString(option)) {
    option = options.find((o) => o.label === option) || option;
  }

  const newStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
    ...(styles[option?.className] || {}),
  };

  const element = (
    <Typography {...data[index].props} style={newStyle}>
      {getLabel(option)}
    </Typography>
  );
  return React.cloneElement(element);
}

export default function SelectMultipleField({
  input: { value, onChange, ...input },
  meta,
  suggestions,
  disabled,
  typeIcons,
  styles = {},
  filterOptions,
  ...props
}) {
  function handleChange(event, value) {
    const [selection, ...existingValues] = value.slice().reverse();

    if (typeof selection !== 'object') {
      onChange(value);
    } else {
      onChange([...existingValues.reverse(), selection.value]);
    }
  }

  const options = _.orderBy(suggestions, ['type', 'label']);

  return (
    <Autocomplete
      multiple
      ListboxComponent={VirtualisedListboxComponent}
      ListboxProps={{ styles, options, renderRow }}
      // !! disallows "" and defaultValue isn't a new [] every time
      value={!!value ? value : defaultValue}
      disabled={disabled}
      onChange={handleChange}
      options={options}
      getOptionSelected={(option, selected) => option.value === selected}
      // if custom filtering isn't applied autocomplete will attempt to do
      // it's own filtering which does option.toLowerCase and if option is
      // an object it will crash
      getOptionLabel={!filterOptions ? getLabel : undefined}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          helperText={meta.touched && (meta.error || meta.submitError)}
          error={meta.touched && meta.invalid}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const suggestion = suggestions.find(
            (suggestion) => suggestion.value === option
          );

          return (
            <Chip
              label={getLabel(suggestion || option)}
              {...getTagProps({ index })}
              style={styles[suggestion?.className]}
              icon={typeIcons && suggestion && typeIcons[suggestion.type]}
            />
          );
        })
      }
      // renderOption={(option) => {
      //   const possible = !option.unavailable;

      //   return (
      //     <Typography
      //       style={possible ? undefined : IMPOSSIBLE_STYLE}
      //       title={possible ? undefined : IMPOSSIBLE_MESSAGE}
      //     >
      //       {option.label}
      //     </Typography>
      //   );
      // }}
      // groupBy={(option) => option.type}
    />
  );
}

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Toolbar,
  makeStyles,
  Button,
  Tab,
  Tabs,
  List,
  ListItem,
  ListSubheader,
  Divider,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { red } from '@material-ui/core/colors';
import { Router as BoxIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useAuth } from '../../Auth';
import { usePrevious } from '../../../hooks';
// import {
//   Field,
//   // TextField,
//   // required,
//   SelectField,
//   CheckboxField,
// } from '../../fields';
import {
  FETCH_TELEMATICS_BOX_POLLS,
  START_TELEMATICS_BOX_POLLS_STREAM,
  END_TELEMATICS_BOX_POLLS_STREAM,
  UPDATE_TELEMATICS_BOX,
  DELETE_TELEMATICS_BOX,
  UPDATE_VEHICLE_IMEI,
} from '../../../actions';
import TelematicsBoxVehicles from './TelematicsBoxVehicles';
import DioTable from './DioTable';
import PollTable from './PollTable';
import RouteLeavingGuard from './RouteLeavingGuard';
// import { vinErrors } from './utilities';
import { useCommissioningDialog } from './CommissioningDialog';
import useLatestPoll from './useLatestPoll';
import { DriverIdStatus } from './DriverIDSection';
import { TrackingStatus } from './TrackingStateControl';
import TelematicsBoxMap from '../../reports/telematicsBoxesReports/LastContact/TelematicsBoxMap';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';

const {
  dioOptions: { hideMap = false, showDriverId = true, showTracking = false },
} = window.config;

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  cardContent: {
    padding: 0,
  },
  spacer: {
    flex: '1 1',
  },
  title: {
    flex: '0 0 auto',
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  setThemeWorkaround: {
    noret: (theme.overrides = {
      ...theme.overrides,
      MuiTableCell: {
        root: {
          // textAlign: 'center',
          padding: theme.spacing(0.5),
          textAlign: 'center',
          [theme.breakpoints.down('xs')]: {
            padding: 0,
            textAlign: 'center',
            borderRight: '1px solid rgb(224,224,224)',
            whiteSpace: 'pre-line',
          },
          [theme.breakpoints.down('sm')]: {
            padding: 0,
            textAlign: 'center',
            borderRight: '1px solid rgb(224,224,224)',
            whiteSpace: 'pre-line',
          },
        },
      },
    }),
  },
  content: {
    padding: 0,
  },
  header: {
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  commissionButton: {
    width: '150px',
    marginRight: theme.spacing(1),
    // color: theme.palette.primary.main,
  },
  negativeButton: {
    color: theme.palette.error.main,
    // width: '150px',
  },
  positiveButton: {
    color: theme.palette.primary.main,
    // width: '150px',
  },
  field: {
    width: 200,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  vinText: {
    textTransform: 'uppercase',
  },
  stepper: {
    padding: 16,
  },
  status: {
    width: '100%',
    maxWidth: '1024px',
  },
  mapContainer: {
    height: '500px',
    width: '100%',
  },
}));

// const composeValidators = (...validators) => (value) =>
//   validators.reduce((error, validator) => error || validator(value), undefined);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? children : <></>}
    </div>
  );
}

export default function TelematicsBoxDetail() {
  const { id } = useParams();
  const auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const latestPoll = useLatestPoll(id);

  const boxesByImei = useSelector((state) => state.telematicsBoxes.boxesByImei);
  const box = boxesByImei[id];
  const { imei, polls, mostRecentTime } = box || {};
  const ordered = _.orderBy(polls || [], ['time'], ['desc']); // sort by time

  const [currentTab, setCurrentTab] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const commissioningDialog = useCommissioningDialog();

  const formRef = useRef();

  const socketIsSubscribed = useSelector(
    (state) => state.telematicsBoxes.socketIsSubscribed
  );

  // wipe state when different box
  const prevId = usePrevious(id);
  useEffect(() => {
    if (prevId !== id && formRef.current) {
      formRef.current.restart();
      formRef.current.initialize({
        identificationNumber: box?.identificationNumber,
        fleetNumber: box?.fleetNumber,
      });
    }
  }, [prevId, id, formRef, box]);

  // startup (only times when id, selectedXXX change)
  useEffect(() => {
    if (imei && !socketIsSubscribed) {
      let lastTime = moment(mostRecentTime || undefined);

      //console.log("Going to listen for " + imei + " around " + lastTime.format());
      // if there is a last response time, and it's not from today, query from then
      if (!lastTime.isValid() || lastTime > moment().subtract(1, 'days')) {
        lastTime = moment();
      }

      let start = lastTime.clone().utc().subtract(1, 'days');
      let end = lastTime.clone().utc();

      dispatch({
        type: FETCH_TELEMATICS_BOX_POLLS,
        payload: {
          imei,
          start,
          end,
        },
      });

      dispatch({
        type: START_TELEMATICS_BOX_POLLS_STREAM,
        payload: { imei },
      });
    }

    // shutdown
    return () => {
      if (socketIsSubscribed) {
        dispatch({
          type: END_TELEMATICS_BOX_POLLS_STREAM,
        });
      }
    };
  }, [imei, mostRecentTime, socketIsSubscribed, dispatch]);

  function onSubmit(box) {
    dispatch({
      type: UPDATE_TELEMATICS_BOX,
      payload: {
        imei,
        ...box,
      },
    });
  }

  function handleChangeTab(event, value) {
    setCurrentTab(value);
  }

  function shouldBlockNavigation(nextLocation, dirty) {
    return dirty && nextLocation?.pathname !== history.location.pathname;
  }

  function a11yProps(index, alwaysEnabled = false) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function renderSkeleton() {
    return (
      <Toolbar color="primary" disableGutters style={{ paddingRight: 12 }}>
        <CardHeader
          avatar={
            <Avatar>
              {<Skeleton variant="circle" width="100%" height="100%" />}
            </Avatar>
          }
          title={<Skeleton height={10} width="150px" />}
        />
      </Toolbar>
    );
  }

  function renderNotFound() {
    return (
      <Toolbar color="primary" disableGutters style={{ paddingRight: 12 }}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="Box"
              className={classes.avatar}
              style={{ background: red[500] }}
            >
              <BoxIcon />
            </Avatar>
          }
          title={`IMEI ${id} not found`}
        />
      </Toolbar>
    );
  }

  function deleteImei() {
    if (box.identificationNumber) {
      dispatch({
        type: UPDATE_VEHICLE_IMEI,
        payload: {
          vehicle: { identificationNumber: box.identificationNumber },
          telematicsBoxImei: '',
        },
      });
    }

    dispatch({
      type: DELETE_TELEMATICS_BOX,
      payload: imei,
    });
  }

  const canEdit = auth.getAuthorisation()?.telematicsBoxes?.write;
  const canCommission =
    auth.getAuthorisation()?.commissioning?.write &&
    auth.getAuthorisation()?.vehicles?.write;

  function renderBoxDetails() {
    return (
      <>
        <Toolbar color="primary" disableGutters style={{ paddingRight: 12 }}>
          <CardHeader
            avatar={
              <Avatar aria-label="Box" className={classes.avatar}>
                <BoxIcon />
              </Avatar>
            }
            title={id}
          />
          <div className={classes.spacer}></div>
          {canCommission && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                commissioningDialog.show({ box });
              }}
              className={classes.commissionButton}
            >
              Commission
            </Button>
          )}
        </Toolbar>
        <Form
          onSubmit={onSubmit}
          // debug={console.log}
          initialValues={box} // should reset the form when the id changes
          render={({
            handleSubmit,
            dirty,
            pristine,
            submitting,
            form: { restart },
            form,
          }) => {
            formRef.current = form;
            // const buttonsDisabled = pristine || submitting;

            return (
              <form onSubmit={handleSubmit}>
                <RouteLeavingGuard
                  when={dirty}
                  navigate={(path) => history.push(path)}
                  shouldBlockNavigation={(nextLocation) =>
                    shouldBlockNavigation(nextLocation, dirty)
                  }
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.content}>
                    <Tabs
                      value={currentTab}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      //textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab {...a11yProps(0)} label="Info" />
                      <Tab {...a11yProps(1, true)} label="Polls" />
                      <Tab {...a11yProps(2, true)} label="Events" />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                      {/* <Prompt when={dirty} message={"Unsaved changes will be lost, are you sure?"} /> */}
                      <List>
                        {/* <ListSubheader disableSticky>
                          Telematics Box
                        </ListSubheader>
                        <ListItem>
                          <Field
                            name="make"
                            className={classes.field}
                            label="Make"
                            component={SelectField}
                            values={[
                              { label: 'Huf', value: 'huf' },
                              { label: 'Astra', value: 'astra' },
                            ]}
                            disabled={!canEdit}
                          />
                          <Field
                            name="model"
                            className={classes.field}
                            label="Model"
                            component={SelectField}
                            values={[
                              { label: 'A', value: 'a' },
                              { label: 'B', value: 'b' },
                            ]}
                            disabled={!canEdit}
                          />
                          <Field
                            name="hardwareVersion"
                            className={classes.field}
                            label="Hardware Version"
                            component={SelectField}
                            values={[
                              { label: '1', value: '1' },
                              { label: '2', value: '2' },
                            ]}
                            disabled={!canEdit}
                          />
                        </ListItem>
                        <ListItem>
                          <Field
                            name="active"
                            className={classes.field}
                            label="Active"
                            component={CheckboxField}
                            disabled={!canEdit}
                          />
                        </ListItem>
                        <Divider /> */}
                        {showDriverId && (
                          <>
                            <ListSubheader disableSticky>
                              Driver ID
                            </ListSubheader>
                            <ListItem>
                              <DriverIdStatus imei={box?.imei} />
                            </ListItem>
                            <Divider />
                          </>
                        )}
                        {showTracking && (
                          <>
                            <ListSubheader disableSticky>
                              Tracking
                            </ListSubheader>
                            <ListItem>
                              <TrackingStatus imei={box?.imei} />
                            </ListItem>
                            <Divider />
                          </>
                        )}
                        <ListSubheader disableSticky>Status</ListSubheader>
                        <ListItem>
                          <div className={classes.status}>
                            <PollTable polls={ordered} pollsToShow={1} />
                            <DioTable
                              polls={ordered}
                              pollsToShow={1}
                              showTime={false}
                            />
                          </div>
                        </ListItem>
                        <Divider />
                        {!hideMap && (
                          <>
                            <ListSubheader disableSticky>
                              Last known location
                            </ListSubheader>
                            <ListItem
                              className={latestPoll?.position 
                                ? classes.mapContainer : undefined}
                              disableGutters={
                                typeof latestPoll?.position !== 'undefined'
                              }
                            >
                                {latestPoll?.position ? (
                                  <TelematicsBoxMap
                                    point={latestPoll.position}
                                  />
                                ) : (
                                  'No location received'
                                )}
                            </ListItem>
                            <Divider />
                          </>
                        )}
                        <ListSubheader disableSticky>Vehicle</ListSubheader>
                        {/* <ListItem>
                          <Field
                            name="identificationNumber"
                            className={classes.field}
                            disabled={!!box?.identificationNumber}
                            // initialValue={box?.identificationNumber}
                            label="VIN"
                            validate={composeValidators(required, vinErrors)}
                          >
                            {(props) => (
                              <TextField
                                {...props}
                                value={
                                  box?.identificationNumber || props.input.value
                                }
                                inputProps={{
                                  className: classes.vinText,
                                  spellCheck: 'false',
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name="fleetNumber"
                            disabled={!!box?.fleetNumber}
                            value={box?.fleetNumber}
                            className={classes.field}
                            label="Fleet Number"
                          >
                            {(props) => (
                              <TextField
                                {...props}
                                value={box?.fleetNumber || props.input.value}
                                inputProps={{
                                  className: classes.vinText,
                                  spellCheck: 'false',
                                }}
                              />
                            )}
                          </Field>
                        </ListItem> */}
                        <ListItem>
                          <TelematicsBoxVehicles imei={id} />
                        </ListItem>
                        <Divider />
                        {/* <ListItem>
                        <Field
                          name="odometer"
                          className={classes.field}
                          label="Current Odometer Reading"
                          component={TextField}
                        />
                      </ListItem> */}
                      </List>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                      <PollTable polls={ordered} pollsToShow={20} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                      <DioTable polls={ordered} pollsToShow={20} />
                    </TabPanel>
                  </div>
                </CardContent>
                {currentTab === 0 && canEdit && (
                  <CardActions>
                    {/* <Button
                      type="submit"
                      className={classes.positiveButton}
                      disabled={buttonsDisabled}
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.negativeButton}
                      disabled={buttonsDisabled}
                      onClick={restart}
                    >
                      Cancel
                    </Button> */}
                    <Button
                      className={classes.negativeButton}
                      onClick={() => setShowConfirmDelete(true)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                )}
              </form>
            );
          }}
        ></Form>
      </>
    );
  }

  function renderContents() {
    if (!box) {
      if (Object.keys(boxesByImei).length > 0) {
        return renderNotFound();
      } else {
        return renderSkeleton();
      }
    } else {
      return renderBoxDetails();
    }
  }

  return (
    <div>
      <Card className={classes.card}>
        <Helmet>
          <title>IR3 | Telematics Box | {id}</title>
        </Helmet>
        {renderContents()}
      </Card>
      <ConfirmationDialog
        action="Delete"
        open={showConfirmDelete}
        itemId={imei}
        onOk={deleteImei}
        onCancel={() => setShowConfirmDelete(false)}
      />
    </div>
  );
}
